:root {
  --base-font-size-home-page: 2vw;
}

.home-page-charts-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  /* margin-left: 5%; */
  gap: 3%;
  justify-content: center;
  height: calc(var(--base-font-size-home-page) * 16);
  overflow: hidden;
  margin-top: calc(var(--base-font-size-home-page) * 3);
  /* margin-bottom: 0.8vw; */
}

.home-page-charts-section-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  /* margin-left: 5%; */
  gap: 3%;
  justify-content: center;
  height: auto;
  overflow: hidden;
}

.home-page-main-heading-image-layout-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: calc(var(--base-font-size-home-page) * 20);
  overflow: hidden;
  /* margin-top: 5%; */
  background-color: transparent;
  /* margin-top: calc(var(--base-font-size-home-page) * 2); */
  background: white;
}

.home-page-main-heading-image-layout-section-mobile {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  width: 100%;
  margin-top: 5%;
  background-color: transparent;
  margin-top: calc(var(--base-font-size-home-page) * 2);
}
/* Main heading  */

.home-page-heading-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: start;
  gap: calc(var(--base-font-size-home-page) * 1);
  margin-left: 5%;
  z-index: 3;
  width: 100%;
  margin-top: 9%;
}

.home-page-heading-section-mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: start;
  gap: calc(var(--base-font-size-home-page) * 1);
  margin-left: 10%;
  z-index: 3;
  width: 60%;
  margin-top: 7%;
  margin-bottom: 7%;
}
.home-page-main-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-home-page) * 1.7);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 140%;
}

.home-page-sub-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-home-page) * 0.6);
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  width: 130%;
}

.home-page-wallet-button {
  display: flex;
  gap: calc(var(--base-font-size-home-page) * (12 / 32));
  box-shadow: inset 0 2px 0 0 hsla(0, 0%, 100%, 0.2),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: calc(var(--base-font-size-home-page) * (10 / 32));
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-home-page) * (8 / 32));
  background: #2fb484;
  color: #fff;
  font-size: calc(var(--base-font-size-home-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  height: calc(var(--base-font-size-home-page) * 2);
  width: calc(var(--base-font-size-home-page) * 8);
  cursor: pointer;
  border: 0.5px solid #2fb484;
  transition: background-color 0.8s ease 0s;
}

.home-page-wallet-button:hover {
  color: white;
  background: #1b805e;
  /* border: 0.5px solid #2fb484; */
  cursor: pointer;
}

/* Image Collage Layout */
.collage-box-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  transform: rotate(50deg);
  margin-right: -15%;
  width: 250%;
  height: 150%;
  border-radius: 50%;
  overflow: hidden;
  border: none;
}

.collage-box-layout::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 1) 60%
  );
  opacity: 1;
  z-index: 1;
}

.collage-box-layout::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  filter: blur(20px);
  z-index: -2;
}

.collage-box-layout > * {
  position: relative;
  z-index: 1;
}

.collage-box-layout-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  transform: rotate(0deg);
  margin-right: -15%;
  width: 100%;
  height: 40%;
  border: none;
  overflow: hidden;
}
.collage-box-layout-mobile::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 10%,
    rgba(255, 255, 255, 1) 98%
  );
  opacity: 1;
  z-index: 1;
}

.collage-box-layout-mobile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  z-index: -2;
}

.collage-box-layout-mobile > * {
  position: relative;
  z-index: 1;
}

.collage-row {
  display: flex;
}

.collage-box {
  width: calc(var(--base-font-size-home-page) * (170 / 32));
  height: calc(var(--base-font-size-home-page) * (200 / 32));
  margin: calc(var(--base-font-size-home-page) * (8 / 32));
  margin-top: 10;
}

.collage-bigger-box {
  width: calc(var(--base-font-size-home-page) * (220 / 32));
  height: calc(var(--base-font-size-home-page) * (280 / 32));
}

.collage-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* border: 1px solid #183b56; */
  border-radius: calc(var(--base-font-size-home-page) * (24 / 32));
}

/* other boxes */

.other-boxes-main-container {
  display: flex;
  /* border: 1px solid red; */
  width: 80%;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: calc(var(--base-font-size-home-page) * 1);
  margin-left: 10%;
}

.other-boxes-main-container-mobile {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-left: 10%;
  gap: calc(var(--base-font-size-home-page) * 1);
  justify-content: space-around;
  align-items: center;
  /* margin-top: 5%; */
}

.folder-container {
  width: calc(var(--base-font-size-home-page) * (843 / 32));
  height: calc(var(--base-font-size-home-page) * (460 / 32));
  flex-shrink: 0;
  position: relative;
  height: calc(var(--base-font-size-home-page) * (460 / 32));
  background-image: url("https://d12e743s7v0mza.cloudfront.net/FoldersPage-ezgif.com-speed.gif");
  background-size: cover;
  border-radius: calc(var(--base-font-size-dashboard-page) * (16 / 32));
}

.folder-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    transparent 51.91%,
    rgba(0, 0, 0, 0.5) 85.88%
  ); /* Adjust gradient as needed */
  z-index: 1; /* Ensure the gradient is above the background */
  border-radius: calc(var(--base-font-size-dashboard-page) * (16 / 32));
  pointer-events: none; /* Ensure the gradient doesn't interfere with interactions */
}

.folder-container > * {
  position: relative;
  z-index: 2; /* Ensure your content is above the gradient */
}

.folder-container-mobile {
  width: calc(var(--base-font-size-home-page) * (450 / 32));
  height: calc(var(--base-font-size-home-page) * (250 / 32));
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-home-page) * (12 / 32));
  position: relative;
  background-image: url("https://d12e743s7v0mza.cloudfront.net/FoldersPage-ezgif.com-speed.gif");
  background-size: cover;
  justify-content: center;
  align-items: left;
  padding-left: calc(var(--base-font-size-home-page) * 25 / 32);
  padding-bottom: calc(var(--base-font-size-home-page) * 10 / 32);
  /* margin-top: 7%; */
}

.folder-container-mobile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    transparent 51.91%,
    rgba(0, 0, 0, 0.5) 85.88%
  ); /* Adjust gradient as needed */
  z-index: 1; /* Ensure the gradient is above the background */
  border-radius: calc(var(--base-font-size-home-page) * (12 / 32));
  pointer-events: none; /* Ensure the gradient doesn't interfere with interactions */
}

.folder-container-mobile > * {
  position: relative;
  z-index: 2; /* Ensure your content is above the gradient */
}

.link-wallet-container {
  width: calc(var(--base-font-size-home-page) * (843 / 32));
  height: calc(var(--base-font-size-home-page) * (460 / 32));
  flex-shrink: 0;
  position: relative;
  height: calc(var(--base-font-size-home-page) * (460 / 32));
  background-image: url("https://d12e743s7v0mza.cloudfront.net/Wallet-link.gif");
  background-size: cover;
  border-radius: calc(var(--base-font-size-dashboard-page) * (16 / 32));
}

.link-wallet-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    transparent 51.91%,
    rgba(0, 0, 0, 0.5) 85.88%
  ); /* Adjust gradient as needed */
  z-index: 1; /* Ensure the gradient is above the background */
  border-radius: calc(var(--base-font-size-dashboard-page) * (16 / 32));
  pointer-events: none; /* Ensure the gradient doesn't interfere with interactions */
}

.link-wallet-container > * {
  position: relative;
  z-index: 2; /* Ensure your content is above the gradient */
}

.link-wallet-container-mobile {
  width: calc(var(--base-font-size-home-page) * (450 / 32));
  height: calc(var(--base-font-size-home-page) * (250 / 32));
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-home-page) * (12 / 32));
  position: relative;
  background-image: url("https://d12e743s7v0mza.cloudfront.net/Wallet-link.gif");
  background-size: cover;
  justify-content: center;
  align-items: left;
  padding-left: calc(var(--base-font-size-home-page) * 25 / 32);
  padding-bottom: calc(var(--base-font-size-home-page) * 10 / 32);
  /* margin-top: 7%; */
}

.link-wallet-container-mobile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    transparent 51.91%,
    rgba(0, 0, 0, 0.5) 85.88%
  ); /* Adjust gradient as needed */
  z-index: 1; /* Ensure the gradient is above the background */
  border-radius: calc(var(--base-font-size-home-page) * (12 / 32));
  pointer-events: none; /* Ensure the gradient doesn't interfere with interactions */
}

.link-wallet-container-mobile > * {
  position: relative;
  z-index: 2; /* Ensure your content is above the gradient */
}

.gif-box-heading {
  margin-top: calc(var(--base-font-size-home-page) * 11.2);
  display: flex;
  justify-self: center;
  line-height: normal;
  background-color: white;
  width: 90%;
  height: calc(var(--base-font-size-home-page) * (74 / 32));
  align-items: center;
  margin-left: 5%;
  padding-left: calc(var(--base-font-size-home-page) * 0.85);
  border-radius: calc(var(--base-font-size-home-page) * (26 / 32));
  box-shadow: rgb(0 0 0 / 8%) 0px calc(var(--base-font-size-home-page) * 0.08)
      calc(var(--base-font-size-home-page) * 0.08),
    rgb(0 0 0 / 5%) 0px 4px 12px;
}
.gif-box-heading-mobile {
  margin-top: calc(var(--base-font-size-home-page) * 5);
  display: flex;
  justify-self: center;
  line-height: normal;
  background-color: white;
  width: 94%;
  height: calc(var(--base-font-size-home-page) * (74 / 32));
  align-items: center;
  /* margin-left: 5%; */
  padding-left: calc(var(--base-font-size-home-page) * 0.85);
  border-radius: calc(var(--base-font-size-home-page) * (20 / 32));
  box-shadow: rgb(0 0 0 / 8%) 0px calc(var(--base-font-size-home-page) * 0.08)
      calc(var(--base-font-size-home-page) * 0.08),
    rgb(0 0 0 / 5%) 0px 4px 12px;
}

.gif-box-heading-content {
  color: #3f2366;
  font-size: calc(var(--base-font-size-home-page) * (24 / 32));
  font-style: normal;
  font-weight: 600;
  width: calc(var(--base-font-size-home-page) * (460 / 32));
}
.gif-box-heading-content-mobile {
  color: #3f2366;
  font-size: calc(var(--base-font-size-home-page) * (16 / 32));
  font-style: normal;
  font-weight: 600;
  line-height: calc(var(--base-font-size-home-page) * (24 / 32));
  width: calc(var(--base-font-size-home-page) * (280 / 32));
}

.gif-container-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(var(--base-font-size-home-page) * 6.5);
  background-color: #effaf5;
  border-radius: calc(var(--base-font-size-home-page) * (32 / 32));
  border: calc(var(--base-font-size-home-page) * (2.14 / 32)) solid #b3e7cd;
  width: calc(var(--base-font-size-home-page) * 1.2);
  height: calc(var(--base-font-size-home-page) * 1.2);
  cursor: pointer;
}
.gif-container-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(var(--base-font-size-home-page) * 6.5);
  background-color: #effaf5;
  border-radius: calc(var(--base-font-size-home-page) * (32 / 32));
  border: calc(var(--base-font-size-home-page) * (2.14 / 32)) solid #b3e7cd;
  width: calc(var(--base-font-size-home-page) * 1.2);
  height: calc(var(--base-font-size-home-page) * 1.2);
  cursor: pointer;
}

.gif-container-arrow:hover {
  border: calc(var(--base-font-size-home-page) * (2.14 / 32)) solid #2fb483;
}

.gif-container-arrow-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: calc(var(--base-font-size-home-page) * 1.4);
  background-color: #effaf5;
  border-radius: calc(var(--base-font-size-home-page) * (32 / 32));
  border: calc(var(--base-font-size-home-page) * (2.14 / 32)) solid #b3e7cd;
  width: calc(var(--base-font-size-home-page) * 1.2);
  height: calc(var(--base-font-size-home-page) * 1.2);
  cursor: pointer;
}

.wallet-box-container-heading {
  color: #d67827;
  color: #3f2366;
  font-size: calc(var(--base-font-size-home-page) * 1.3);
  margin-bottom: calc(var(--base-font-size-home-page) * (20 / 32));
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 90%;
}

.wallet-box-container {
  width: calc(var(--base-font-size-home-page) * (843 / 32));
  height: calc(var(--base-font-size-home-page) * (460 / 32));
  flex-shrink: 0;
  position: relative;
  height: calc(var(--base-font-size-home-page) * (460 / 32));
  background-image: url("https://www.shutterstock.com/image-vector/dark-blue-green-vector-blur-600nw-1692448585.jpg");
  background-size: cover;
  border-radius: calc(var(--base-font-size-dashboard-page) * (16 / 32));
}

.wallet-box-container-mobile {
  width: 90%;
  height: calc(var(--base-font-size-home-page) * (288 / 32));
  flex-shrink: 0;
  border-radius: 12px;
  background-image: url("https://www.shutterstock.com/image-vector/dark-blue-green-vector-blur-600nw-1692448585.jpg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding-left: calc(var(--base-font-size-home-page) * 20 / 32);
  padding-bottom: calc(var(--base-font-size-home-page) * 10 / 32);
  margin-top: 7%;
}

.media-container {
  margin-top: calc(var(--base-font-size-home-page) * (16 / 32));
}

.media-box-container {
  padding-top: calc(var(--base-font-size-home-page) * 0.5);
  padding-left: calc(var(--base-font-size-home-page) * 20 / 32);
  width: calc(var(--base-font-size-home-page) * (400 / 32));

  height: calc(var(--base-font-size-home-page) * (460 / 32));
  border-radius: 12px;
  background: linear-gradient(180deg, #e9f2ef 58.81%, #a9ccbd 114.2%);
  /* margin-left: 2%;
  margin-right: 2%; */
}

.media-box-container-mobile {
  padding-top: calc(var(--base-font-size-home-page) * 0.5);
  padding-left: calc(var(--base-font-size-home-page) * 20 / 32);
  width: 90%;
  height: calc(var(--base-font-size-home-page) * (250 / 32));
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-home-page) * (12 / 32));
  /* background: linear-gradient(180deg, #e9f2ef 58.81%, #a9ccbd 114.2%); */
  background: #c7ebde;
  margin-left: 2%;
  margin-right: 2%;
}

.media-box-container-mobile.podcast {
  height: calc(var(--base-font-size-home-page) * (190 / 32));
}
.podcast-container {
  margin-top: calc(var(--base-font-size-home-page) * (14 / 32));
}

.podcast-slide {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: calc(var(--base-font-size-home-page) * (350 / 32));
  height: calc(var(--base-font-size-home-page) * (250 / 32));
  border-radius: calc(var(--base-font-size-home-page) * (12 / 32));
}
.podcast-slide-mobile {
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  width: calc(var(--base-font-size-home-page) * (418 / 32));
  /* height: calc(var(--base-font-size-home-page) * (200 / 32)); */
  border-radius: calc(var(--base-font-size-home-page) * (12 / 32));
}

.podcast-iframe {
  border: none;
  width: calc(var(--base-font-size-home-page) * (350 / 32));
  height: calc(var(--base-font-size-home-page) * (350 / 32));

  /* overflow: hidden; */
}
.podcast-iframe-mobile {
  border: 2px solid red;
  width: 100%;
  height: calc(var(--base-font-size-home-page) * 4);
  border-radius: calc(var(--base-font-size-home-page) * (16 / 32));
}

.podcast-box-sub-heading {
  color: #3f2366;
  margin-top: calc(var(--base-font-size-home-page) * (16 / 32));
  /* margin-bottom: calc(var(--base-font-size-home-page) * (2 / 32)); */
  /* padding-left: 2%; */
  font-size: calc(var(--base-font-size-home-page) * 0.7);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}
.podcast-platform-icons {
  display: flex;
  flex-direction: row;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  line-height: normal;
  margin-top: calc(var(--base-font-size-home-page) * 1.4);
  /* border: 2px solid red; */
}
.podcast-platform-icons-mobile {
  display: flex;
  justify-content: left;
  /* border: 2px solid red; */
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: calc(var(--base-font-size-home-page) * (418 / 32));
  margin-top: calc(var(--base-font-size-home-page) * (24 / 32));
  /* Adjust the space between icons */
}

.podcast-platform-icons img {
  height: 2.7vw; /* Adjust the size of the icons */
  width: 2.7vw; /* Maintain aspect ratio */
}
.podcast-platform-icons-mobile img {
  height: calc(
    var(--base-font-size-home-page) * 1.6
  ); /* Adjust the size of the icons */
  width: auto; /* Maintain aspect ratio */
}

.media-box-container {
  width: calc(var(--base-font-size-home-page) * (400 / 32));
  height: calc(var(--base-font-size-home-page) * (460 / 32));
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-home-page) * (12 / 32));
  background: #c7ebde;

  /* background: linear-gradient(180deg, #dde3fc 59.24%, #7991f1 169.25%); */
  padding-left: 2%;
  padding-right: 2%;
}

.videos-box-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.carousel {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
  cursor: pointer;
  /* margin-left: 2%; */
  -webkit-overflow-scrolling: touch;
}

.slides {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: scroll;
  height: 22vw;
}

.video-slide {
  display: block;
  margin-bottom: calc(var(--base-font-size-home-page) * (10 / 32));
}

iframe {
  border: none;
  width: calc(var(--base-font-size-home-page) * (271 / 32));
  height: calc(var(--base-font-size-home-page) * (159 / 32));
  border-radius: calc(var(--base-font-size-home-page) * (18 / 32));
}

.video-slide:last-child {
  margin-right: 0;
  margin-bottom: 0;
}

.small-boxes-container {
  margin-top: calc(var(--base-font-size-home-page) * (20 / 32));
  display: flex;
  justify-content: center;
  align-items: center;
  width: 97%;
}

.small-box {
  width: calc(var(--base-font-size-home-page) * (24 / 32));
  height: calc(var(--base-font-size-home-page) * (18 / 32));
  background-color: white;
  margin: 0 calc(var(--base-font-size-home-page) * (5 / 32));
}

/* charts */

.all-charts-container {
  position: relative;
  padding-top: calc(var(--base-font-size-home-page) * 0.7);
  width: 30%;
  height: calc(var(--base-font-size-home-page) * 470 / 32);
  border-radius: 16px;
  background: rgba(47, 180, 131, 0.122);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.5px);
  /* margin-bottom: calc(var(--base-font-size-home-page) * 3.5); */
}

.all-charts-container-mobile {
  position: relative;
  padding-top: calc(var(--base-font-size-home-page) * 0.5);
  width: 90%;
  height: calc(var(--base-font-size-home-page) * 470 / 32);
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.5px);
  padding-bottom: calc(var(--base-font-size-home-page) * 1);
  margin-bottom: calc(var(--base-font-size-home-page) * 1);
}

.sales-history-filter-data-heading {
  color: #183b56;
  padding-left: 4%;
  text-align: left;
  font-size: calc(var(--base-font-size-home-page) * 0.6);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sales-history-filter-data-values {
  color: #2fb484;
  padding-left: 4%;
  text-align: left;
  font-size: calc(var(--base-font-size-home-page) * 1);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: calc(var(--base-font-size-home-page) * 0.5);
  margin-bottom: calc(var(--base-font-size-home-page) * 0.3);
}

.sales-history-value-change-inc {
  color: #068858;
  text-align: left;
  padding-left: 4%;
  font-size: calc(var(--base-font-size-home-page) * 0.45);
  font-style: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: calc(var(--base-font-size-home-page) * 0.1);
}

.sales-history-value-change-dec {
  color: rgb(183, 4, 4);
  text-align: left;
  padding-left: 4%;
  font-size: calc(var(--base-font-size-home-page) * 0.45);
  font-style: normal;
  line-height: normal;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: calc(var(--base-font-size-home-page) * 0.1);
}

.gas-chart-secondary-values {
  color: #747474;
  font-size: calc(var(--base-font-size-home-page) * 0.4);
  margin-left: 5%;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.eth-chart-tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--base-font-size-folders-page) * (20 / 32));
  margin-top: calc(var(--base-font-size-folders-page) * (10 / 32));
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: calc(var(--base-font-size-folders-page) * (12 / 32));
}
#gas-price-chart {
  margin-top: calc(var(--base-font-size-folders-page) * (18 / 32));
}

.home-page-heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: calc(var(--base-font-size-folders-page) * (70 / 32));
}

.home-page-divider {
  width: 80%;
  height: calc(var(--base-font-size-folders-page) * (1 / 32));
  background-color: #d0d0d0;
  margin-top: 2em;
}

.home-page-heading {
  width: 80%;
  font-size: calc(var(--base-font-size-folders-page) * (24 / 32));
  font-weight: bold;

  display: flex;
  justify-content: flex-start;
  /* Adjust the space around the heading as needed */
}

/* Spotlight Artists container */
.spotlight-artists-container {
  display: flex;
  justify-content: flex-start; /* Align items to the start of the container */
  align-items: center;
  margin-top: calc(var(--base-font-size-folders-page) * (50 / 32));
  overflow-x: auto; /* Use auto instead of scroll to avoid always showing the scrollbar */
  -webkit-overflow-scrolling: touch; /* For momentum scroll in iOS */
  /* Prevent flex items from shrinking below their content size */
  flex-shrink: 0;
}

.spotlight-artist-card {
  position: relative;
  display: flex;
  transition: all 0.6s ease-out;
  flex: 0 0 auto;
  width: calc(var(--base-font-size-folders-page) * (273 / 32));
  height: calc(var(--base-font-size-folders-page) * (320 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (32 / 32));
  overflow: hidden;
  box-shadow: 0 calc(var(--base-font-size-folders-page) * (4 / 32))
    calc(var(--base-font-size-folders-page) * (8 / 32)) rgba(0, 0, 0, 0.1);
}

.spotlight-artist-card-mobile {
  position: relative;
  display: flex;
  flex: 0 0 auto;
  width: 40%;
  height: 100%;
  border-radius: calc(var(--base-font-size-folders-page) * (10 / 32));
  overflow: hidden;
  box-shadow: 0 calc(var(--base-font-size-folders-page) * (4 / 32))
    calc(var(--base-font-size-folders-page) * (8 / 32)) rgba(0, 0, 0, 0.1);
}

.spotlight-artist-card.active {
  opacity: 1;
}

.spotlight-artist-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: #2fb484;
  /* Adjust the color and transparency as needed */
}

.spotlight-artist-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    transparent 51.91%,
    rgba(0, 0, 0, 0.5) 85.88%
  ); /* Adjust the color and transparency as needed */
  z-index: 1; /* Ensure the overlay is above the image */
}

.spotlight-artist-info {
  font-size: calc(var(--base-font-size-folders-page) * (10 / 32));
  position: absolute;
  top: 80%;
  left: 5%;
  z-index: 3;
  width: 100%;
  height: 20%;
  text-align: start;
  padding: 1em;
  backdrop-filter: blur(0.25em);
}

.spotlight-artist-name {
  margin: 0.5rem 0 0.2rem 0;
  font-size: 1.6em;
  font-weight: 700;
  color: #ffffff;
}

.spotlight-artist-profession {
  margin: 0;
  font-size: 1.2em;
  color: #ffffff;
  font-weight: 600;
}

.spotlight-scroll-button {
  width: calc(var(--base-font-size-creator-page) * 5);
  height: calc(var(--base-font-size-creator-page) * 5);
  color: black;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px); /* Blur effect */
  border: none; /* Remove border */
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 50%;
  box-shadow: inset 0 2px 0 0 hsla(0, 0%, 100%, 0.2),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.spotlight-scroll-button.left {
  left: calc(var(--base-font-size-creator-page) * -3);
  padding-left: calc(var(--base-font-size-creator-page) * 3);
  margin-left: 0vw;
}

.spotlight-scroll-button.right {
  right: calc(var(--base-font-size-creator-page) * -3);
  padding-right: calc(var(--base-font-size-creator-page) * 3);
  margin-right: 0vw;
}

.spotlight-artists-wrapper:hover .spotlight-scroll-button {
  opacity: 1;
}

/* Trending Collections container */
.trending-collections-container {
  display: flex;
  justify-content: flex-start; /* Align items to the start of the container */
  flex-wrap: wrap;
  align-items: center;
  gap: 3rem;

  margin-top: calc(var(--base-font-size-folders-page) * (50 / 32));

  overflow-x: auto; /* Use auto instead of scroll to avoid always showing the scrollbar */
  -webkit-overflow-scrolling: touch; /* For momentum scroll in iOS */

  /* Prevent flex items from shrinking below their content size */
  flex-shrink: 0;
}

.collection-box-home-page {
  margin-right: calc(var(--base-font-size-creator-page) * 0.8);
  padding: calc(var(--base-font-size-creator-page) * 0.5);
  width: auto;
  max-width: calc(var(--base-font-size-creator-page) * (353 / 32));
  height: 90%;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.5px);
  margin-bottom: calc(var(--base-font-size-creator-page) * 1);
  border-radius: calc(var(--base-font-size-creator-page) * (16 / 32));
  /* padding-left: none; */
}

.collection-box-home-page:hover {
  background-color: #2fb48375;
  cursor: pointer;
  transition: all 0.7s linear;
}

.collections-container-home-page {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  width: 80%;
  /* background-color: red; */
  justify-content: center;
  margin-top: calc(var(--base-font-size-creator-page) * 40 / 64);
  margin-left: calc(var(--base-font-size-creator-page) * 4.05);
  margin-bottom: calc(var(--base-font-size-creator-page) * (16 / 32));
}

.collections-container-home-page-mobile {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  width: 90%;
  margin-top: calc(var(--base-font-size-creator-page) * (24 / 32));
  margin-left: 5%;
  overflow-x: auto;
  margin-bottom: calc(var(--base-font-size-creator-page) * 2.3);
}

/* feedback form */
.feedback-form-container-box {
  position: absolute;
  left: 50%;
  width: calc(var(--base-font-size-creator-page) * (480 / 32));
  height: calc(var(--base-font-size-creator-page) * (400 / 32));
  padding: calc(var(--base-font-size-creator-page) * (41 / 32))
    calc(var(--base-font-size-creator-page) * (38 / 32));
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--base-font-size-creator-page) * (22 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (20 / 32));
  background: #fafafa;
  box-shadow: 2px -4px 10px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.5px);
  margin-top: -8%;
}

.feedback-form-container-box-mobile {
  position: absolute;
  left: 15.5%;
  width: calc(var(--base-font-size-creator-page) * (440 / 32));
  height: calc(var(--base-font-size-creator-page) * (400 / 32));
  padding: calc(var(--base-font-size-creator-page) * (41 / 32))
    calc(var(--base-font-size-creator-page) * (38 / 32));
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--base-font-size-creator-page) * (22 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (20 / 32));
  background: #fafafa;
  box-shadow: 2px -4px 10px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.5px);
  margin-top: -105%;
}

.feedback-form-input {
  width: 100%;
  height: calc(var(--base-font-size-creator-page) * (40 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (8 / 32));
  border: 0.5px solid #74747493;
  background: var(--White, #fff);
  color: #183b56;
  padding-left: calc(var(--base-font-size-creator-page) * (10 / 32));
  font-size: calc(var(--base-font-size-creator-page) * (12 / 32));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.feedback-form-input:hover {
  border: 1px solid #2fb484;
  padding-left: calc(var(--base-font-size-creator-page) * (15 / 32));
  transition: all 0.3s ease;
}

.feedback-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-folders-page) * (20 / 32));
}

.feedback-form-message-input {
  display: flex;
  width: 100%;
  min-height: calc(var(--base-font-size-profile-page) * 170 / 32);
  height: auto;
  padding: calc(var(--base-font-size-profile-page) * 17 / 32)
    calc(var(--base-font-size-profile-page) * 12 / 32);
  align-items: flex-start;
  gap: calc(var(--base-font-size-profile-page) * 10 / 32);
  border-radius: calc(var(--base-font-size-profile-page) * 12 / 32);
  border: 0.5px solid #74747493;
  background: var(--White, #fff);
  color: #183b56;
  font-size: calc(var(--base-font-size-profile-page) * 15 / 32);
  resize: none;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}
.feedback-form-message-input:hover {
  border: 1px solid #2fb484;
  padding-left: calc(var(--base-font-size-creator-page) * (15 / 32));
  transition: all 0.3s ease;
}

.feedback-form-button {
  width: 35%;
  height: calc(var(--base-font-size-creator-page) * (35 / 32));
  display: flex;
  padding: calc(var(--base-font-size-creator-page) * (16 / 32));
  justify-content: center;
  align-items: center;
  gap: calc(var(--base-font-size-creator-page) * (10 / 32));
  border-radius: calc(var(--base-font-size-home-page) * (8 / 32));
  background: #2fb484;
  box-shadow: 0px 24px 34px 0px rgba(32, 70, 100, 0.1);
  color: white;
  text-align: center;
  font-size: calc(var(--base-font-size-creator-page) * (12 / 32));
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}

.feedback-form-button:hover {
  background: #129e6b;
  cursor: pointer;
  fill: #2fb484;
}
/*renamed it bacaused it was overlapping with Chatmadeeasy classNames*/
.feedback-banners {
  width: 80%;
  margin-left: 10%;
  margin-top: calc(var(--base-font-size-home-page) * 4);
  height: calc(var(--base-font-size-home-page) * (460 / 32));
  border-radius: calc(var(--base-font-size-profile-page) * 24 / 32);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-image: url("../../assets/images/Green-Wavy-BG@2x.png");
  background-size: cover;
  margin-bottom: calc(var(--base-font-size-home-page) * 2);
}

.feedback-banner-mobile {
  width: 80%;
  margin-left: 10%;
  margin-top: calc(var(--base-font-size-home-page) * 12);
  height: calc(var(--base-font-size-home-page) * (460 / 32));
  border-radius: calc(var(--base-font-size-profile-page) * 24 / 32);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  background-image: url("../../assets/images/layered-waves-haikei.svg");
  background-size: cover;
  margin-bottom: calc(var(--base-font-size-home-page) * 2);
}

.feedback-banner-text {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  height: 100%;
}
.feedback-banner-text-mobile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12%;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.feedback-banner-heading {
  width: 90%;
  margin-left: 8%;
  color: var(--White, #fff);
  font-size: calc(var(--base-font-size-home-page) * (64 / 32));
  font-style: normal;
  font-weight: 700;
  line-height: calc(var(--base-font-size-home-page) * (60 / 32));
}

.feedback-banner-sub-heading {
  width: 90%;
  margin-top: calc(var(--base-font-size-home-page) * (20 / 32));
  margin-left: 8%;
  color: var(--White, #fff);
  font-size: calc(var(--base-font-size-home-page) * (18 / 32));
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--base-font-size-home-page) * (30 / 32));
}

@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  :root {
    --base-font-size-home-page: 5vw;
  }
}

/* scrollbar */

.carousel::-webkit-scrollbar {
  width: 3px;
  height: 0px;
}

.carousel::-webkit-scrollbar-track {
  background-color: transparent;
}

.carousel::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.carousel::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.carousel::-webkit-scrollbar {
  width: 3px;
  height: 0px;
}

.collections-container-home-page-mobile::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.collections-container-home-page-mobile::-webkit-scrollbar-track {
  background-color: transparent;
}

.collections-container-home-page-mobile::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.collections-container-home-page-mobile::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.collections-container-home-page-mobile::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
/* -----Origional------- */

/* Footer */
/* .footer-section-main-container {
  width: 80%;
  margin-left: 10%;
  display: -webkit-flex; 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-top: calc(var(--base-font-size-home-page) * 1 / 32) solid #d9d9d9;
  padding-top: calc(var(--base-font-size-home-page) * 0.4);
  margin-bottom: calc(var(--base-font-size-home-page) * 0.4);
} */

.footer-section-main-container {
  width: 80%;
  margin-left: 10%;

  display: -webkit-flex; /* Safari 6.1+ */
  display: flex;
  -webkit-flex-direction: row; /* Safari 6.1+ */
  flex-direction: row;
  -webkit-justify-content: space-between; /* Safari 6.1+ */
  justify-content: space-between;
  -webkit-align-items: center; /* Safari 6.1+ */
  align-items: center;

  border-top: calc(var(--base-font-size-home-page) * 1 / 32) solid #d9d9d9;
  padding-top: calc(var(--base-font-size-home-page) * 0.8);
  margin-bottom: calc(var(--base-font-size-home-page) * 0.8);
}

.footer-section-main-container-mobile {
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-top: calc(var(--base-font-size-home-page) * 1 / 32) solid #d9d9d9;
  padding-top: calc(var(--base-font-size-home-page) * 1);
  margin-bottom: calc(var(--base-font-size-home-page) * 0.4);
}

.footer-section-links {
  width: 130%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.footer-section-links-mobile {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: calc(var(--base-font-size-home-page) * 0.4);
  gap: calc(var(--base-font-size-home-page) * 1);
  justify-content: space-between;
  align-items: center;
}

.footer-section-links-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-home-page) * 15 / 32);
  font-style: normal;
  font-weight: 400;
  line-height: calc(var(--base-font-size-home-page) * 30 / 32);
  cursor: pointer;
}
.footer-section-links-heading:hover {
  color: #2fb484;
  padding-bottom: 0.4em;
  transition: all 0.3s ease;
}

.footer-section-logos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  cursor: pointer;
}

.footer-section-social-links {
  display: flex;
  -webkit-align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  /* max-width: 218px; */
  max-width: calc(var(--base-font-size-home-page) * 8);
}

.footer-facebook-container:hover .footer-facebook-svg {
  transition: stroke 0.3s ease;
}
.footer-facebook-container:hover .footer-facebook-svg {
  stroke: #2fb484;
}

.feedback-form-button {
  position: relative;
  z-index: 1; /* Ensure the button is on top */
}

/* scrollbar */
.spotlight-artists-scrollbar {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 1.5vw;

  margin-top: calc(var(--base-font-size-creator-page) * (18 / 32));
  margin-bottom: calc(var(--base-font-size-creator-page) * (18 / 32));

  width: 100%;
}

.spotlight-artists-scrollbar-mobile {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  gap: 1.5vw;
  height: 50vw;
  padding-bottom: 1em;
  width: 100%;
  margin-top: calc(var(--base-font-size-creator-page) * (24 / 32));
}

.spotlight-artists-scrollbar::-webkit-scrollbar {
  height: calc(var(--base-font-size-item-page) * 0 / 32);
}
.spotlight-artists-scrollbar-mobile::-webkit-scrollbar {
  height: calc(var(--base-font-size-item-page) * 0 / 32);
}

.slides::-webkit-scrollbar {
  width: 3px;
  height: 0px;
}

.home-page-activity {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 94%;
  gap: calc(var(--base-font-size-item-page) * 16 / 32);
  margin-left: 3%;
  margin-top: calc(var(--base-font-size-item-page) * 2);
  margin-bottom: 2%;
}
.home-page-activity-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 94%;
  gap: calc(var(--base-font-size-item-page) * 16 / 32);
  margin-left: 3%;
  margin-top: calc(var(--base-font-size-item-page) * 5);
  margin-bottom: 2%;
}

.home-page-filter-section-row {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
}

.filter-edit-modal-component {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  border-radius: calc(var(--base-font-size-item-page) * 8 / 20);
  border-bottom-right-radius: calc(var(--base-font-size-item-page) * 8 / 20);
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 10 / 20) rgba(0, 0, 0, 0.2);
  text-align: center;
  min-width: calc(var(--base-font-size-item-page) * 350 / 20);
  min-height: auto;
  max-height: 70%;
  max-width: 80%;
  overflow: scroll;
  padding-top: calc(var(--base-font-size-item-page) * 0.5);
  padding-bottom: calc(var(--base-font-size-item-page) * 0.8);
  padding-left: calc(var(--base-font-size-item-page) * 1);
  padding-right: calc(var(--base-font-size-item-page) * 0.8);
  overflow: hidden;
}

.home-page-activity-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
}

.home-page-filter-section-container {
  /* position: sticky; */
  top: calc(var(--base-font-size-collection-page) * (100 / 32));
  align-self: start;
  padding-top: 1em;
  width: calc(var(--base-font-size-home-page) * (300 / 32));
  height: auto;
  overflow-y: auto;
  border-radius: calc(var(--base-font-size-home-page) * (18 / 32));
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding-bottom: 1em;
  overflow-x: hidden;
}

.home-page-filter-section-mobile {
  display: flex;
  flex-direction: column;
  align-self: center;
  position: absolute;
  overflow: scroll !important;
  padding: calc(var(--base-font-size-home-page) * (20 / 32))
    calc(var(--base-font-size-home-page) * (25 / 32))
    calc(var(--base-font-size-home-page) * (7 / 32))
    calc(var(--base-font-size-home-page) * (15 / 32));
  height: calc(var(--base-font-size-home-page) * 32);
  border-radius: calc(var(--base-font-size-home-page) * (18 / 32));
  background: white;
  box-shadow: 0px calc(var(--base-font-size-home-page) * (2 / 32))
    calc(var(--base-font-size-home-page) * (15 / 32)) 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(calc(var(--base-font-size-home-page) * (10 / 32)));
  /* overflow-y: auto; */
  width: 82%;
  /* margin-top: calc(var(--base-font-size-home-page) * 1.2); */
  z-index: 99;
  transition: bottom 0.5s ease-in-out;
  left: 0;
}

.home-page-filter-section-heading {
  width: 88%;
  padding-bottom: 1.5%;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  color: #183b56;
  margin-left: 6%;
  margin-bottom: 5%;
  margin-top: calc(var(--base-font-size-home-page) * 1);
  font-size: calc(var(--base-font-size-home-page) * 0.55);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: calc(var(--base-font-size-home-page) * (6 / 32));
  position: relative;
  /* border: 20px solid red; */
}

.filter-section-collections-select-box {
  /* border: 1px solid red; */
  width: calc(var(--base-font-size-home-page) * 1);
  height: calc(var(--base-font-size-home-page) * 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  /* background-color: #b3e7cd; */
}
.filter-section-collections-select-box:hover {
  background-color: #b3e7cd;
}

.home-page-filter-section-heading-mobile {
  width: 88%;
  padding-bottom: 1.5%;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  color: #183b56;
  margin-left: 6%;
  margin-bottom: 5%;
  margin-top: calc(var(--base-font-size-home-page) * (16 / 32));
  font-size: calc(var(--base-font-size-home-page) * 0.55);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: calc(var(--base-font-size-home-page) * (6 / 32));
  position: relative;
}

.home-page-edit-modal-heading {
  /* width: 88%; */
  padding-bottom: 1.5%;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  color: #183b56;
  margin-bottom: calc(var(--base-font-size-home-page) * 0.8);
  font-size: calc(var(--base-font-size-home-page) * 0.55);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: calc(var(--base-font-size-home-page) * (6 / 32)); */
  position: relative;
  /* border: 20px solid red; */
}

.edit-filters-buttons {
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-home-page) * (18 / 32));
}

.select-all-button-home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 35%;
  margin-left: 6%;
  margin-bottom: 3%;
  height: calc(var(--base-font-size-home-page) * 1);
  background: #2fb484;
  padding: 0px 0.2em;
  color: white;
  font-size: calc(var(--base-font-size-home-page) * 0.45);
  font-weight: 700;
  border-radius: calc(var(--base-font-size-home-page) * 0.2);
  cursor: pointer;
}

.filter-section-custom-filters-home-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  line-height: normal;
  margin-bottom: 3%;
  gap: calc(var(--base-font-size-home-page) * (10 / 32));
}
.filter-edit--modal-filters-home-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: left;
  align-items: center;
  line-height: normal;
  /* margin-bottom: 2%; */
  gap: calc(var(--base-font-size-home-page) * (16 / 32));
}

.filter-section-custom-filters-save {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: 25%; */
  height: calc(var(--base-font-size-home-page) * 1);
  background: #2fb484;
  padding: calc(var(--base-font-size-collection-page) * (7 / 32))
    calc(var(--base-font-size-collection-page) * (10 / 32));
  color: white;
  font-size: calc(var(--base-font-size-home-page) * 0.45);
  font-weight: 700;
  border-radius: calc(var(--base-font-size-home-page) * 0.2);
  cursor: pointer;
}

.filter-groups-each-apply-button {
  padding: 0.3em 0.5em;
  background-color: rgba(47, 180, 131, 1);
  font-size: calc(var(--base-font-size-collection-page) * 0.4);
  font-weight: bold;
  text-align: center;
  border-radius: calc(var(--base-font-size-collection-page) * 8 / 32);
  color: white;
  cursor: pointer;
  width: 100%;
}

.filter-groups-each-apply-button-edit {
  width: 100%;
  display: flex;
  gap: 1em;
  margin-top: 0.5em;
  border-top: 0.5px solid #747474;
  padding-top: 0.5em;
  margin-bottom: 0.2em;
  justify-content: space-between;
  align-items: center;
}

.filter-tour-start-button {
  width: auto;
  padding: 0.5em 0.4em;
  margin-left: 0.1em;
  text-align: center;
  background-color: rgba(47, 180, 131, 1);
  font-size: calc(var(--base-font-size-collection-page) * 0.45);
  font-weight: bold;
  border-radius: calc(var(--base-font-size-collection-page) * 12 / 32);
  color: white;
  cursor: pointer;
}

.each-filter-group-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(var(--base-font-size-collection-page) * 8);
  padding: 0.5em;
  margin-bottom: calc(var(--base-font-size-collection-page) * 16 / 32);
  border-radius: calc(var(--base-font-size-home-page) * 0.2);
  border: 0.5px solid #2fb484;
  margin-left: 6%;
}

.remove-applied-filter {
  background-color: rgba(255, 0, 0, 0.74);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.5em 0em;
  width: 1.5em;
  font-size: calc(var(--base-font-size-home-page) * 0.4);
  font-weight: 800;
  border-radius: calc(var(--base-font-size-home-page) * 0.2);
  cursor: pointer;
}

.each-filter-group-row-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2fb48329;
  width: calc(var(--base-font-size-collection-page) * 8);
  padding: 0.5em;
  margin-bottom: calc(var(--base-font-size-collection-page) * 16 / 32);
  border-radius: calc(var(--base-font-size-home-page) * 0.2);
  border: 0.5px solid #2fb484;
  margin-left: 6%;
}

.filter-groups-each-name {
  color: #183b56;
  font-size: calc(var(--base-font-size-home-page) * 0.5);
  font-weight: 600;
}
.filter-groups-each-filter {
  color: #183b56;
  margin-top: 0.2em;
  font-size: calc(var(--base-font-size-home-page) * 0.45);
}

.filter-delete-icon-span {
  position: absolute;
  top: 41.5em;
  right: 1.2em;
}

.filter-section-search-bar-home-page {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: calc(var(--base-font-size-folders-page) * (30 / 32));
  background: white;
  padding: 0px 0.1em;
  color: #183b56;
  border: none;
  border: 0.1em solid #2fb484;
  font-size: calc(var(--base-font-size-home-page) * 0.45);
  font-weight: 700;
  border-radius: calc(var(--base-font-size-home-page) * 0.2);
  cursor: pointer;
}
.filter-section-search-bar-home-page-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 75%;
  margin-left: 6%;
  /* margin-bottom: 3%; */
  height: calc(var(--base-font-size-folders-page) * (30 / 32));
  background: white;
  padding: 0px 0.1em;
  color: #183b56;
  border: none;
  border: 0.1em solid #2fb484;
  font-size: calc(var(--base-font-size-home-page) * 0.45);
  font-weight: 700;
  border-radius: calc(var(--base-font-size-home-page) * 0.2);
  cursor: pointer;
}

.home-page-filter-section-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-x: hidden;
  margin-left: 6%;
  /* margin-bottom: 6%; */
  width: 90%;
  height: calc(var(--base-font-size-home-page) * 50);
  overflow-y: scroll;
  padding-right: 10%;
  overflow-y: scroll;
  max-height: calc(var(--base-font-size-home-page) * (250 / 32));
}

.home-page-filter-section-data-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-x: hidden;
  margin-left: 6%;
  margin-bottom: 6%;
  margin-top: 12%;
  width: 90%;
  height: calc(var(--base-font-size-home-page) * 50);
  overflow-y: scroll;
  padding-right: 10%;
  cursor: pointer;
  overflow-y: scroll;
  max-height: calc(var(--base-font-size-home-page) * (250 / 32));
}

/* scrollbar */
.home-page-filter-section-data::-webkit-scrollbar {
  width: 3px;
}

.home-page-filter-section-data::-webkit-scrollbar-track {
  background-color: transparent;
}

.home-page-filter-section-data::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.home-page-filter-section-data::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.home-page-filter-section-data::-webkit-scrollbar {
  width: 3px;
}

.home-page-filter-section-collections {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  justify-content: start;
  line-height: normal;
  margin-bottom: 2.5%;
  font-size: calc(var(--base-font-size-home-page) * 0.46);
  gap: calc(var(--base-font-size-home-page) * (15 / 32));
  /* padding-left: calc(var(--base-font-size-home-page) * (5 / 32)); */
  padding-top: calc(var(--base-font-size-home-page) * (5 / 32));
  padding-bottom: calc(var(--base-font-size-home-page) * (5 / 32));
}

.filter-section-collections-sort-row {
  position: relative;
  width: 90%;
  margin-left: 6%;

  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  align-items: center;

  padding-top: calc(var(--base-font-size-home-page) * (5 / 32));
  padding-bottom: calc(var(--base-font-size-home-page) * (5 / 32));

  margin-bottom: 5%;
}

.home-page-all-selected-filters {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 1em;
  flex-wrap: wrap;
}

.home-page-selected-filters {
  display: flex;
  justify-content: center;
  gap: calc(var(--base-font-size-collection-page) * 0.4);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/* scrollbar */
.home-page-filter-section-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-home-page) * (2 / 32));
}

.home-page-filter-section-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.home-page-filter-section-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: calc(var(--base-font-size-home-page) * (6 / 32));
}

.home-page-filter-section-container::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.home-page-filter-section-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-home-page) * (3 / 32));
}

/* scrollbar */
.home-page-filter-section-mobile::-webkit-scrollbar {
  width: calc(var(--base-font-size-home-page) * (3 / 32));
}

.home-page-filter-section-mobile::-webkit-scrollbar-track {
  background-color: transparent;
}

.home-page-filter-section-mobile::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: calc(var(--base-font-size-home-page) * (6 / 32));
}

.home-page-filter-section-mobile::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.home-page-filter-section-mobile::-webkit-scrollbar {
  width: calc(var(--base-font-size-home-page) * (3 / 32));
}

.home-page-dropdown-options {
  padding: calc(var(--base-font-size-folders-page) * (8 / 32));
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  position: absolute;
  top: 2.5vw;
  background-color: #2fb484;
  border: calc(var(--base-font-size-folders-page) * (2 / 32)) solid #2fb484;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
}

.home-page-dropdown-options-mobile {
  padding: calc(var(--base-font-size-folders-page) * (8 / 32));
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  position: absolute;
  margin-top: calc(var(--base-font-size-folders-page) * 1.5);
  background-color: #2fb484;
  border: calc(var(--base-font-size-folders-page) * (2 / 32)) solid #2fb484;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
}
.home-page-dropdown-option {
  background-color: #fff;
  margin-top: calc(var(--base-font-size-folders-page) * (8 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  padding: calc(var(--base-font-size-folders-page) * (8 / 32));
  text-wrap: wrap;
  word-wrap: break-word;

  border: calc(var(--base-font-size-folders-page) * (1 / 32)) solid #2fb484;
}
.home-page-dropdown-option:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

/* Activity Section CSS */

.activity-section-hero-heading {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: auto;
  color: #183b56;
  font-size: calc(var(--base-font-size-home-page) * 1.2);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.activity-section-hero-heading-div {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100vw; /* Full viewport width */
  height: 7vw;
  /* border: 2px solid red; */
}

.activity-section-hero-sub-heading-div {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100vw; /* Full viewport width */
  margin-bottom: calc(var(--base-font-size-home-page) * 0.6);
  /* height: 8vw; */
}

.activity-section-hero-sub-heading {
  /* border: 2px solid green; */
  display: flex;
  align-self: center;
  justify-items: center;
  text-align: center;
  width: 60%;
  color: #183b56;
  font-size: calc(var(--base-font-size-home-page) * 0.6);
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  /* width: 130%; */
}

.activity-section-hero-bullet-div {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Center vertically */
  width: 100vw; /* Full viewport width */
  margin-bottom: calc(var(--base-font-size-home-page) * 0.6);
  gap: 2vw;
}

.activity-section-hero-each-bullet-div {
  border: 1px solid #b3e7cd;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--base-font-size-folders-page) * (16 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  height: calc(var(--base-font-size-folders-page) * 6.5);
  width: calc(var(--base-font-size-folders-page) * 9);
  padding: 20px calc(var(--base-font-size-folders-page) * 1);
  /* align-content: center; */
  background: rgb(47, 180, 132);
  background: linear-gradient(
    146deg,
    rgba(47, 180, 132, 1) 0%,
    rgba(22, 102, 77, 1) 81%,
    rgba(17, 67, 53, 1) 100%
  );
}

.activity-section-hero-each-bullet-div .bold-text {
  font-weight: bold;
  color: white;
  line-height: 1.5;
  min-height: 3rem; /* Adjust based on the desired height */
}

.activity-section-hero-each-bullet-div .regular-text {
  color: white;
  line-height: 1.5;
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
}

.activity-section-hero-button-div {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100vw; /* Full viewport width */

  transition: transform 0.4s;
}

.activity-section-hero-button-div:hover {
  transform: scale(1.05);
}

.right-arrow {
  transition: transform 0.4s ease-in-out;
}
.activity-section-hero-button-div:hover .right-arrow {
  transform: translateX(10px) scaleX(1.1);
}
