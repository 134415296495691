:root {
  --base-font-size-creator-page: 2.4vw;
  --base-font-size-header: 2vw;
}

/* creator Details section */
.creator-profile-image {
  width: calc(var(--base-font-size-creator-page) * 10.6);
  height: calc(var(--base-font-size-creator-page) * 11.6);
  border-radius: calc(var(--base-font-size-creator-page) * (24 / 32)) 0px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.35);
  object-fit: cover;
}

.creator-profile-image-mobile {
  position: relative;
  margin-top: 14%;
  width: 100%;
  padding: none;
  height: calc(var(--base-font-size-creator-page) * 15);
  overflow: hidden;
  object-fit: cover;
  max-height: 60vw;
}

.creator-page-hello {
  color: #183b56;
  font-size: calc(var(--base-font-size-creator-page) * 0.5);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.creator-page-iam {
  color: #183b56;
  font-size: calc(var(--base-font-size-creator-page) * 1);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.creator-name {
  color: #2fb484;
  font-size: calc(var(--base-font-size-creator-page) * 1);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.creator-name-container-mobile {
  width: 80%;
  margin-left: 10%;
  margin-top: calc(var(--base-font-size-creator-page) * 0.5);
}

.collection-icons-container-mobile {
  align-self: flex-end;
  padding-top: calc(var(--base-font-size-creator-page) * 0.5);
  padding-right: calc(var(--base-font-size-creator-page) * 1);
}

.creator-details-main-conatiner {
  display: flex;
  flex-direction: row;
  padding-top: 8vw;
  padding-left: 6vw;
  gap: calc(var(--base-font-size-creator-page) * 1.2);
}
.creator-main-details-value {
  color: #2fb484;
  font-size: calc(var(--base-font-size-creator-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.creator-main-details-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-creator-page) * 0.4);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.creator-secondary-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-wrap: nowrap;
}
.creator-secondary-details-heading {
  color: #2fb484;
  font-size: calc(var(--base-font-size-creator-page) * 0.4);
  font-style: normal;
  margin-left: 0.4vw;
  font-weight: 700;
  line-height: normal;
}

.creator-last-updated-time-row {
  display: flex;

  align-items: center;
  margin-top: calc(var(--base-font-size-creator-page) * 0.4);
  font-size: calc(var(--base-font-size-creator-page) * 0.35);
}

.creator-main-details-container-box {
  width: calc(var(--base-font-size-creator-page) * (480 / 32));
  height: calc(var(--base-font-size-creator-page) * (75 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (16 / 32));
  background: white;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding-left: calc(var(--base-font-size-creator-page) * 1.2);
  padding-right: calc(var(--base-font-size-creator-page) * 1.2); */
  align-items: center;
  text-align: center;
  margin: calc(var(--base-font-size-creator-page) * (15 / 32)) 0
    calc(var(--base-font-size-creator-page) * (5 / 32)) 0;
}

.creator-main-details-container-box-mobile {
  position: relative;
  width: 80%;
  height: calc(var(--base-font-size-creator-page) * (104 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (16 / 32));
  background: white;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-left: 10%;
  margin-top: calc(var(--base-font-size-creator-page) * 1);
  margin-bottom: calc(var(--base-font-size-creator-page) * 1);
}

/* Add more media queries as needed for different screen sizes */

.creator-main-details-container-box::before,
.creator-main-details-container-box::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  width: calc(var(--base-font-size-creator-page) * (2.5 / 32));
  background-color: #2fb484;
}

.creator-main-details-container-box::before {
  left: 0;
}

.creator-main-details-container-box::after {
  right: 0;
}

.creator-main-details-container-box-mobile::before,
.creator-main-details-container-box-mobile::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60%;
  width: calc(var(--base-font-size-creator-page) * (2.5 / 32));
  background-color: #2fb484;
}

.creator-main-details-container-box-mobile::before {
  left: 0;
}

.creator-main-details-container-box-mobile::after {
  right: 0;
}

.creator-main-details {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-creator-page) * (7 / 32));
}
.creator-main-details-onwers-tab {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-creator-page) * (7 / 32));
  cursor: pointer;
  background-color: #2fb48315;
  padding-left: 15%;
  padding-right: 15%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.creator-main-details-onwers-tab:hover {
  background-color: rgba(47, 177, 129, 0.283);
}

.creator-main-details-onwers-tab::before,
.creator-main-details-onwers-tab::after {
  content: "";
  position: absolute;
  top: 20%;
  bottom: 20%;
  width: 1px;
  background-color: #d9d9d9;
}

.creator-main-details-onwers-tab::before {
  left: 0;
}

.creator-main-details-onwers-tab::after {
  right: 0;
}

.creator-secondary-values {
  border-bottom: 1px solid #d9d9d9;
  width: calc(var(--base-font-size-creator-page) * (190 / 32));
  padding: calc(var(--base-font-size-creator-page) * (12 / 32)) 0
    calc(var(--base-font-size-creator-page) * (12 / 32)) 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: calc(var(--base-font-size-creator-page) * 0.4);
}
.creator-secondary-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 3rem;
}

.creator-secondary-container-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-left: 10%;
  margin-bottom: calc(var(--base-font-size-creator-page) * 1);
}
.creator-main-details-container-box-line {
  width: calc(var(--base-font-size-creator-page) * (1 / 32));
  height: calc(var(--base-font-size-creator-page) * (40 / 32));
  background-color: #d9d9d9;
}

/* creator announcement section*/

.creator-announcement-box {
  width: calc(var(--base-font-size-creator-page) * (280 / 32));
  height: calc(var(--base-font-size-creator-page) * (210 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (16 / 32));
  background: white;
  box-shadow: 0px calc(var(--base-font-size-creator-page) * (4 / 32))
    calc(var(--base-font-size-creator-page) * (15 / 32)) 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding: calc(var(--base-font-size-creator-page) * (15 / 32));
  margin-top: 5%;
  display: flex;
  overflow-wrap: break-word;
  flex-direction: column;
  gap: calc(var(--base-font-size-creator-page) * (10 / 32));
  overflow-y: auto;
}

.creator-announcement-box-mobile {
  width: 80%;
  margin-left: 10%;
  height: calc(var(--base-font-size-creator-page) * (160 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (16 / 32));
  background: white;
  box-shadow: 0px calc(var(--base-font-size-creator-page) * (4 / 32))
    calc(var(--base-font-size-creator-page) * (15 / 32)) 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding: calc(var(--base-font-size-creator-page) * (15 / 32));
  margin-top: calc(var(--base-font-size-creator-page) * 1);
  display: flex;
  overflow-wrap: break-word;
  flex-direction: column;
  gap: calc(var(--base-font-size-creator-page) * (10 / 32));
  overflow-y: auto;
}

.creator-announcement-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-creator-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 10px;
}
.creator-announcement-data {
  color: #183b56;
  font-size: calc(var(--base-font-size-creator-page) * 0.4);
  font-style: normal;
  line-height: normal;
  overflow: auto;
  cursor: pointer;
  height: auto;
  min-height: calc(var(--base-font-size-creator-page) * (90 / 32));
}

.creator-announcement {
  display: flex;
  justify-content: left;
  align-items: center;
}

.creator-announcement-title {
  color: #183b56;
  font-size: calc(var(--base-font-size-creator-page) * 0.4);
  margin-bottom: 1em;
}

.creator-socials-box {
  width: calc(var(--base-font-size-creator-page) * (280 / 32));
  height: calc(var(--base-font-size-creator-page) * (60 / 32));
  border-radius: 16px;
  background: white;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.creator-social-box {
  width: calc(var(--base-font-size-creator-page) * (40 / 32));
  height: calc(var(--base-font-size-creator-page) * (40 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (12 / 32));
  background: #fff;
  /* box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.12); */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: calc(var(--base-font-size-creator-page) * 0.1);
}
.creator-social-box:hover {
  border: 1px solid #2fb484;
}

.creator-socials-box-mobile {
  width: 80%;
  margin-left: 10%;
  height: calc(var(--base-font-size-creator-page) * (60 / 32));
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-creator-page) * (12 / 32));
  background: white;
  box-shadow: 0px calc(var(--base-font-size-creator-page) * 0.02)
    calc(var(--base-font-size-creator-page) * 0.7) 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  margin-top: calc(var(--base-font-size-creator-page) * 1);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.creator-announcements-icon-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: calc(var(--base-font-size-creator-page) * (40 / 32));
  gap: calc(var(--base-font-size-creator-page) * (10 / 32));
  align-self: flex-end;
}

/* Creator Bio Section */
.box-container {
  margin-top: calc(var(--base-font-size-creator-page) * 1);
  margin-left: 7.5%;
  width: 85%;
  padding: calc(var(--base-font-size-creator-page) * (30 / 32));
  padding-top: calc(var(--base-font-size-creator-page) * (20 / 32));
  padding-bottom: calc(var(--base-font-size-creator-page) * (20 / 32));
  height: auto;
  flex-shrink: 0;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
}

.box-container-mobile {
  width: 80%;
  margin-left: 10%;
  padding: calc(var(--base-font-size-creator-page) * (30 / 32));
  height: auto;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  margin-top: calc(var(--base-font-size-creator-page) * 1);
}

.creator-bio-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-creator-page) * 1);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: calc(var(--base-font-size-creator-page) * (20 / 32));
  padding-bottom: calc(var(--base-font-size-creator-page) * (10 / 32));
}

.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--base-font-size-creator-page) * (20 / 32));
}

.content {
  flex: 1;
  color: #2fb4835c;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #183b56;
  font-size: calc(var(--base-font-size-creator-page) * 0.5);
  font-style: normal;
  font-weight: 500;
  margin-right: calc(var(--base-font-size-creator-page) * (30 / 32));
}

.bio-info-icon-row {
  border: 0.5px solid #2fb484;
  border-radius: calc(var(--base-font-size-creator-page) * (8 / 32));
  width: auto;
  display: flex;
  align-items: center;
  padding: calc(var(--base-font-size-creator-page) * (2 / 32));
  padding-left: calc(var(--base-font-size-creator-page) * (4 / 32));
  display: flex;
  line-height: normal;
  color: #747474;
  font-size: calc(var(--base-font-size-creator-page) * 0.3);
  flex-direction: row;
  gap: calc(var(--base-font-size-creator-page) * (10 / 32));
  align-items: center;
}

.bio-info-icon-row-mobile {
  border: 0.5px solid #2fb484;
  border-radius: calc(var(--base-font-size-creator-page) * (8 / 32));
  width: auto;
  display: flex;
  align-items: center;
  padding: calc(var(--base-font-size-creator-page) * (2 / 32));
  padding-left: calc(var(--base-font-size-creator-page) * (4 / 32));
  display: flex;
  line-height: normal;
  color: #747474;
  margin-top: 5%;
  font-size: calc(var(--base-font-size-creator-page) * 0.3);
  flex-direction: row;
  gap: calc(var(--base-font-size-creator-page) * (10 / 32));
  align-items: center;
}

.video {
  flex: 1;
  border: 0.5px solid #d9d9d9;
  object-fit: cover;
  border-radius: calc(var(--base-font-size-creator-page) * (12 / 32));
  min-width: auto;
  max-width: 100%;
  min-width: 300px;
  width: 100%;
  height: calc(var(--base-font-size-creator-page) * (300 / 32));
}

.video-mobile {
  width: 100%;
  margin-top: calc(var(--base-font-size-creator-page) * 0.5);
  height: calc(var(--base-font-size-creator-page) * (300 / 32));
  margin-bottom: calc(var(--base-font-size-creator-page) * 0.5);
}

.creator-banner-opensea {
  flex: 1;
  border: 0.5px solid #d9d9d9;
  object-fit: contain;
  border-radius: calc(var(--base-font-size-creator-page) * (12 / 32));
  min-width: auto;
  max-width: 100%;
  min-width: 300px;
  width: 100%;
  height: calc(var(--base-font-size-creator-page) * (300 / 32));
}
.creator-banner-opensea-mobile {
  width: 100%;
  margin-top: calc(var(--base-font-size-creator-page) * 0.5);
  height: calc(var(--base-font-size-creator-page) * (300 / 32));
  margin-bottom: calc(var(--base-font-size-creator-page) * 0.5);
}

.creator-bio-button {
  border-radius: 50px;
  background: #2fb484;
  color: #fff;
  font-size: calc(var(--base-font-size-creator-page) * 0.4);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  border: none;
  padding: calc(var(--base-font-size-creator-page) * (8 / 32))
    calc(var(--base-font-size-creator-page) * (20 / 32))
    calc(var(--base-font-size-creator-page) * (8 / 32))
    calc(var(--base-font-size-creator-page) * (20 / 32));
  margin-right: 20px;
  margin-top: calc(var(--base-font-size-creator-page) * 0.4);
}

/* Creator Collections Section */

.collections-container {
  display: flex;
  overflow-x: auto;
  width: 100%;
  margin-top: calc(var(--base-font-size-creator-page) * 40 / 64);
  /* margin-bottom: calc(var(--base-font-size-creator-page) * 5 / 64); */
}

.collection-box {
  margin: 0 calc(var(--base-font-size-creator-page) * 0.4);
  padding: calc(var(--base-font-size-creator-page) * 0.5);
  width: auto;
  max-width: calc(var(--base-font-size-creator-page) * (353 / 32));
  height: calc(var(--base-font-size-creator-page) * (300 / 32));
  /* background: rgba(255, 255, 255, 0.35); */
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.5px);
  margin-bottom: calc(var(--base-font-size-creator-page) * (14 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (16 / 32));
}

.creator-scroll-button {
  width: calc(var(--base-font-size-creator-page) * 5);
  height: calc(var(--base-font-size-creator-page) * 5);
  color: black;
  position: absolute;
  box-shadow: inset 0 2px 0 0 hsla(0, 0%, 100%, 0.2),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  top: 40%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  border: none;
  cursor: pointer;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 50%;
}

.creator-scroll-button.left {
  left: calc(var(--base-font-size-creator-page) * -3);
  padding-left: calc(var(--base-font-size-creator-page) * 3);
  margin-left: 0vw;
}

.creator-scroll-button.right {
  right: calc(var(--base-font-size-creator-page) * -3);
  padding-right: calc(var(--base-font-size-creator-page) * 3);
  margin-right: 0vw;
}

.scroll-button-wrapper:hover .creator-scroll-button {
  opacity: 1;
}

.token-images {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: calc(var(--base-font-size-creator-page) * (20 / 32));
  height: calc(var(--base-font-size-creator-page) * (240 / 32));
  margin-bottom: calc(var(--base-font-size-creator-page) * (5 / 32));
}

.vertical-tokens {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-creator-page) * (10 / 32));
}
.token-img {
  width: calc(var(--base-font-size-creator-page) * (230 / 32));
  height: calc(var(--base-font-size-creator-page) * (230 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (12 / 32));
  object-fit: cover;
  /* border: 0.5px solid #d9d9d9; */
}

.token-img-vertical {
  width: calc(var(--base-font-size-creator-page) * (70 / 32));
  height: calc(var(--base-font-size-creator-page) * (70 / 32));
  border-radius: calc(var(--base-font-size-creator-page) * (12 / 32));
  object-fit: cover;
  /* border: 0.5px solid #d9d9d9; */
}
.collection-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: normal;
  align-items: center;
  padding-right: calc(var(--base-font-size-creator-page) * 0.2);
}

.creator-collection-name {
  color: #183b56;
  font-size: calc(var(--base-font-size-creator-page) * 0.45);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  word-wrap: break-word;
}

.creator-collection-token-count {
  padding-top: 4px;
  color: #747474;
  font-size: calc(var(--base-font-size-creator-page) * 0.4);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Creator Owners Modal CSS */

.modal-overlay-owners-section {
  position: fixed;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding-top: 6%;
  background-color: rgba(0, 0, 0, 0.5); /* Black color with 50% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-x: hidden;
  cursor: pointer;
}
.creator-onwers-modal-container {
  background: white;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 70vh;
  width: 81%;
  justify-content: center;
  align-self: center;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  /* padding: calc(var(--base-font-size-item-page) * 0.5)
    calc(var(--base-font-size-item-page) * 1); */
  padding-top: 0;
  cursor: default;
}

.creator-owner-modal-username {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.creator-owner-modal-address {
  /* color: #183b56; */
  font-size: calc(var(--base-font-size-item-page) * 0.45);
  font-weight: 700;
  line-height: normal;
}
.creator-owner-modal-purchase-date-value {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.creator-owner-modal-purchase-date-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.creator-owner-modal-owns-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.creator-owner-modal-purchase-date-value {
  color: #2fb484;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.creator-owner-modal-view-details-heading {
  color: #747474;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-item-page) * (10 / 32));
  cursor: pointer;
  align-items: center;
}
.creator-owners-user-details {
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-item-page) * (10 / 32));
}
.creator-owners-modal-user-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3%;
}

.creator-owner-modal-profile-pic {
  width: calc(var(--base-font-size-item-page) * (90 / 32));
  height: calc(var(--base-font-size-item-page) * (90 / 32));
  border-radius: 8px;
  background: #d9d9d9;
}
.creator-owner-modal-owns-value {
  color: #2fb484;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.creator-owner-owns-invested-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: right;
}
.creator-owner-name-address-column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
}
.creator-owners-modal-user-second-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3%;
  padding-bottom: 3%;
  border-bottom: 1px solid #d9d9d9;
  overflow-x: auto;
}
.creator-owners-modal-user-second-row-mobile {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-item-page) * (10 / 32));
  margin-bottom: 3%;
  padding-bottom: 3%;
  border-bottom: 1px solid #d9d9d9;
}

/* fonts */
@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  :root {
    --base-font-size-creator-page: 5vw;
  }
}
/* Scrollbar CSS */

.collections-container::-webkit-scrollbar {
  height: calc(var(--base-font-size-creator-page) * 4 / 32);
  background-color: transparent;
}

.collections-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.creator-onwers-modal-container::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.creator-onwers-modal-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.creator-announcement-data::-webkit-scrollbar {
  height: calc(var(--base-font-size-creator-page) * 4 / 32);
  background-color: transparent;
}

.creator-announcement-data::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.creator-announcement-data::-webkit-scrollbar {
  width: calc(var(--base-font-size-creator-page) * 3 / 32);
  background-color: transparent;
}

.creator-announcement-data::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}
