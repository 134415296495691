:root {
  --base-font-size-provenance-report-page: 2vw;
}

/* ProvenanceTokenDetailsSection */

.pdf-main-container {
  margin-top: 50px;
  width: 1000px;
  box-sizing: border-box;
}

.provenance-details-main-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--base-font-size-provenance-report-page) * (50 / 32));
  width: 100%;
  justify-content: center;
  margin-top: 7%;
}

.provenance-details-main-section-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: 6rem;
}
.provenance-item-image-details-container {
  width: calc(var(--base-font-size-provenance-report-page) * (579 / 32));
  height: calc(var(--base-font-size-provenance-report-page) * (624 / 32));
  border-radius: calc(var(--base-font-size-provenance-report-page) * (24 / 32));
  background: #fff;
  box-shadow: 0px calc(var(--base-font-size-provenance-report-page) * (2 / 32))
    calc(var(--base-font-size-provenance-report-page) * (15 / 32)) 0px
    rgba(0, 0, 0, 0.15);
}

.provenance-item-image-details-container-mobile {
  width: calc(var(--base-font-size-provenance-report-page) * (500 / 32));

  border-radius: calc(var(--base-font-size-provenance-report-page) * (24 / 32));
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #fff;
  box-shadow: 0px calc(var(--base-font-size-provenance-report-page) * (2 / 32))
    calc(var(--base-font-size-provenance-report-page) * (15 / 32)) 0px
    rgba(0, 0, 0, 0.15);
}

.provenance-item-image-details-container-pdf {
  margin-top: 50px;
  width: 430px;
  height: 430px;
  border-radius: calc(var(--base-font-size-provenance-report-page) * (24 / 32));
  background: #fff;
}

.provenance-item-image {
  width: 100%;
  height: 92%;
  border-radius: calc(var(--base-font-size-provenance-report-page) * (24 / 32))
    calc(var(--base-font-size-provenance-report-page) * (24 / 32)) 0px 0px;
  object-fit: cover;
}

.provenance-item-image-pdf {
  color: #183b56;
  width: 100%;
  height: 100%;
  border-radius: calc(var(--base-font-size-provenance-report-page) * (24 / 32));
  object-fit: cover;
}

.provenance-token-details-container {
  width: calc(var(--base-font-size-provenance-report-page) * (506 / 32));
  height: calc(var(--base-font-size-provenance-report-page) * (624 / 32));
  border-radius: calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0px calc(var(--base-font-size-provenance-report-page) * (4 / 32))
    calc(var(--base-font-size-provenance-report-page) * (10 / 32)) 0px
    rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding: calc(var(--base-font-size-provenance-report-page) * (27 / 32));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.provenance-token-details-container-mobile {
  color: #183b56;
  width: calc(var(--base-font-size-provenance-report-page) * (500 / 32));
  height: calc(var(--base-font-size-provenance-report-page) * (624 / 32));
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0px calc(var(--base-font-size-provenance-report-page) * (4 / 32))
    calc(var(--base-font-size-provenance-report-page) * (10 / 32)) 0px
    rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding: calc(var(--base-font-size-provenance-report-page) * (27 / 32));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: calc(
    var(--base-font-size-provenance-report-page) * (16 / 32)
  );
  border-bottom-right-radius: calc(
    var(--base-font-size-provenance-report-page) * (16 / 32)
  );
}

.provenance-token-details-container-pdf {
  margin-top: 50px;
  width: 350px;
  height: 430px;
  border-radius: calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  background: rgba(255, 255, 255, 0.35);
  padding: calc(var(--base-font-size-provenance-report-page) * (27 / 32));
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.provenance-token-details-headings {
  color: #183b56;
  font-size: calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
}
.provenance-token-name-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-provenance-report-page) * (32 / 32));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.provenance-token-details-values {
  color: #183b56;
  font-size: calc(var(--base-font-size-provenance-report-page) * (15 / 32));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-wrap: break-word;
}

.provenance-token-highlighted-values {
  color: #2fb484;
  font-size: calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-wrap: wrap;
  word-wrap: break-word;
}

.provenance-token-collection-value {
  color: #2fb484;
  font-size: calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
.provenance-token-image-link {
  color: #2fb484;
  font-size: calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  text-wrap: wrap;
  word-wrap: break-word;
}
.provenance-metadata-data {
  height: auto;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  cursor: all-scroll;
}

.provenance-metadata-data-pdf {
  height: auto;
  overflow-x: hidden;
  white-space: pre-wrap;
  cursor: all-scroll;
}

.provenance-token-description-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: calc(var(--base-font-size-provenance-report-page) * (50 / 32));
}

.provenance-token-description-container-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: calc(var(--base-font-size-provenance-report-page) * (30 / 32));
  align-items: center;
}

.provenance-token-description {
  width: 60%;
  color: #183b56;
  font-size: calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  margin-left: 10%;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}
.provenance-token-description-mobile {
  width: 80%;
  color: #183b56;
  font-size: calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  font-style: normal;
  font-weight: 500;
  line-height: calc(var(--base-font-size-provenance-report-page) * (25 / 32));
  display: flex;
  justify-content: left;
  align-items: flex-start;
}
.creator-qr-code {
  width: calc(var(--base-font-size-provenance-report-page) * (250 / 32));
  height: calc(var(--base-font-size-provenance-report-page) * (250 / 32));
  display: flex;
  justify-content: center;
  align-items: center;
}

.provenance-metadata-section {
  width: 80%;
  color: #183b56;
  font-size: calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-left: 10%;
}

.provenance-traits-sections {
  width: 100%;
  border: 1px solid red;
}

.provenance-sub-headings-header {
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-folders-page) * (35 / 32));
  align-items: center;
  width: 100%;
  margin-top: 5%;
  border-bottom: 2.5px solid #d9d9d9;
  padding-bottom: calc(var(--base-font-size-folders-page) * (7 / 32));
  margin-bottom: calc(var(--base-font-size-folders-page) * (30 / 32));
}

/* ProvenanceArchiveNotesSection */

.provenance-archive-note-section {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-provenance-report-page) * (15 / 32));
  margin-bottom: 5%;
}
.provenance-archive-note-headings {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.provenance-archive-note {
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: calc(var(--base-font-size-provenance-report-page) * (15 / 32));
  cursor: pointer;
  height: auto;
  align-items: center;
  border: 0.5px solid #2fb484;
  border-radius: calc(var(--base-font-size-provenance-report-page) * (12 / 32));
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
}

.provenance-archive-note-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-provenance-report-page) * (15 / 32));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.provenance-archive-note:hover {
  background-color: #2fb48459;
}

.open-note {
  background-color: #2fb48459;
}
/* EachArciveNote */

.provenance-each-archive-note-main-container {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-provenance-report-page) * (25 / 32));
  width: calc(var(--base-font-size-provenance-report-page) * (850 / 32));
  padding: calc(var(--base-font-size-provenance-report-page) * (16 / 32))
    calc(var(--base-font-size-provenance-report-page) * (96 / 32)) 0px
    calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  align-items: center;
  border-radius: 16px;
  background: #f9fafb;
  margin-top: 3%;
  margin-bottom: 2%;
}

.provenance-each-archive-note-main-container-mobile {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-provenance-report-page) * (25 / 32));
  width: calc(var(--base-font-size-provenance-report-page) * (400 / 32));
  padding: calc(var(--base-font-size-provenance-report-page) * (16 / 32))
    calc(var(--base-font-size-provenance-report-page) * (16 / 32)) 0px
    calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  align-items: center;
  border-radius: 16px;
  background: #f9fafb;
  margin-top: 3%;
  margin-bottom: 2%;
}

.provenance-each-archive-note-date {
  display: flex;
  align-self: flex-start;
  color: #183b56;
  font-size: calc(var(--base-font-size-provenance-report-page) * (14 / 32));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.provenance-each-archive-note-details-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--base-font-size-provenance-report-page) * (15 / 32));
  margin-bottom: 5%;
  width: 90%;
}
.provenance-each-archive-note-each-detail {
  width: 100%;
  padding-bottom: calc(var(--base-font-size-provenance-report-page) * (7 / 32));
  border-bottom: 1px solid #d9d9d9;
  color: #183b56;
  /* margin-left: 5%; */
  font-size: calc(var(--base-font-size-provenance-report-page) * (14 / 32));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* padding-left: 15%; */
}

.provenance-each-archive-note-each-detail-mobile {
  width: 100%;
  padding-bottom: calc(var(--base-font-size-provenance-report-page) * (7 / 32));
  border-bottom: 1px solid #d9d9d9;
  color: #183b56;
  /* margin-left: 5%; */
  font-size: calc(var(--base-font-size-provenance-report-page) * (14 / 32));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* padding-left: 15%; */
}

.provenance-each-archive-note-image {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-provenance-report-page) * (8 / 32));
  gap: calc(var(--base-font-size-provenance-report-page) * (20 / 32));
}
.provenance-note-links {
  font-size: calc(var(--base-font-size-provenance-report-page) * (14 / 32));
  font-style: normal;
  color: #183b56;
  font-weight: 700;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-color: #2fb484;
  text-wrap: wrap;
  word-wrap: break-word;
}

/* fonts */

@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  :root {
    --base-font-size-provenance-report-page: 5vw;
  }
}

/* scroll-bar */

.provenance-metadata-data::-webkit-scrollbar {
  width: 3px;
}

.provenance-metadata-data::-webkit-scrollbar-track {
  background-color: transparent;
}

.provenance-metadata-data::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.provenance-metadata-data::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.provenance-metadata-data::-webkit-scrollbar {
  width: 3px;
}

/* pdf only */

.provenance-details-main-section-pdf {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--base-font-size-provenance-report-page) * (200 / 32));
  width: 100%;
  justify-content: center;
  margin-top: 7%;
}

.provenance-each-archive-note-main-container-pdf {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-provenance-report-page) * (25 / 32));
  width: 100%;
  padding: calc(var(--base-font-size-provenance-report-page) * (16 / 32))
    calc(var(--base-font-size-provenance-report-page) * (96 / 32)) 0px
    calc(var(--base-font-size-provenance-report-page) * (16 / 32));
  align-items: center;
  border-radius: 16px;
  background: #f9fafb;
  margin-top: 3%;
  margin-bottom: 2%;
}

.provenance-each-archive-note-each-detail-pdf {
  width: 650px;
  padding-bottom: calc(var(--base-font-size-provenance-report-page) * (7 / 32));
  border-bottom: 1px solid #d9d9d9;
  color: #183b56;
  margin-left: 3.5%;
  font-size: calc(var(--base-font-size-provenance-report-page) * (14 / 32));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 5%;
}

.trait-pdf {
  background-color: white;
  flex: 0 0 calc(31.33% - calc(var(--base-font-size-item-page) * 10 / 20));
  border: 0.5px solid #2fb484;
  padding: 1vw;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 1rem;
  margin-right: 1rem;
  flex-grow: 1;
  height: calc(var(--base-font-size-item-page) * 5);
  max-height: calc(var(--base-font-size-item-page) * 6);
}
