:root {
  --base-font-size-dashboard-page: 2.4vw;
}

/* dashboard charts */

.dashboard-charts-section {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin-top: calc(var(--base-font-size-dashboard-page) * (24 / 32));
  margin-left: 5%;
}

.dashboard-charts-container-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(var(--base-font-size-dashboard-page) * (480 / 32));
  height: calc(var(--base-font-size-dashboard-page) * (380 / 32));
  border-radius: calc(var(--base-font-size-dashboard-page) * (16 / 32));
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px calc(var(--base-font-size-dashboard-page) * (4 / 32))
    calc(var(--base-font-size-dashboard-page) * (15 / 32)) 0px
    rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(calc(var(--base-font-size-dashboard-page) * (3 / 32)));

  font-size: calc(var(--base-font-size-dashboard-page) * (15 / 32));
}

.dashboard-charts-container-box-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 7.5%;
  margin-top: 7%;
  width: 85%;
  min-height: calc(var(--base-font-size-dashboard-page) * (380 / 32));
  height: auto;
  border-radius: calc(var(--base-font-size-dashboard-page) * (16 / 32));
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px calc(var(--base-font-size-dashboard-page) * (4 / 32))
    calc(var(--base-font-size-dashboard-page) * (15 / 32)) 0px
    rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(calc(var(--base-font-size-dashboard-page) * (3 / 32)));

  font-size: calc(var(--base-font-size-dashboard-page) * (15 / 32));
}

.wallet-distribution-modal-container {
  height: 50%;
  width: auto;
  display: flex;
  text-wrap: wrap;
  flex-wrap: wrap;
  flex-direction: column;
  gap: calc(var(--base-font-size-dashboard-page) * (20 / 32));
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10%;
  align-self: center;
}

.wallet-distribution-modal-container-mobile {
  height: 70%;
  width: 100%;
  display: flex;
  gap: calc(var(--base-font-size-dashboard-page) * (10 / 32));
  flex-direction: column;
  text-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.dashboard-charts-wallet-repoty-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: calc(var(--base-font-size-dashboard-page) * (8 / 32));
  font-size: calc(var(--base-font-size-dashboard-page) * (15 / 32));
}

.dashboard-charts-heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  margin-left: 5%;
  margin-top: 2%;
}

.dashboard-charts-main-heading {
  border-radius: calc(var(--base-font-size-dashboard-page) * (8 / 32));
  background: #2fb484;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  display: inline-flex;
  padding: calc(var(--base-font-size-dashboard-page) * (8 / 32))
    calc(var(--base-font-size-dashboard-page) * (16 / 32));
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: calc(var(--base-font-size-dashboard-page) * 0.4);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.activity-trend-tab-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 30vw;
  gap: calc(var(--base-font-size-dashboard-page) * (20 / 32));
  margin-top: calc(var(--base-font-size-dashboard-page) * (10 / 32));
  border-bottom: 1px solid #d9d9d9;
}

.activity-trend-tab {
  position: relative;
  cursor: pointer;
  padding: 1% 2%;
  color: #183b56;
  font-size: calc(var(--base-font-size-dashboard-page) * 0.4);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0.7rem;
}

.tooltip {
  position: absolute;
  text-align: center;
  width: 100px;
  height: auto;
  padding: 2px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}
.x-axis-title,
.y-axis-title {
  color: #183b56;
  font-size: calc(var(--base-font-size-dashboard-page) * 0.35);
  font-weight: bold;
}

.axis{
  color: black;
  width: 2px;
}


.activity-trend-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: #2fb484;
}

.activity-trend-tab.active {
  color: #2fb484;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.activity-trend-tab.active::before {
  width: 100%;
  left: 50%;
  transform: translateX(-50%); /* Center the pseudo-element */
  transition: width 0.3s ease; /* Transition for width */
}

/* fonts */
@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  :root {
    --base-font-size-dashboard-page: 5vw;
  }
}

/* Dashboard Favouriyes Section */

.dashboard-sub-headings-header {
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-dashboard-page) * (35 / 32));
  align-items: center;
  width: auto;
  margin-left: 6%;
  margin-right: 6%;
  margin-top: calc(var(--base-font-size-dashboard-page) * (16 / 32));
  border-bottom: calc(var(--base-font-size-dashboard-page) * 0.07) solid #d9d9d9;
  padding-bottom: calc(var(--base-font-size-dashboard-page) * (7 / 32));
  /* margin-bottom: calc(var(--base-font-size-dashboard-page) * (30 / 32)); */
}

.dashboard-sub-headings {
  display: flex;
  flex-direction: row;
  line-height: normal;
  align-items: center;
  margin-top: calc(var(--base-font-size-dashboard-page) * (24 / 32));
  gap: calc(var(--base-font-size-dashboard-page) * (15 / 32));
  width: 70%;
  color: #183b56;
  font-size: calc(var(--base-font-size-dashboard-page) * 0.6);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.folders-add-button {
  padding: calc(var(--base-font-size-dashboard-page) * (6 / 32))
    calc(var(--base-font-size-dashboard-page) * (10 / 32));

  border-radius: calc(var(--base-font-size-dashboard-page) * (8 / 32));
  background: #2fb484;
  cursor: pointer;
}

.favourites-add-button {
  display: flex;
  justify-content: space-between;
  padding: calc(var(--base-font-size-dashboard-page) * (6 / 32))
    calc(var(--base-font-size-dashboard-page) * (10 / 32));
  align-items: center;
  gap: calc(var(--base-font-size-dashboard-page) * (6 / 32));
  border-radius: calc(var(--base-font-size-dashboard-page) * (8 / 32));
  background: #2fb484;
  cursor: pointer;
  box-shadow: inset 0 2px 0 0 hsla(0, 0%, 100%, 0.2),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.favourites-add-button:hover {
  background: #1b805e;
}

.favourites-add-text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.favourites-add-icon {
  color: #183b56;
  font-size: calc(var(--base-font-size-dashboard-page) * 0.3);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: 0.5px solid white;
  background-color: #fff;
  padding: calc(var(--base-font-size-dashboard-page) * (0 / 32))
    calc(var(--base-font-size-dashboard-page) * (3 / 32));
  border-radius: 50%;
}

.favourites-container-box {
  width: 100%;
  height: calc(var(--base-font-size-dashboard-page) * (250 / 32));
  /* margin-left: 5%; */
  border-radius: calc(var(--base-font-size-dashboard-page) * (16 / 32));
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px calc(var(--base-font-size-dashboard-page) * (4 / 32))
    calc(var(--base-font-size-dashboard-page) * (15 / 32)) 0px
    rgba(0, 0, 0, 0.15);
  margin-bottom: calc(var(--base-font-size-dashboard-page) * (30 / 32));
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 1vw;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: calc(var(--base-font-size-dashboard-page) * (2 / 5));
}
.scrollable-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.scrollable-container {
  display: flex;
  overflow-x: auto;
  width: 100%;
}
.scrollable-wrapper:hover .scroll-button {
  opacity: 1;
}
.scroll-button {
  height: calc(var(--base-font-size-dashboard-page) * 4);
  color: black;
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: inset 0 2px 0 0 hsla(0, 0%, 100%, 0.2),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border: none;
}

.scroll-button.left {
  width: 0.5em;
  padding: 2em !important;
  /* left: 5%; */
  border-radius: 0% 100px 100px 0%;
}

.scroll-button.right {
  right: 0;
  width: 0.5em;
  padding: 2em !important;
  border-radius: 100px 0% 0% 100px;
}

.favourites-container-box::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.favourites-container-box::-webkit-scrollbar {
  height: calc(var(--base-font-size-dashboard-page) * (4 / 32));
  background-color: transparent;
}

.favorites-container-box-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: column;
  height: 16em;
  background-color: rgba(47, 180, 132, 0.15);
  border-radius: 1em;
  margin-left: 1em;
  cursor: pointer;
  color: #183b56;
}
.favorites-container-box-cards:hover {
  background-color: #2fb484;
  color: white;
}

.favorites-container-box-cards-heading {
  font-size: 1em;
  font-weight: 600;
  padding-top: 0.7em;
  padding-bottom: 0.5em;
  white-space: nowrap;
  width: 13em;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.dashboard-favorites-headings-row {
  width: 80%;
  margin-left: 6%;
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-dashboard-page) * (30 / 32));
  cursor: pointer;
  align-items: center;
  margin-top: calc(var(--base-font-size-dashboard-page) * (16 / 32));
  margin-bottom: calc(var(--base-font-size-dashboard-page) * (16 / 32));
}

.dashboard-favorites-sub-headings {
  display: flex;
  flex-direction: row;
  line-height: normal;
  align-items: center;
  color: #183b56;
  border: 0.5px solid #d9d9d9;
  padding: calc(var(--base-font-size-dashboard-page) * (5 / 32))
    calc(var(--base-font-size-dashboard-page) * (6 / 32));
  border-radius: calc(var(--base-font-size-dashboard-page) * (8 / 32));
  background-color: #ecf0f9;
  font-size: calc(var(--base-font-size-dashboard-page) * 0.4);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dashboard-favorites-sub-headings.active {
  background-color: #2fb484;
  color: #fff;
  font-style: normal;
  line-height: normal;
}

.fav-tab-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 43vw;
  gap: calc(var(--base-font-size-dashboard-page) * (40 / 32));
  padding-bottom: 0.5%;
  margin-top: calc(var(--base-font-size-dashboard-page) * (20 / 32));
  /* border-bottom: 1.5px solid #d9d9d9; */
  margin-bottom: 0.5em;
}

.fav-trend-tab {
  position: relative;
  cursor: pointer;
  width: 100%;
  color: #183b56;
  font-size: calc(var(--base-font-size-dashboard-page) * 0.44);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0.7rem;

  text-wrap: nowrap;
}

.fav-trend-tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: #2fb484;
}

.fav-trend-tab.active {
  color: #2fb484;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.fav-trend-tab.active::before {
  width: 100%;
  left: 50%;
  transform: translateX(-50%); /* Center the pseudo-element */
  transition: width 0.3s ease; /* Transition for width */
}

/* Dashbboard CollectionsAndFolderSection */

.dashboard-collection-folders-tabs {
  width: auto;
  margin-left: 6%;
  margin-right: 6%;
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-dashboard-page) * (50 / 32));
  cursor: pointer;
  align-items: center;
  margin-top: calc(var(--base-font-size-dashboard-page) * (16 / 32));
  border-bottom: calc(var(--base-font-size-dashboard-page) * 0.07) solid #d9d9d9;
  /* padding-bottom: calc(var(--base-font-size-dashboard-page) * (7 / 32)); */
  margin-bottom: calc(var(--base-font-size-dashboard-page) * (30 / 32));
}

.dashboard-tabs-headings {
  color: #183b56;
  font-size: calc(var(--base-font-size-dashboard-page) * 0.6);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  flex-direction: row;
  border-bottom: calc(var(--base-font-size-item-page) * 3 / 20) solid
    rgba(0, 0, 0, 0);

  padding-bottom: calc(var(--base-font-size-dashboard-page) * 0.3);
  gap: 0.5em;
}

.dashboard-tabs-headings::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: calc(var(--base-font-size-item-page) * 3 / 20);
  background-color: #2fb484;
}

.dashboard-tabs-headings.active {
  color: #2fb484;
  font-style: normal;
  border-bottom: calc(var(--base-font-size-item-page) * 3 / 20) solid #2fb484;
  line-height: normal;
}

.dashboard-tabs-headings.active::before {
  width: 100%;
  left: 50%;
  transform: translateX(-50%); /* Center the pseudo-element */
  transition: width 0.3s ease; /* Transition for width */
}

.collections-folder-section-main-container {
  display: flex;
  width: 84%;
  margin-left: 8%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 5%;
}

.collection-folder-details-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120%;
  margin-left: 20%;
  align-items: center;
  gap: calc(var(--base-font-size-dashboard-page) * (10 / 32));
}

.dashboard-collection-table-main-container {
  height: auto;
  width: 100%;
}

.dashboard-collection-table {
  width: 100%;
  margin-bottom: 2%;
}

.dashboard-collection-table th {
  text-align: center;
  font-size: calc(var(--base-font-size-dashboard-page) * 0.4);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 1px solid #d9d9d9;
  color: #183b56;
  background-color: transparent;
  /* border-radius: 2vw; */
  position: sticky;
  top: 0;
  z-index: 2;
}

.dashboard-collection-table td {
  text-align: center;
  font-size: calc(var(--base-font-size-dashboard-page) * 0.4);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #183b56;
  /* cursor: pointer; */
}

.dashboard-collection-table > thead {
  background-color: white;
  position: sticky;
  top: calc(var(--base-font-size-collection-page) * (100 / 32));
}

.dashboard-collection-row-section {
  display: flex;
  padding-bottom: calc(var(--base-font-size-dashboard-page) * (10 / 32));
  flex-direction: row;
  align-items: flex-start;
  gap: calc(var(--base-font-size-dashboard-page) * (10 / 32));
}

.dashbaord-collection-name-row-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20vw;
  gap: calc(var(--base-font-size-dashboard-page) * (10 / 32));
  text-decoration: none;
}

.transfer-history-table-event-row-item-page {
  display: flex;
  flex-direction: row;
  margin-left: 4%;
  margin-top: 3%;
  align-items: center;
}

.transfer-history-table-event {
  font-size: calc(var(--base-font-size-dashboard-page) * 0.35);
}

/* Dashboard Notifications */

.notification-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dashboard-notifications-main-container {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 12.5%;
  margin-top: 5%;
  margin-bottom: 5%;
  padding-bottom: calc(var(--base-font-size-dashboard-page) * (30 / 32));
  border-radius: calc(var(--base-font-size-dashboard-page) * (16 / 32));
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
}
.notifications-form {
  width: 75%;
  margin-left: 12.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: calc(var(--base-font-size-dashboard-page) * (20 / 32));
}

.notification-label {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-dashboard-page) * (6 / 32));
}

.notifications-buttons {
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-dashboard-page) * (20 / 32));
  justify-content: flex-end;
  width: 100%;
  cursor: pointer;
}

.notification-input {
  height: calc(var(--base-font-size-collection-page) * (35 / 32));
  border: none;
  border-radius: calc(var(--base-font-size-collection-page) * (10 / 32));
  background: #ecf0f9;
  color: #183b56;
  padding: calc(var(--base-font-size-collection-page) * (13 / 32))
    calc(var(--base-font-size-collection-page) * (16 / 32));
  gap: calc(var(--base-font-size-collection-page) * (10 / 32));
  width: 30vw;
  cursor: pointer;
}

.notification-input:hover {
  border: 1px solid #2fb484;
}
.notification-input:focus {
  border: 2px solid #2fb484;
}

.notification-alert-input {
  height: calc(var(--base-font-size-collection-page) * (35 / 32));
  border: none;
  border-radius: calc(var(--base-font-size-collection-page) * (10 / 32));
  background: #ecf0f9;
  color: #183b56;
  font-size: calc(var(--base-font-size-collection-page) * 0.35);
  padding: 0 calc(var(--base-font-size-collection-page) * (17 / 32));
  gap: calc(var(--base-font-size-collection-page) * (10 / 32));
  width: auto;
  cursor: pointer;
}
.notification-alert-input:hover {
  border: 1px solid #2fb484;
}
.notification-alert-input:focus {
  border: 2px solid #2fb484;
}

/* Dashboard activity table */

/* scrollbar*/

.dashboard-collection-table-main-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-dashboard-page) * (6 / 32));
  background-color: transparent;
}

.dashboard-collection-table-main-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.dashboard-collection-table-main-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-dashboard-page) * (5 / 32));
  background-color: transparent;
}

.dashboard-collection-table-main-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.dashboard-wallet-details-mobile-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  height: calc(var(--base-font-size-wallet-report-page) * 5);
  align-items: center;
  /* border: 2px solid blue; */
  /* padding: calc(var(--base-font-size-wallet-report-page) * (16 / 32)); */
}
.dashboard-wallet-details-box-mobile {
  display: flex;
  height: calc(var(--base-font-size-wallet-report-page) * 5);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: calc(var(--base-font-size-wallet-report-page) * (6 / 32));
  width: 80%;
  padding: calc(var(--base-font-size-wallet-report-page) * (32 / 32));
}

.dashboard-wallet-details-mobile {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: calc(var(--base-font-size-wallet-report-page) * (32 / 32));
  align-items: center;
}
