:root {
  --base-font-size-search: 2vw;
}

input:focus {
  outline: none;
}

.search-bar {
  height: 45%;
  display: flex;
  justify-content: center;
  width: 25%;
  backdrop-filter: blur (0.2em);
  border: rgba(116, 116, 116, 0.8) solid
    calc(4 / 5 * var(--base-font-size-search) * 0.03);
  height: calc(4 / 5 * var(--base-font-size-search) * 1.6);
  padding-left: 1vw;
  background-color: rgba(216, 216, 216, 0.6);
  border-radius: 0.6vw;
}

.search {
  background: #e2eef1;
  font-size: calc(4 / 5 * var(--base-font-size-search) * 9);
  position: relative;
  border-radius: calc(4 / 5 * var(--base-font-size-search) * (12 / 32));
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border: solid 1px transparent;
  width: 60%;
  padding-left: 30px;
  color: #b7b8b9;
}

.search-container {
  display: flex;
  width: 20%;
  height: 140%;
  /* padding: 12px 20px; */
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  /* border-radius: calc(4 / 5 * var( --base-font-size-search)*12); */
  background: #ecf0f9;
  padding-right: 2%;
  padding-left: 5%;
  margin-right: 20%;
  margin-left: 20%;
  margin-top: -2%;
  /* padding: 7% 20px; */
  padding: 18px 30px;
}

.search-inputbox::placeholder {
  color: #747474;
  /* font-family: Inter; */
  font-size: calc(4 / 5 * var(--base-font-size-search) * 0.6);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.box-content {
  width: 200px;
  padding: 12px;
  border: transparent;
  background: #ecf0f9;
}

.box-border {
  width: 200px;
  padding: 12px;
  border: transparent;
  box-sizing: border-box;
  background: #ecf0f9;
  user-select: none;
  outline: none;
}

.no-highlight-box {
  user-select: none;
}

.reset-search {
  position: absolute;
  right: calc(4 / 5 * var(--base-font-size-search) * 0.8);
  top: 18%;
  cursor: pointer;
  align-self: center;
}

.search-inputbox {
  width: 100%;
  height: 100%;
  padding-left: 1vw;
  border: none;
  background-color: rgba(216, 216, 216, 0);
  cursor: pointer;
}

.search-input-icon {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: calc(var(--base-font-size-collection-page) * (2 / 32));
  padding-right: calc(var(--base-font-size-collection-page) * (8 / 32));
}

.search-bar:focus {
  border: #747474 solid calc(4 / 5 * var(--base-font-size-search) * 0.05);
}
.search-bar:hover {
  border: #747474 solid calc(4 / 5 * var(--base-font-size-search) * 0.05);
}

@keyframes scan-effect {
  0% {
    background-position: 150% 0;
  }
  100% {
    background-position: -150% 0;
  }
}

.loading-background {
  /* Mostly white background with a green scan effect */
  background: linear-gradient(
    to right,
    rgba(47, 180, 132, 0.4) 25%,
    #2fb484 50%,
    rgba(47, 180, 132, 0.4) 75%
  );
  background-size: 300% 100%;

  /* Animation */
  animation: scan-effect 2s linear infinite;
}

.justify-content-start-0 {
  justify-content: start;
}
