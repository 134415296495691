.alternateRows tbody tr:nth-child(odd) {
  background-color: #2fb48459;
  height: 30px;
}

.alternateRows tbody tr:nth-child(even) {
  background-color: whitesmoke;
}

td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-size: small; */
}

.alternateRows {
  width: 100%;
  margin-top: 13px;
}
