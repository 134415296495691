:root {
  --base-font-size-item-page: 2vw;
}

::-webkit-scrollbar-button {
  background: transparent;
  border-radius: calc(var(--base-font-size-item-page) * 12 / 20);
}
::-webkit-scrollbar-track-piece {
  background: transparent;
  border-radius: calc(var(--base-font-size-item-page) * 12 / 20);
  height: calc(var(--base-font-size-item-page) * 10 / 20);
}
::-webkit-scrollbar-thumb {
  background: #2fb48321;
  border-radius: calc(var(--base-font-size-item-page) * 12 / 20);
}

body {
  --item_page_left_comp_width: 40vw;
  --item_page_right_comp_width: calc(
    4.8 / 5 * var(--item_page_left_comp_width)
  );
}

.tab-content-body {
  font-weight: 500;
  word-wrap: break-word;
}

.item-page-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20);
}

.item-page-container-left-column {
  margin-top: calc(var(--base-font-size-item-page) * 2);
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 7%;
  width: 54%;
}

.item-page-container-right-column {
  margin-top: calc(var(--base-font-size-item-page) * 2);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
  padding-left: 2%;
}

.info-container {
  width: var(--item_page_left_comp_width);
}

.tab-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  border-bottom: calc(var(--base-font-size-item-page) * 1 / 20) solid #ccc;
  padding-top: 5%;
  margin-bottom: calc(var(--base-font-size-item-page) * 0.5);
}

.tab {
  position: relative;
  cursor: pointer;
  padding: 1% 2%;
  margin-right: 1%;
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.57);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0.7rem;
}

.tab::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: calc(var(--base-font-size-item-page) * 3 / 20);
  background-color: #2fb484;
}

.tab.active {
  color: #2fb484;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tab.active::before {
  width: 100%;
  left: 50%;
  transform: translateX(-50%); /* Center the pseudo-element */
  transition: width 0.3s ease; /* Transition for width */
}

.tab-content {
  display: flex;
  flex-direction: column;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
}

.tab-content-heading {
  color: #2fb484;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-right: 0.5rem;
  margin-bottom: calc(var(--base-font-size-item-page) * 0.2);
}

/* activity page css*/



.item-transfer-history-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: calc(var(--base-font-size-item-page) * 20 / 20);
  height: auto;
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 1.125rem;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 2 / 20)
    calc(var(--base-font-size-item-page) * 15 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10calc (var(--base-font-size-item-page) * 5 / 20));
  padding: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 7 / 32)
    calc(var(--base-font-size-item-page) * 7 / 32)
    calc(var(--base-font-size-item-page) * 7 / 32);
  padding-top: calc(var(--base-font-size-item-page) * 0.1);

  font-size: calc(var(--base-font-size-item-page) * 0.4);
}

.alternateRows {
  width: 100%;
  max-width: 100%;
  margin-top: 0.7rem;
  /* border: calc(var(--base-font-size-item-page) * 1 / 20) solid red; */
}

.alternateRows tbody tr:nth-child(odd) {
  background-color: transparent;
  height: 2rem;
  width: 100%;
  border-bottom: 0calc (var(--base-font-size-item-page) * 5 / 20) solid #d9d9d9;
  color: #183b56;
  font-style: normal;
  line-height: normal;
  border-left: calc(var(--base-font-size-item-page) * (4 / 32)) solid
    transparent;
}

.alternateRows tbody tr:nth-child(even) {
  background-color: transparent;
  height: 2rem;
  width: 100%;
  border-bottom: 0calc (var(--base-font-size-item-page) * 5 / 20) solid #d9d9d9;
  color: #183b56;
  font-style: normal;
  line-height: normal;
  border-left: calc(var(--base-font-size-item-page) * (4 / 32)) solid
    transparent;
}

.alternateRows tbody tr:nth-child(odd):hover {
  background-color: #2fb48459;
  border-left: calc(var(--base-font-size-item-page) * (4 / 32)) solid #1b805e;
}

.alternateRows tbody tr:nth-child(even):hover {
  background-color: #2fb48459;
  border-left: calc(var(--base-font-size-item-page) * (4 / 32)) solid #1b805e;
}

td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.sales-history-filterdata-heading {
  color: #183b56;
  width: 100%;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  display: flex;
  flex-direction: row;
  line-height: normal;
  font-weight: 700;
  justify-content: flex-start;
  align-items: center;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: calc(var(--base-font-size-item-page) * 0.6);
}
.sales-history-filterdata-dropdown {
  border: calc(var(--base-font-size-item-page) * 1 / 20) solid #2fb484;
  padding: calc(var(--base-font-size-item-page) * 2 / 20);
  /* margin-left: calc(var(--base-font-size-item-page) * 10 / 20); */
  border-radius: calc(var(--base-font-size-item-page) * 5 / 20);
  font-size: calc(var(--base-font-size-item-page) * 0.4);
  font-style: normal;
  font-weight: bold;
  cursor: pointer;
}
/* current-prices component css*/

.current-prices-container {
  padding-top: 0.1rem;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  overflow-x: auto;
  margin-top: calc(var(--base-font-size-item-page) * 10 / 20);
}

.current-prices-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  gap: calc(var(--base-font-size-item-page) * 0.3);

  width: 100%;
  margin-bottom: calc(var(--base-font-size-item-page) * 0.5);
  height: calc(var(--base-font-size-item-page) * 1.5);
}

.current-prices-row-marketplace-icon-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.current-prices-row-marketplace-icon {
  border-radius: calc(var(--base-font-size-item-page) * 8 / 32);
  width: calc(var(--base-font-size-item-page) * 0.9);
  height: calc(var(--base-font-size-item-page) * 0.9);
}

.current-prices-row-data-block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  align-items: center;
  text-align: center;
}

.current-prices-row-2-row-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: calc(var(--base-font-size-item-page) * 0.3);
}

.current-prices-row-2-row-block-right {
  align-items: flex-end;
}

.current-prices-row-2-row-block-upper-value {
  font-size: calc(var(--base-font-size-item-page) * (16 / 32));
  font-weight: bold;
  line-height: 1.2rem;
  letter-spacing: 0em;
  text-align: left;
  color: #183b56;
}

.current-prices-row-2-row-block-lower-value {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 0.2em;
  font-size: calc(var(--base-font-size-item-page) * 0.4);
  font-style: italic;
  font-weight: 550;
  line-height: 0.9rem;
  text-align: left;
  color: #747474;
}

/* description component css*/

a {
  text-decoration: none;
}

h2 .collection-name {
  display: inline-block;
  color: #2fb484;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
}

span {
  color: #183b56;
  text-decoration-line: none;
  /* font-weight: 500; */
}

p {
  width: 100%;
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 400;
  line-height: 1.375em;
}

/* details component css*/

.detail-section {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-item-page) * 0.5);
  width: 100%;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
}

.detail {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 500;
  line-height: 1em;
}

.details-header {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  padding-bottom: 1%;
  width: 100%;
  margin-bottom: 3%;
  border-bottom: calc(var(--base-font-size-item-page) * 1 / 20) solid #ccc;
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem;
}

.token-info-detail-metadata {
  text-decoration: underline;
  cursor: pointer;
  color: #183b56;
}

.item-metadata-data-container-box {
  overflow: auto;
  cursor: pointer;
}

.item-metadata-data {
  width: 100%;
  overflow: auto;
  white-space: pre-wrap;
  text-align: left;
  align-items: center;
  font-family: Inter;
  margin: calc(var(--base-font-size-item-page) * 10 / 20);
  line-height: 1.6em;
}

/* highlights component css*/

.highlights-header {
  border-bottom: calc(var(--base-font-size-item-page) * 1 / 20) solid #ccc;
  width: 100%;
  margin-bottom: 1.875rem;
  margin-top: 0.9rem;
}

/* content-box css*/

#item-page-content-box {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  overflow: hidden;
  height: auto;
  border-radius: calc(var(--base-font-size-item-page) * 0.4);
  background: rgba(255, 255, 255, 0.25);
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * (2 / 20))
    calc(var(--base-font-size-item-page) * (15 / 20))
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(calc(var(--base-font-size-item-page) * (10.5 / 20)));
  width: var(--item_page_right_comp_width);

  font-size: calc(var(--base-font-size-item-page) * 0.5);

  margin-bottom: calc(var(--base-font-size-item-page) * (18 / 32));
  padding: calc(var(--base-font-size-item-page) * (10 / 20))
    calc(var(--base-font-size-item-page) * (15 / 20))
    calc(var(--base-font-size-item-page) * (5 / 20))
    calc(var(--base-font-size-item-page) * (15 / 20));
}

/* listing price component css*/

.listing-price-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(var(--base-font-size-item-page) * 0.5);
  width: 100%;
  border-bottom: calc(var(--base-font-size-item-page) * 1 / 20) solid #ccc;
}

.listing-price-icons {
  display: flex;
  justify-content: space-between;
  padding-left: 2%;
  gap: 0rem;
  padding-bottom: 0.7rem;

  font-size: calc(var(--base-font-size-item-page) * 0.5);
}
.listing-price-edit-button {
  background-color: white;
  border: none;
}

/* history price component css*/
.history-price-container {
  font-size: calc(var(--base-font-size-item-page) * 0.5);

  color: red;
  padding: calc(var(--base-font-size-item-page) * 10 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20);
}

/* listing price component css*/

/* scorecard component css*/

.scorecard-header {
  border-bottom: calc(var(--base-font-size-item-page) * 1 / 20) solid #ccc;
  width: 100%;
  margin-bottom: 10%;
  margin-top: 0.9rem;
}
.scorecard-heading {
  font-size: calc(var(--base-font-size-item-page) * 0.7);
  color: #2fb484;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* token info component css*/

.token-info-container {
  display: flex;
  flex-direction: column;
  width: var(--item_page_right_comp_width);
  margin-bottom: calc(var(--base-font-size-item-page) * (24 / 32));
}

.token-info-row {
  margin-top: calc(var(--base-font-size-item-page) * 0.6);
}

.token-info-share-icon {
  padding-top: 0calc (var(--base-font-size-item-page) * 8 / 20);
}
.token-info-icons-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: calc(var(--base-font-size-item-page) * 0.6);
}

.token-info-rarity-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
  width: 100%;
  text-align: center;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
}

.collection-name {
  color: #2fb484;
  font-style: normal;
  font-size: calc(var(--base-font-size-item-page) * 0.55);
  font-weight: 700;
  line-height: normal;
  padding-right: 0.5rem;
  background: none;
  cursor: pointer;
}

.collection-name.p-0 {
  text-align: left;
  margin-right: 2%;
}
.token-info-token-ranking {
  padding: calc(var(--base-font-size-item-page) * 3 / 32)
    calc(var(--base-font-size-item-page) * 7 / 32)
    calc(var(--base-font-size-item-page) * 3 / 32)
    calc(var(--base-font-size-item-page) * 7 / 32);
  width: calc(var(--base-font-size-item-page) * 45 / 32);
  height: calc(var(--base-font-size-item-page) * 22 / 32);
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-item-page) * 5 / 32);
  background: #2fb484;
  color: #fff;
  font-size: calc(var(--base-font-size-item-page) * 0.55);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-left: calc(var(--base-font-size-item-page) * 5 / 32);
}
.token-info-total-tokens {
  margin-left: calc(var(--base-font-size-item-page) * 3 / 32);
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.55);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
.rarity-ranking-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.info-icon-container {
  cursor: pointer;
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
}

.rarity-modal-container {
  cursor: pointer;
  z-index: 999;
  position: absolute;
  height: auto;
  width: 80%;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  border: 0calc (var(--base-font-size-item-page) * 7 / 20) solid #2fb484;
  background: #fff;
  box-shadow: calc(var(--base-font-size-item-page) * 1 / 32)
    calc(var(--base-font-size-item-page) * 1 / 32)
    calc(var(--base-font-size-item-page) * 10 / 32)
    calc(var(--base-font-size-item-page) * 10 / 32) rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1%;
  margin-top: 2%;
  left: 15%;
  overflow-y: scroll;
}

.token-rarity-modal-container {
  cursor: pointer;
  z-index: 999999999999999999999999999999;
  position: absolute;
  height: auto;
  width: 25em;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  border: 0calc (var(--base-font-size-item-page) * 7 / 20) solid #2fb484;
  background: #fff;
  box-shadow: calc(var(--base-font-size-item-page) * 1 / 32)
    calc(var(--base-font-size-item-page) * 1 / 32)
    calc(var(--base-font-size-item-page) * 10 / 32)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 1%;
  margin-top: -6%;
  overflow-y: auto;
}

.rarity-modal-formula-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: calc(var(--base-font-size-item-page) * 1);
}

/* .trait-value-container-rarity-modal {
  background-color: blue;
  width: calc(var(--base-font-size-item-page) * 6);
  font-size: calc(var(--base-font-size-item-page) * 0.4);
} */

.trait-value-container-rarity-modal {
  display: flex;
  align-items: center;
  padding-left: calc(var(--base-font-size-item-page) * 0.8);
  padding-right: calc(var(--base-font-size-item-page) * 1);
  justify-content: center;
  gap: calc(var(--base-font-size-item-page) * 10 / 32);
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  overflow-x: hidden;
  margin-top: calc(var(--base-font-size-item-page) * 0.3);
}

.trait-value-rarity-modal {
  display: flex;
  color: #183b56;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2%;
  background-color: #ecf0f9;
  padding: calc(var(--base-font-size-item-page) * 0.1);
  border-radius: calc(var(--base-font-size-item-page) * 12 / 32);
  margin-top: calc(var(--base-font-size-item-page) * (4 / 32));
  width: calc(var(--base-font-size-item-page) * 8);
  height: calc(var(--base-font-size-item-page) * 2);
  font-size: calc(var(--base-font-size-item-page) * 0.4);
  font-weight: 550;
}
.trait-value-rarity-modal-values {
  font-size: calc(var(--base-font-size-item-page) * 0.4);
  font-weight: 650;
}

.total-rarity-value-rarity-modal {
  background-color: #c6eade;
  padding: calc(var(--base-font-size-item-page) * 0.6);
  border-radius: calc(var(--base-font-size-item-page) * 12 / 32);
  color: #2fb484;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-top: calc(var(--base-font-size-item-page) * (10 / 32));
}

.item-image-modal-container {
  cursor: pointer;
  z-index: 999;
  position: absolute;
  top: 0px;
  height: auto;
  width: 15em;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  border: 0calc (var(--base-font-size-item-page) * 7 / 20) solid #2fb484;
  background: #fff;
  box-shadow: calc(var(--base-font-size-item-page) * 1 / 20)
    calc(var(--base-font-size-item-page) * 1 / 20)
    calc(var(--base-font-size-item-page) * 10 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(47, 180, 132, 0.25);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: calc(var(--base-font-size-item-page) * 10 / 20);
  margin-top: calc(var(--base-font-size-item-page) * 10 / 20);
}
.rarity-modal-text {
  font-size: calc(var(--base-font-size-item-page) * 0.4);
  font-weight: bold;
  line-height: calc(var(--base-font-size-item-page) * 0.6);
}
.separator {
  width: 100%;
  height: 0.07rem;
  background-color: #d9d9d9;
  margin-top: calc(var(--base-font-size-item-page) * (4 / 32));
  margin-bottom: calc(var(--base-font-size-item-page) * (4 / 32));
}

.token-info-token-name {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.95);
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  /* margin-bottom: 0.6vw; */
}

.token-info-icons {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  cursor: pointer;
  justify-content: start;
}
.token-info-openseaicon img {
  width: calc(var(--base-font-size-item-page) * 0.72);
  transform: translateY(calc(var(--base-font-size-item-page) * -0.05));
}

.owner-name {
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
}

.owner-name:hover {
  color: #2fb484;
}

.token-info-owner-creator-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: calc(var(--base-font-size-item-page) * (14 / 32));
  gap: calc(var(--base-font-size-item-page) * 0.5);
  font-size: calc(var(--base-font-size-item-page) * 0.45);
  font-weight: 600;
  text-wrap: wrap;
  word-wrap: break-word;
  flex-wrap: wrap;
}

.token-info-owner-creator-container-mobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: calc(var(--base-font-size-item-page) * 0.45);
  font-weight: 600;
}

.user-creator-icons {
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-item-page) * 0.2);
}

.owner-creator-section {
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-item-page) * 0.3);
  align-items: center;
}

.owner-creator-row {
  /* margin-top: 0.5rem; */
  color: #183b56;
  font-size: inherit;
}

.erc1155-modal-container {
  display: flex;
  flex-direction: row;
  margin-bottom: calc(var(--base-font-size-item-page) * 0.5);
  justify-content: space-between;
  align-items: center;
}

.owner-name-erc1155 {
  color: #2fb484;
  margin-left: calc(var(--base-font-size-item-page) * 0.4);
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
}
/* traits component css*/

.traits {
  width: 100%;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.trait {
  background-color: #ecf0f9d2;
  flex: 0 0 calc(34.33% - calc(var(--base-font-size-item-page) * 10 / 20));
  border-radius: 0.625rem;
  padding: 1vw;
  text-align: center;
  margin: 0.35rem;
  box-sizing: border-box;
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 4 / 20)
    calc(var(--base-font-size-item-page) * 10 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  height: auto;
  max-height: calc(var(--base-font-size-item-page) * 6);
}

.trait-value-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: calc(var(--base-font-size-item-page) * 0.2);
  z-index: 0;
  font-size: var(--base-font-size-item-page);
}

.trait-type {
  font-weight: bold;
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.6);
  line-height: calc(var(--base-font-size-item-page) * 0.7);
}

.trait-value {
  font-weight: bold;
  color: #2fb484;
  height: auto;
  overflow: hidden;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
}

.trait-floor-value {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.4);
  margin-top: calc(var(--base-font-size-item-page) * 5 / 32);
}

/* item page provenance section css*/
.item_page_provenance_section {
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 0.625rem;
}

.item_page_provenance_main_section {
  width: 100%;
  z-index: 1;
  display: flex;

  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  padding: 0.625rem;

  gap: 0.7rem;
}

.item_page_provenance_container {
  box-sizing: border-box;
  background-color: #ecf0f9;
  display: flex;
  flex: 1 1 calc(31.33% - calc(var(--base-font-size-item-page) * 10 / 20));
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  padding: 0.9rem;
  text-align: center;

  box-sizing: border-box;
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 4 / 20)
    calc(var(--base-font-size-item-page) * 10 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.1);
}

.item_page_provenance_view_detail_row {
  width: 100%;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  align-items: center;
  /* padding: 0.625rem; */

  color: #2fb484;
  font-size: calc(var(--base-font-size-item-page) * 0.65);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;

  margin-top: 0.7em;
}

/* user modal css*/
.user-modal-container {
  z-index: 999;
  position: absolute;
  height: auto;
  width: auto;
  /* min-width: calc(var(--base-font-size-item-page) * 10); */
  border-radius: 0.5rem;
  word-wrap: break-word;
  border: 0calc (var(--base-font-size-item-page) * 7 / 20) solid #2fb484;
  background: #fff;
  box-shadow: calc(var(--base-font-size-item-page) * 1 / 20)
    calc(var(--base-font-size-item-page) * 1 / 20)
    calc(var(--base-font-size-item-page) * 10 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.25);
  display: flex;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 2rem;
  padding-left: 2em;
  flex-direction: row;
  gap: 1.5vw;
}
.user-modal-pointer-cursor {
  cursor: pointer;
}
.user-details {
  justify-content: center;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  /* margin-right: 0.625rem;
  margin-top: 0.5rem; */
  gap: 0.375rem;
}

.user-profilepic {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-detail-icons {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: flex-end;
  flex-direction: row;
  text-align: center;
  margin-top: calc(var(--base-font-size-item-page) * 5 / 32);
  gap: calc(var(--base-font-size-item-page) * 20 / 32);
  margin-bottom: calc(var(--base-font-size-item-page) * 10 / 32);
}

.username-modal {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.user-address-modal {
  color: #747474;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 550;
  line-height: normal;
}

/* Close button */
.close-button {
  display: flex;
  gap: calc(var(--base-font-size-item-page) * 10 / 20);
  border: none;
  background-color: white;
  border-radius: calc(var(--base-font-size-item-page) * 4 / 20);
  padding-bottom: calc(var(--base-font-size-item-page) * 5 / 20);
  cursor: pointer;
  margin-right: calc(var(--base-font-size-item-page) * 0.4);
  font-size: calc(var(--base-font-size-item-page) * 0.7);
}

.close-button:hover {
  color: black;

  font-weight: bold;
}

/*Item Image CSS*/
.item-image-details-container {
  position: relative;
  width: var(--item_page_left_comp_width);
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-item-page) * 0.7);
  background-color: white;
  border-color: transparent;
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 4 / 20)
    calc(var(--base-font-size-item-page) * 15 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.15);
  /* padding-left: 3vw; */
}

.item-image-container {
  width: 100%;
  height: var(--item_page_left_comp_width);
  position: relative;
  top: 0;
  left: 0;
  border-radius: calc(var(--base-font-size-item-page) * 0.7);
  background: #ecf0f9;
}

.item-image {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  border-radius: calc(var(--base-font-size-item-page) * 0.7);
  background: #ecf0f9;
  object-fit: cover;
}
.item-image-mobile {
  width: 10%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  border-bottom-left-radius: calc(var(--base-font-size-item-page) * 0.7);
  border-bottom-right-radius: calc(var(--base-font-size-item-page) * 0.7);
  background: #ecf0f9;
  object-fit: cover;
}

/*Item-Image Buttons CSS*/
.item-page-image-button-placeholder {
  position: absolute;
  top: 3%;
  right: 7%;
  width: 5.95vw;
  height: 2.083333333vw;
  background-size: cover;
  background-repeat: no-repeat;

  /* background-position: center; */
}

/* Button image */
.button-image {
  display: flex;
  justify-content: space-between;
  width: 120%;
  height: 100%;
  border-radius: calc(var(--base-font-size-item-page) * 4 / 20);
  background-color: #ecf0f9;
  padding: calc(var(--base-font-size-item-page) * 0.5);
  align-items: center;
  cursor: pointer;
}
.button-image-mobile {
  display: flex;
  justify-content: center;
  width: auto;
  height: calc(var(--base-font-size-item-page) * 1.2);
  border-radius: calc(var(--base-font-size-item-page) * 4 / 20);
  background-color: #ecf0f9;
  padding: calc(var(--base-font-size-item-page) * 0.5);
  align-items: center;
  cursor: pointer;
  gap: calc(var(--base-font-size-item-page) * 0.25);
}

/* Enlarge button */
.item-image-enlarge-button-container {
  width: 100%;
  border-radius: calc(var(--base-font-size-item-page) * 4 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 4 / 20);
}
.enlarge-button {
  display: inline-flex;
  padding: 3%;
  align-items: flex-start;
  gap: 1.25rem;
  box-shadow: calc(var(--base-font-size-item-page) * 1 / 20)
    calc(var(--base-font-size-item-page) * 1 / 20)
    calc(var(--base-font-size-item-page) * 8 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.1);
  border-radius: calc(var(--base-font-size-item-page) * 4 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 4 / 20);
  width: 100%;
}

.line-between-elements {
  width: 0.063rem;
  height: 1.3rem;
  background: #d9d9d9;
}

/*Item-Image Bar at the bottom - CSS*/

.image-bar {
  position: relative;
  width: 100%;
  height: 5.625rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  border-radius: calc(var(--base-font-size-item-page) * 24 / 20);
}

.item-page-image-bot-box-container {
  display: flex;
  align-items: center;
  margin-left: 2%;
  margin-top: 0.7%;
  margin-bottom: 0.7%;
  margin-right: 3.5%;
  justify-content: center;
}

.item-image-bar-at-the-bottom-container-input {
  display: flex;
  align-items: center;
  padding: 1.5%;
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.4);
  font-weight: 600;
}

.item-page-image-bot-box-container-input-for-like {
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1%;
  padding-right: 8%;
  margin-left: 50%;
}
.text-box-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.625rem;
}
.text-box-1 {
  padding: 2%;
  position: relative;
  bottom: 0;
}

.text-box-2 {
  text-align: right;
  position: relative;
  bottom: 0;
}

.text-box-1:first-child {
  color: #183b56;
  font-family: Inter;
  font-size: calc(var(--base-font-size-item-page) * 1);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-box-2:first-child {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 1);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/*More Options Menu-CSS*/
/* MoreOptionsMenu.css */
.more-options-menu-container {
  position: relative;
  display: inline-block;
  width: calc(var(--base-font-size-item-page) * 6);
  height: auto;
  border-radius: 8%;
  border: 0calc (var(--base-font-size-item-page) * 75 / 20) solid #2fb484;
  background: white;
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 2 / 20)
    calc(var(--base-font-size-item-page) * 15 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.25);

  margin-left: -5rem;
  margin-top: 10%;
}

.more-options-menu-inside-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  right: 9rem;
  top: 7rem;
}

.more-options-icon-and-text-container {
  display: flex;
  padding-bottom: 10%;
  padding-left: 8%;
  justify-content: left;
  align-items: center;
  text-align: left;
  gap: 1.5rem;
}

.more-options-text-layout {
  padding-top: 8%;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.4);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.more-options-text-layout:hover {
  color: #2fb484;
}
.more-options-line-between-elements {
  width: 80%;
  height: 0.07rem;
  background-color: #d9d9d9;
  justify-content: center;
  padding-inline: 3%;
  margin-left: 8%;
}

.more-options-etherscan-icon {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-item-page) * 16 / 20);
}

.more-options-original-icon-layout {
  width: 0.8rem;
  height: 0.8rem;
  flex-shrink: 0;
}

.more-options-etherscan-icon-layout {
  width: 0.8rem;
  height: 0.8rem;
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-item-page) * 16 / 20);
}

.more-options-share-icon-layout {
  padding-right: 2%;
  width: 0.8rem;
  height: 0.8rem;
  flex-shrink: 0;
}

.more-options-copy-link-icon-layout {
  width: 0.8rem;
  height: 0.8rem;
  flex-shrink: 0;
}

.menu {
  /* display: none; */
  position: absolute;
  cursor: pointer;
}

/*...........mobile...........*/

.item-page-layout-mobile {
  height: calc(var(--base-font-size-item-page) * 1000 / 20);
  width: 100%;
  height: auto;
}
.item-page-container-left-column-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.item-page-container-right-column-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.token-info-container-mobile {
  border-radius: calc(var(--base-font-size-item-page) * 16 / 20);
  background: #fff;
  border-bottom: none;
  border-bottom-left-radius: calc(var(--base-font-size-item-page) * 0 / 20);
  border-bottom-right-radius: calc(var(--base-font-size-item-page) * 0 / 20);
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 2 / 20)
    calc(var(--base-font-size-item-page) * 15 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.15);
  width: 90%;
  padding: calc(var(--base-font-size-item-page) * 20 / 20);
  margin-top: calc(var(--base-font-size-item-page) * 4);
  position: relative;
}

.item-image-details-container-mobile {
  position: relative;
  width: 90%;
  height: width;
  flex-shrink: 0;

  border-radius: calc(var(--base-font-size-item-page) * 0.7);
  background-color: white;
  border-color: transparent;
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 5 / 20) -calc(
      var(--base-font-size-item-page) * 1 / 20
    ) rgba(116, 112, 112, 0.5);
  margin-top: -calc(var(--base-font-size-item-page) * 20 / 20);
  z-index: 2;
}

.item-image-container-mobile {
  width: 100%;
  height: 85vw;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 1.5rem;
  background: #ecf0f9;
}

.item-page-image-button-placeholder-mobile {
  position: absolute;
  align-items: center;
  top: 3%;
  right: 7%;
  width: 10vw;
  height: 5vw;
  background-size: cover;
  background-repeat: no-repeat;
}

#item-page-content-box-mobile {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 90%;
  overflow: hidden;
  height: auto;
  border-radius: calc(var(--base-font-size-item-page) * 0.4);
  background: rgba(255, 255, 255, 0.25);
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * (2 / 20))
    calc(var(--base-font-size-item-page) * (15 / 20))
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(calc(var(--base-font-size-item-page) * (10.5 / 20)));

  padding: 1% 5% 1% 5%;

  font-size: calc(var(--base-font-size-item-page) * 0.5);

  margin-top: calc(var(--base-font-size-item-page) * 0.4);
  margin-bottom: calc(var(--base-font-size-item-page) * 0.4);
}

.listing-price-edit-button-mobile {
  background-color: transparent;
  border: none;
}

.info-container-mobile {
  width: 90%;
}

.info-container-header-seprator {
}

/* collection tab */

.collection-tab-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: calc(var(--base-font-size-item-page) * (24 / 32));
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.75);
  color: #183b56;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: -6%;
}

.collection-tab-container {
  width: 100%;
  max-width: 100%;
  margin-bottom: calc(var(--base-font-size-item-page) * 50 / 20);
  margin-top: calc(var(--base-font-size-item-page) * (24 / 32));
}

.collection-tab {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;

  position: relative;
}

.recommendationsFromCollection-token-box {
  width: calc(var(--base-font-size-item-page) * 10);
  flex-shrink: 0;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 2 / 20)
    calc(var(--base-font-size-item-page) * 15 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10calc (var(--base-font-size-item-page) * 5 / 20));
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: center;
  border: calc(var(--base-font-size-item-page) * 0 / 20);
  padding: calc(var(--base-font-size-item-page) * 0 / 20);
  margin: calc(var(--base-font-size-item-page) * 0.3);
  border-radius: calc(var(--base-font-size-item-page) * 0.7);
}

.recommendationsFromCollection-token-box:hover {
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 2 / 20)
    calc(var(--base-font-size-item-page) * 15 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20) #2fb484;
}

@keyframes recommendations-from-collection-token-box {
  0% {
    background-color: #fff;
  }
  100% {
    box-shadow: 0 0 calc(var(--base-font-size-item-page) * 15 / 20) 70em
      rgba(000, 000, 000, 0.324);
    background-color: #53e2ae;
    z-index: 2;
  }
}

.recommendationsFromCollection-token-info-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  /* height: calc(var(--base-font-size-item-page) * 15); */
}

.recommendationsFromCollection-token-image-container {
  height: calc(var(--base-font-size-item-page) * 230 / 32);
  width: 100%;
}
.recommendationsFromCollection-collection-name {
  color: #2fb484;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  text-align: left;
  font-weight: 500;
  line-height: normal;
  padding-top: calc(var(--base-font-size-item-page) * 0.7);
  padding-left: calc(var(--base-font-size-item-page) * 0.4);
}

.recommendationsFromCollection-token-name {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.7);
  font-style: normal;
  font-weight: 700;
  text-align: left;
  width: 95%;
  overflow: hidden;
  line-height: normal;
  padding-top: calc(var(--base-font-size-item-page) * 0.7);
  padding-left: calc(var(--base-font-size-item-page) * 0.4);
}
.recommendationsFromCollection-token-name.no-rank {
  padding-bottom: calc(var(--base-font-size-item-page) * 0.8);
}

.recommendationsFromCollection-token-ranking-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  padding-top: calc(var(--base-font-size-item-page) * 0.4);
  padding-left: calc(var(--base-font-size-item-page) * 0.4);
  padding-bottom: calc(var(--base-font-size-item-page) * 0.8);
}

.recommendationsFromCollection-token-ranking {
  padding: calc(var(--base-font-size-item-page) * 3 / 20)
    calc(var(--base-font-size-item-page) * 7 / 20)
    calc(var(--base-font-size-item-page) * 3 / 20)
    calc(var(--base-font-size-item-page) * 7 / 20);
  width: calc(var(--base-font-size-item-page) * 45 / 20);
  height: calc(var(--base-font-size-item-page) * 22 / 20);
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-item-page) * 5 / 20);
  background: #2fb484;
  color: #fff;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-left: calc(var(--base-font-size-item-page) * 5 / 20);
}
.recommendationsFromCollection-total-tokens {
  margin-left: calc(var(--base-font-size-item-page) * 3 / 20);
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.recommendationsFromCollection-token-image {
  border-radius: calc(var(--base-font-size-item-page) * 12 / 20);
  border-bottom-left-radius: calc(var(--base-font-size-item-page) * 0 / 20);
  border-bottom-right-radius: calc(var(--base-font-size-item-page) * 0 / 20);
  object-fit: cover;
  width: 100%;
  height: 100% !important;
}

.scroll-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(var(--base-font-size-item-page) * 30 / 20);
  margin-left: -10%;
}
.scroll-button {
  /* width: calc(
    var(--base-font-size-item-page) * 35 / 20
  ); Set the width and height to make a circle */
  /* height: calc(var(--base-font-size-item-page) * 35 / 20);
  border: calc(var(--base-font-size-item-page) * 1 / 20) solid red;
  border-radius: 50%; Use 50% to make it a complete circle */
  /* background-color: #2fb484;
  color: white;
  border: none;
  cursor: pointer;
  font-size: calc(var(--base-font-size-item-page) * 1);
  margin-bottom: calc(var(--base-font-size-item-page) * 100 / 20);
  border: 2px solid red */
}
/* .scroll-button:hover {
  border: calc(var(--base-font-size-item-page) * 1 / 20) solid #2fb484;
  background-color: white;
  color: #2fb484;
} */

/* .scroll-button.left {
  margin-right: calc(var(--base-font-size-item-page) * 10 / 20);
  cursor: pointer;
}

.scroll-button.right {
  margin-left: calc(var(--base-font-size-item-page) * 10 / 20);
  cursor: pointer;
} */

/* Info Tabs Toggle Modal*/

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-component {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  border-radius: calc(var(--base-font-size-item-page) * 8 / 20);
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 10 / 20) rgba(0, 0, 0, 0.2);
  text-align: center;
  min-width: calc(var(--base-font-size-item-page) * 350 / 20);
  min-height: auto;
  max-height: 70%;
  max-width: 80%;
  margin-top: 10%;

  overflow: scroll;

  /* padding: calc(var(--base-font-size-item-page) * 20 / 20) calc(var(--base-font-size-item-page) * 30 / 20); */
  padding: calc(var(--base-font-size-item-page) * 1)
    calc(var(--base-font-size-item-page) * 1);
}

.modal-component-image-enlarge {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: white;
  min-width: 90%;
  min-height: 80%;
  padding: calc(var(--base-font-size-item-page) * 20 / 20)
    calc(var(--base-font-size-item-page) * 30 / 20);
  border-radius: calc(var(--base-font-size-item-page) * 8 / 20);
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 10 / 20) rgba(0, 0, 0, 0.2);
  text-align: center;
  max-height: 80%;
  max-width: 80%;
  overflow: scroll;
  margin-top: calc(var(--base-font-size-item-page) * 2);
}
.modal-component-image-enlarge-mobile {
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  background: white;
  min-width: 95%;
  min-height: auto;
  padding: calc(var(--base-font-size-item-page) * 20 / 20)
    calc(var(--base-font-size-item-page) * 30 / 20);
  border-radius: calc(var(--base-font-size-item-page) * 8 / 20);
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 10 / 20) rgba(0, 0, 0, 0.2);
  text-align: center;
  max-height: auto;
  max-width: 80%;
  overflow: scroll;
  margin-top: calc(var(--base-font-size-item-page) * 2);
}

.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: calc(var(--base-font-size-item-page) * 2);
  border-bottom: calc(var(--base-font-size-item-page) * 1 / 20) solid #d9d9d9;

  padding-bottom: 0.9em;
  margin-bottom: calc(var(--base-font-size-item-page) * 0.5);
}

.modal-header-title {
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-weight: 700;
  color: #183b56;
}

.modal-header-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: calc(var(--base-font-size-item-page) * 10 / 20);
}

.tab-toggle-modal h2 {
  margin-top: 0;
}

/* Styles for the modal content */

.each-modal-listing {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.5rem;
  gap: 1rem;
  padding-top: 2rem;
}

/* Styles for the selected listing */
.listing-item.selected {
  display: flex;
  justify-content: space-between;
  padding: calc(var(--base-font-size-item-page) * 15 / 20)
    calc(var(--base-font-size-item-page) * 12 / 20);
  /* Change to your preferred selected background color */
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: bold;
  line-height: normal; /* Change to your preferred selected text color */
  cursor: pointer;
  border-radius: calc(var(--base-font-size-item-page) * 16 / 20);
  border: calc(var(--base-font-size-item-page) * 1 / 20) solid #2fb484;
  background: rgba(47, 180, 132, 0.15);
}

/* Styles for the unselected listing */
.listing-item.not-selected {
  display: flex;
  justify-content: space-between;
  padding: calc(var(--base-font-size-item-page) * 15 / 20)
    calc(var(--base-font-size-item-page) * 12 / 20);
  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  background-color: white; /* Change to your preferred unselected background color */
  color: #333; /* Change to your preferred unselected text color */
  cursor: pointer;
  border-radius: calc(var(--base-font-size-item-page) * 16 / 20);
  border: calc(var(--base-font-size-item-page) * 1 / 20) solid #2fb484;
}

.modal-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: calc(var(--base-font-size-item-page) * 0.5);

  line-height: 1em;

  padding-left: 0.5em;
  padding-right: 1em;
  padding-bottom: 0.5em;

  margin-bottom: 1em;
}

.modal-row-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: #183b56;
  font-size: calc(var(--base-font-size-item-page) * 0.5);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.tab-toggle-modal button {
  background-color: #007bff;
  color: black;
  border: none;
  padding: calc(var(--base-font-size-item-page) * 10 / 20)
    calc(var(--base-font-size-item-page) * 20 / 20);
  border-radius: calc(var(--base-font-size-item-page) * 4 / 20);
  cursor: pointer;
}

.modal button:hover {
  background-color: #0056b3;
}

.pill-toggle {
  display: none;
}

.pill-label {
  display: inline-block;
  width: calc(var(--base-font-size-item-page) * 40 / 20);
  height: calc(var(--base-font-size-item-page) * 15 / 20);
  background-color: #ccc;
  border-radius: calc(var(--base-font-size-item-page) * 25 / 20);
  position: relative;
  cursor: pointer;
}

.pill-toggle:checked + .pill-label {
  background-color: #2fb484;
}

.pill-label::before {
  content: "";
  position: absolute;
  width: calc(var(--base-font-size-item-page) * 13 / 20);
  height: calc(var(--base-font-size-item-page) * 13 / 20);
  background-color: #fff;
  border-radius: 50%;
  top: 50%;
  margin-left: calc(var(--base-font-size-item-page) * 2 / 20);
  margin-right: calc(var(--base-font-size-item-page) * 0 / 20);
  left: 0;
  transform: translate(0, -50%);
  transition: 0.4s;
}

.pill-toggle:checked + .pill-label::before {
  margin-left: calc(var(--base-font-size-item-page) * 4 / 20);
  left: calc(var(--base-font-size-item-page) * 21 / 20);
}

/*Sales Chart css */
.tooltip {
  position: absolute;
  background: #fff;
  border: calc(var(--base-font-size-item-page) * 1 / 20) solid #ccc;
  border-radius: calc(var(--base-font-size-item-page) * 8 / 20);
  padding: calc(var(--base-font-size-item-page) * 10 / 20);
  display: none;
  font-weight: bold;
  line-height: 1.5;
  z-index: 999;
}

.tooltip p {
  margin: calc(var(--base-font-size-item-page) * 10 / 20) 0;
}

/*alert popup css*/
.alert-container {
  position: fixed;
  top: 10%;
  left: 40%;
  width: 25%;
  box-shadow: calc(var(--base-font-size-item-page) * 0 / 20)
    calc(var(--base-font-size-item-page) * 2 / 32)
    calc(var(--base-font-size-item-page) * 15 / 32)
    calc(var(--base-font-size-item-page) * 0 / 20) rgba(0, 0, 0, 0.15);
  /* border-radius: calc(var(--base-font-size-item-page) * 12 / 32); */
  font-size: calc(var(--base-font-size-item-page) * 0.4);
  font-weight: bold;
  text-align: center;
  padding: calc(var(--base-font-size-item-page) * 10 / 32);
  transition: top 0.5s ease-in-out;
  z-index: 2147483647;
  border-radius: calc(var(--base-font-size-item-page) * 8 / 20);
  opacity: 0;
}

.alert-container.visible {
  opacity: 1;
}

/* media queries */

/* fonts */

@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  :root {
    --base-font-size-item-page: 5vw;
  }
}

/*  scrollbar  */

.item-transfer-history-table-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 3 / 32);
}

.item-transfer-history-table-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.item-transfer-history-table-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: calc(var(--base-font-size-item-page) * 6 / 32);
}

.item-transfer-history-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.item-transfer-history-table-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 6 / 32);
}

/* collection tab scrollbar*/

.collection-tab::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 3 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.collection-tab::-webkit-scrollbar-track {
  background-color: transparent;
}

.collection-tab::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: calc(var(--base-font-size-item-page) * 6 / 32);
}

.collection-tab::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.collection-tab::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 3 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.modal-component-image-enlarge::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 3 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.modal-component-image-enlarge::-webkit-scrollbar-track {
  background-color: transparent;
}

.modal-component-image-enlarge::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: calc(var(--base-font-size-item-page) * 6 / 32);
}

.modal-component-image-enlarge::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.modal-component-image-enlarge::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.item-metadata-data-container-box::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.item-metadata-data-container-box::-webkit-scrollbar-track {
  background-color: transparent;
}

.item-metadata-data-container-box::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: calc(var(--base-font-size-item-page) * 6 / 32);
}

.item-metadata-data-container-box::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.item-metadata-data-container-box::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.modal-component::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.modal-component::-webkit-scrollbar-track {
  background-color: transparent;
}

.modal-component::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: calc(var(--base-font-size-item-page) * 6 / 32);
}

.modal-component::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.modal-component::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.link-field {
  color: #2081e2 !important;
  font-weight: 700 !important;
  /* text-decoration: underline; */
  cursor: pointer !important;
  /* border-bottom: 2px solid #2fb484; */
}
.link-field:hover {
  color: #1d63b0 !important;
  font-weight: 700 !important;

  /* text-decoration: underline; */
}

.item-page-owner-modal-mobile {
  /* border: 2px solid red; */
  position: absolute;
  white-space: nowrap;
  transform: translateX(-18vw);
  transition: transform 0.3s ease;
  z-index: 9;
  /* width: 100vw; */
}
