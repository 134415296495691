/* loading spinner */

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 3vw;
  height: 3vw;
  border: calc(var(--base-font-size-folders-page) * (3 / 32)) solid #f3f3f3; /* Light grey */
  border-top: calc(var(--base-font-size-folders-page) * (3 / 32)) solid #2fb484;
  border-radius: 50%;
  animation: spinner 0.7s linear infinite;
}

/*  fetching spinner */

.fetching-spinner-row {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  line-height: normal;
  width: 100%;
  align-self: center;
  gap: 1.7em;
  font-size: calc(var(--base-font-size-folders-page) * (14 / 32));
  font-weight: 700;
}

.dot-elastic {
  position: relative;
  width: calc(var(--base-font-size-folders-page) * (6 / 32));
  height: calc(var(--base-font-size-folders-page) * (6 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: #2fb484;
  color: #2fb484;
  animation: dot-elastic 1s infinite linear;
  margin-bottom: calc(var(--base-font-size-folders-page) * (7 / 32));
}
.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic::before {
  left: calc(var(--base-font-size-folders-page) * (-15 / 32));
  width: calc(var(--base-font-size-folders-page) * (6 / 32));
  height: calc(var(--base-font-size-folders-page) * (6 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: #2fb484;
  color: #2fb484;
  animation: dot-elastic-before 1s infinite linear;
}
.dot-elastic::after {
  left: calc(var(--base-font-size-folders-page) * (15 / 32));
  width: calc(var(--base-font-size-folders-page) * (6 / 32));
  height: calc(var(--base-font-size-folders-page) * (6 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: #2fb484;
  color: #2fb484;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
