.token-list-dropdown-container {
  font-size: calc(var(--base-font-size-collection-page) * (1 / 2));
  position: absolute;
  top: calc(100% + 10px);
  width: calc(var(--base-font-size-collection-page) * (400 / 32));
  border: 0.5px solid #ccc;
  border-radius: 0.7vw;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: block;
  overflow-y: auto;
  max-height: 70vh;
  background-color: #ecf0f9;
  padding: calc(var(--base-font-size-collection-page) * (15 / 32));
  z-index: 1;
}

.token-list-dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-wrap: break-word;
  margin-bottom: calc(var(--base-font-size-collection-page) * (10 / 32));
  height: calc(var(--base-font-size-collection-page) * (43 / 32));
  background-color: #2fb484;
  cursor: pointer;
  color: white;
  padding: 20px;
  border: none;
  font-size: calc(var(--base-font-size-collection-page) * (15 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * 0.2);
  width: 100%;
}

.token-list-dropdown-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.token-list-dropdown-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.token-list-dropdown-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: calc(var(--base-font-size-item-page) * 6 / 32);
}

.token-list-dropdown-container::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.token-list-dropdown-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}
