:root {
  --base-font-size-signup-page: 2.4vw;
}

.claim-wallet-page {
}

.signup-page {
}

.claim-wallet-page-mobile {
  padding-top: 15vw;

  justify-content: center;
  align-items: center;
}

.signup-page-mobile {
  margin-top: 15vw;

  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-sign-in-animation {
  color: white;
  font-size: calc(var(--base-font-size-signup-page) * 0.75);
  background-color: #a6a3a3;
  transition: background-color 0.4s;
}

.login-page-sign-in-animation:hover {
  background-color: #2fb484;
  color: white;
}

.login-page-input-animation {
  animation: login-page-input-animation 0.8s cubic-bezier(0.29, 0.33, 0.25, 1);
}

.signup-page-input-animation {
  animation: login-page-input-animation 0.8s cubic-bezier(0.29, 0.33, 0.25, 1);
}

@keyframes login-page-input-animation {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

/* .login-page-input-animation-mobile {
  animation: login-page-input-animation-mobile 0.8s
    cubic-bezier(0.29, 0.33, 0.25, 1);
} */

/* @keyframes login-page-input-animation-mobile {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
} */

.signup-page-input-animation-mobile {
  animation: signup-page-input-animation-mobile 0.8s
    cubic-bezier(0.29, 0.33, 0.25, 1);
}

@keyframes signup-page-input-animation-mobile {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

/* .login-page-welcome-animation {
  animation: login-page-welcome-animation 0.8s cubic-bezier(0.29, 0.33, 0.25, 1);
}

@keyframes login-page-welcome-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
} */

.signup-page-welcome-animation {
  animation: signup-page-welcome-animation 0.8s
    cubic-bezier(0.29, 0.33, 0.25, 1);
}

@keyframes signup-page-welcome-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.modal-overlay-otp-section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black color with 50% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-x: hidden;
  cursor: pointer;
}
.login-otp-modal-container {
  background: white;
  font-size: calc(var(--base-font-size-signup-page) * 0.5);
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 60vh;
  width: 35%;
  justify-content: center;
  align-self: center;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: calc(var(--base-font-size-signup-page) * 0.5)
    calc(var(--base-font-size-signup-page) * 1);
  cursor: default;
  text-align: center;
}

.login-verify-button {
  width: calc(var(--base-font-size-signup-page) * (101 / 32));
  height: calc(var(--base-font-size-signup-page) * (43 / 32));
  color: #2fb484;
  border: 0;
  border-radius: calc(var(--base-font-size-signup-page) * 1);
  background-color: #c6eade;
  font-weight: bold;
  margin-top: calc(var(--base-font-size-signup-page) * 1);
  margin-left: calc(var(--base-font-size-signup-page) * 0.3);
  font-size: calc(var(--base-font-size-signup-page) * 0.5);
  transition: all 0.4s;
}

.login-cancel-button {
  width: calc(var(--base-font-size-signup-page) * (101 / 32));
  height: calc(var(--base-font-size-signup-page) * (43 / 32));
  color: #747474;
  border: 0;
  border-radius: calc(var(--base-font-size-signup-page) * 1);
  background-color: #ecf0f9;
  font-weight: bold;
  margin-top: calc(var(--base-font-size-signup-page) * 1);
  margin-left: calc(var(--base-font-size-signup-page) * 0.3);
  font-size: calc(var(--base-font-size-signup-page) * 0.5);
  transition: all 0.4s;
}

.login-verify-button:hover {
  width: calc(var(--base-font-size-signup-page) * (101 / 32));
  height: calc(var(--base-font-size-signup-page) * (43 / 32));
  color: white;
  border: 0;
  border-radius: calc(var(--base-font-size-signup-page) * 1);
  background-color: #2fb484;
  box-shadow: rgba(0, 0, 0, 0.25) 0 4px 12px;
  font-weight: bold;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin-top: calc(var(--base-font-size-signup-page) * 1);
  margin-left: calc(var(--base-font-size-signup-page) * 0.3);
  transition: all 0.4s;
}

.login-cancel-button:hover {
  width: calc(var(--base-font-size-signup-page) * (101 / 32));
  height: calc(var(--base-font-size-signup-page) * (43 / 32));
  color: white;
  border: 0;
  border-radius: calc(var(--base-font-size-signup-page) * 1);
  background-color: #747474;
  box-shadow: rgba(0, 0, 0, 0.15) 0 4px 15px;
  font-weight: bold;
  box-shadow: 2px 2px 5px rgba(116, 116, 116, 0.5);
  margin-top: calc(var(--base-font-size-signup-page) * 1);
  margin-left: calc(var(--base-font-size-signup-page) * 0.3);
  transition: all 0.4s;
}

.login-sub-text {
  color: #747474;
  font-size: calc(var(--base-font-size-signup-page) * 0.45);
  justify-content: center;
}

.login-otp-input {
  height: calc(var(--base-font-size-signup-page) * (50 / 32));
  color: #2fb484;
  margin-top: calc(var(--base-font-size-signup-page) * 0.3);
  text-align: center;
  border-radius: 0.8vw;
  border: 1px solid rgb(200, 200, 200);
  font-size: calc(var(--base-font-size-signup-page) * 0.9);
  font-weight: bold;
  background-color: #f9fafb;
  padding-left: 5%;
  letter-spacing: calc(var(--base-font-size-signup-page) * 0.5);
}

.login-send-otp-button {
  width: calc(var(--base-font-size-signup-page) * (80 / 32));
  height: calc(var(--base-font-size-signup-page) * (35 / 32));
  color: #2fb484;
  border: 1px solid #2fb484;
  border-radius: calc(var(--base-font-size-signup-page) * 20 / 32);
  background-color: white;
  font-weight: bold;
  font-size: calc(var(--base-font-size-signup-page) * 0.45);
  transition: all 0.4s;
}

.login-send-otp-button:hover {
  width: calc(var(--base-font-size-signup-page) * (80 / 32));
  height: calc(var(--base-font-size-signup-page) * (35 / 32));
  color: white;
  box-shadow: 0px 2px 8px rgba(116, 116, 116, 0.5);
  background-color: #2fb484;
  font-weight: bold;
  font-size: calc(var(--base-font-size-signup-page) * 0.45);
}

.google-sign-in-button {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #747474;
  border-radius: calc(var(--base-font-size-signup-page) * 20 / 32);
  background-color: white;
  font-weight: bold;
  transition: all 0.4s;
}
.google-sign-in-button:hover {
  border: 1px solid #2fb484;
}

.greyed-out-input {
  background-color: #f2f2f2; /* Grey shade */
  pointer-events: none; /* Disable pointer events */
}
.resend-timer {
  color: #183b56;
  font-size: calc(var(--base-font-size-signup-page) * (14/32));
  font-weight: 500;
  margin-top: calc(var(--base-font-size-signup-page) * 0.8);
}

.resend-button {
  color: #2fb484;
  font-size: calc(var(--base-font-size-signup-page) * (14/32));
  font-weight: 570;
  margin-top: calc(var(--base-font-size-signup-page) * 0.8);
  border: 0;
  background-color: white;
}

@media (max-width: 1100px) {
  :root {
    --base-font-size-signup-page: 4.8vw;
  }
}

/* --- */

.signup-welcome-textbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 100vh;
  /* background-color: #2fb484; */
  padding-top: 6vw;
  padding-right: 1vw;
  color: #effaf5;
  /* background-color: #2fb484; */
  padding-top: 8vw;
  padding-left: 3vw;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}
.signup-welcome-textbox-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1%;
  /* background-color: #2fb484; */
  /* padding-top: 6vw;
  padding-right: 1vw; */
  /* color: #effaf5; */
  /* background-color: #2fb484; */
  padding-top: 8vw;
  /* padding-left: 3vw; */
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  /* height: 100vh; */
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
