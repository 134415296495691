
/*-------- sorting section css ---------*/

:root {
  --base-font-size-collection-page: 2vw;
}

.ui.search {
  font-size: calc(var(--base-font-size-collection-page) * (14 / 32));
}
.ui.search.selection.dropdown > input.search {
  /* padding: calc(var(--base-font-size-collection-page) * (10 / 32)) !important; */
  font-size: calc(4 / 5 * var(--base-font-size-search) ) !important;
  padding: 0px !important;
}
.ui.dropdown .menu>.item{
  line-height: 2em;
}