.selected-filters {
  display: flex;
  justify-content: center;
  gap: calc(var(--base-font-size-collection-page) * 0.4);
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* margin-bottom: 30px; */
  /* margin-left: 6%; */
}

.selected-filters:not(:empty) {
  padding-bottom: calc(var(--base-font-size-collection-page) * (16 / 32));
}

.items-tab-bigger-items-container-with-filter-section {
  display: grid;
  grid-template-columns: repeat(
    var(--columns-bigger-items-container-with-filter-section),
    1fr
  );
  gap: 30px;
  flex-wrap: wrap;
}

.items-tab-smaller-items-container-with-filter-section {
  display: grid;
  grid-template-columns: repeat(
    var(--columns-smaller-items-container-with-filter-section),
    1fr
  );

  gap: 30px;
  flex-wrap: wrap;
}

.items-tab-bigger-items-container-without-filter-section {
  display: grid;
  grid-template-columns: repeat(
    var(--columns-bigger-items-container-without-filter-section),
    1fr
  );
  gap: 1.8vw;
  flex-wrap: wrap;
}

.items-tab-smaller-items-container-without-filter-section {
  display: grid;
  grid-template-columns: repeat(
    var(--columns-smaller-items-container-without-filter-section),
    1fr
  );

  gap: 30px;
  flex-wrap: wrap;
}

.token-card-container {
  --base-font-size-token-card-page: 2vw;

  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  border-radius: calc(var(--base-font-size-token-card-page) * 0.5);
  background-color: white;
  box-shadow: 0 0 calc(var(--base-font-size-token-card-page) * (7 / 32))
    calc(var(--base-font-size-token-card-page) * (1 / 32)) rgba(0, 0, 0, 0.15);
  /* margin-left: calc(var(--base-font-size-token-card-page) * 0.22); */
  /* margin-right: calc(var(--base-font-size-token-card-page) * 0.22);
  margin-bottom: calc(var(--base-font-size-token-card-page) * 0.42); */
  transition: all 0.5s ease-in-out;
}
.token-card-container:hover {
  box-shadow: 0 0 calc(var(--base-font-size-token-card-page) * (18 / 32))
    calc(var(--base-font-size-token-card-page) * (2 / 32)) #53e2ae; /* Horizontal offset, vertical offset, blur radius, spread radius, and color */
  cursor: pointer;
}
/* fonts */
@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  .token-card-container {
    --base-font-size-token-card-page: 6vw;
  }
}

.token-card-container-bigger-width {
  width: calc(var(--base-font-size-token-card-page) * 9.5);
  height: 30vw !important;
}
.token-card-container-bigger-width-unranked {
  width: calc(var(--base-font-size-token-card-page) * 9.5);
  height: 20vw !important;
}
.token-card-container-smaller-width {
  /* width: calc(var(--base-font-size-token-card-page) * 7.6); */
  width: 100% !important;
  height: 25vw !important;
}
.token-card-container-smaller-width-unranked {
  /* width: calc(var(--base-font-size-token-card-page) * 7.6); */
  width: auto;
  height: 22vw !important;
}

.token-card-container-smaller-width-mobile {
  /* width: calc(var(--base-font-size-token-card-page) * 7.6); */
  width: 94% !important;
  margin-left: 3%;
  margin-top: 2vw;
  height: 60vw !important;
}
.token-card-container-smaller-width-mobile-unranked {
  /* width: calc(var(--base-font-size-token-card-page) * 7.6); */
  width: 94% !important;
  margin-left: 3%;
  margin-top: 2vw;
  height: 45vw !important;
}

.img-card {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: all ease-in-out 0.4s;
  border-radius: calc(var(--base-font-size-token-card-page) * 0.4);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.token-card-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 37% !important;
  background-color: white;
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-token-card-page) * 0.4);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.token-card-text-row1 {
  width: 100%;
  color: #183b56;
  font-size: calc(var(--base-font-size-token-card-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  word-wrap: break-word;
  white-space: normal;
  padding-top: calc(var(--base-font-size-token-card-page) * (14 / 32));
  padding-left: calc(var(--base-font-size-token-card-page) * (14 / 32));
  padding-right: calc(var(--base-font-size-token-card-page) * (14 / 32));
}

.token-card-text-row2 {
  width: 90%;
  word-wrap: break-word;
  color: #183b56;
  font-size: calc(var(--base-font-size-token-card-page) * 0.45);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: calc(var(--base-font-size-token-card-page) * (14 / 32));
  padding-left: calc(var(--base-font-size-token-card-page) * (14 / 32));
}
.token-card-text-row2-creator-page {
  width: 90%;
  color: #2fb484;
  font-size: calc(var(--base-font-size-token-card-page) * 0.45);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: calc(var(--base-font-size-token-card-page) * (14 / 32));
  padding-left: calc(var(--base-font-size-token-card-page) * (14 / 32));
}
.token-card-token-ranking {
  padding: 3px 7px 3px 7px;
  width: 45px;
  height: 22px;
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-token-card-page) * 0.15);
  background: #2fb484;
  color: #fff;
  font-size: calc(var(--base-font-size-token-card-page) * 0.45);
  font-style: normal;
  font-weight: bold;
  line-height: normal;

  margin-left: 5px;
}
.token-card-total-tokens {
  margin-left: 3px;
  font-size: calc(var(--base-font-size-token-card-page) * 0.47);
  font-style: normal;
  font-weight: 650;
  line-height: normal;
}

.token-card-line-between-texts {
  width: 85%;
  align-self: center;

  border-bottom: calc(var(--base-font-size-token-card-page) * (1.9 / 32)) solid
    #d9d9d9;

  padding-top: calc(var(--base-font-size-token-card-page) * (20 / 32));
}

.token-card-footer {
  width: 100%;
  margin-top: calc(var(--base-font-size-token-card-page) * (8 / 32));
  border-radius: calc(var(--base-font-size-token-card-page) * 0.4);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.token-card-footer-text {
  font-size: calc(var(--base-font-size-token-card-page) * 0.42);
  padding-left: calc(var(--base-font-size-token-card-page) * (14 / 32));
  padding-bottom: calc(var(--base-font-size-token-card-page) * (30 / 32));
  color: #747474;
  font-weight: 500;
}
.token-card-footer-text-link {
  font-size: calc(var(--base-font-size-token-card-page) * 0.42);
}

.token-card-footer-link {
  width: 100%;
  height: calc(var(--base-font-size-token-card-page) * 1);
  margin-top: calc(var(--base-font-size-token-card-page) * (14 / 32));
  border-radius: calc(var(--base-font-size-token-card-page) * 0.4);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: #2fb484;
  text-align: center;
  color: white;
  justify-content: center;
  align-items: center;
  display: flex;
}

.collection-tokencard-hover-animation {
  animation: collection-tokencard-hover-animation 1s forwards;
  color: #fff;
}

@keyframes collection-tokencard-hover-animation {
  0% {
    background-color: #fff;
  }
  100% {
    box-shadow: 0 0 15px 60em rgba(83, 226, 173, 0.324);
    background-color: #53e2ae;
    z-index: 2;
  }
}

/* fonts */
@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  :root {
    --base-font-size-collection-page: 5vw;
  }
}
