/*-------- search section css ---------*/

.token-search-wrapper {
  position: relative;
}

.token-search-input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(var(--base-font-size-collection-page) * (42 / 32));
  border: calc(var(--base-font-size-collection-page) * (1 / 32)) solid #ced2da;
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);
  background: white;
  color: #747474;
  flex-grow: 0;
  width: 100%;
}

.token-search-input-wrapper:hover {
  border: calc(var(--base-font-size-collection-page) * (1.5 / 32)) solid #b4b8bf;
}

.token-search-input-icon {
  height: 100%;
  /* width: 10%; */
  display: flex;
  align-items: center;
  padding-left: calc(var(--base-font-size-collection-page) * (8 / 32));
  padding-right: calc(var(--base-font-size-collection-page) * (8 / 32));
}

.token-search-input {
  height: 100%;
  border: 0;
  width: calc(var(--base-font-size-collection-page) * 12);
  background: white;
  color: #747474;
  padding: calc(var(--base-font-size-collection-page) * (13 / 32)) 0;
  border: 0 calc(var(--base-font-size-collection-page) * (1 / 32)) solid #ced2da;
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);
  gap: calc(var(--base-font-size-collection-page) * 10);
  flex-grow: 1;
  cursor: pointer;
  font-size: calc(var(--base-font-size-collection-page) * (13 / 32));
}

.token-search-input-mobile {
  height: 100%;

  border: 0;
  background: white;
  color: #747474;
  padding: calc(var(--base-font-size-collection-page) * (13 / 32))
    calc(var(--base-font-size-collection-page) * (6 / 32));

  border: 0 calc(var(--base-font-size-collection-page) * (1 / 32)) solid #ced2da;
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);

  gap: calc(var(--base-font-size-collection-page) * 10);

  flex-grow: 1;
  cursor: pointer;

  font-size: calc(var(--base-font-size-collection-page) * (13 / 32));
}

.search-results {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the distance between input and results */
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 1.4vw;
  box-shadow: 0 2em 4em rgba(0, 0, 0, 0.1); /* Optional: Add box shadow */
  display: block;
  background-color: white;
  overflow-y: auto;
  max-height: 70vh;
  background-color: #ecf0f9;
  padding: calc(var(--base-font-size-collection-page) * (15 / 32));
  z-index: 1;
}

.search-results-mobile {
  position: absolute;
  top: calc(100% + 10px); /* Adjust the distance between input and results */
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 0.7vw;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow */
  display: block;
  background-color: white;
  overflow-y: auto;
  max-height: 70vh;
  background-color: #ecf0f9;
  padding: calc(var(--base-font-size-collection-page) * (15 / 32));
  padding-top: 0px;
  z-index: 1;
}

.search-section:focus + .search-results {
  display: block; /* Show results when input is focused */
}

.search-section-result-item {
  display: flex;
  align-items: center;
  border: none;
  width: 90%;
  border-radius: 0.7vw;
  margin-bottom: calc(var(--base-font-size-collection-page) * (5 / 32));
  height: 5vw !important;
  min-height: 5vw;
  background-color: rgba(47, 180, 132, 0.9);
  cursor: pointer;
}

.search-section-result-item:hover {
  color: white;
  background-color: #2fb484;
  opacity: 0.5;
  transition: 0.5s;
}

.search-section-result-item img {
  height: 50px; /* Adjust image height as needed */
  width: auto; /* Maintain aspect ratio */
  margin-right: 10px; /* Adjust spacing between image and details */
}

.search-section-result-item .details {
  flex-grow: 1; /* Expand details to fill remaining space */
}

.search-section-result-item .verified-icon {
  width: 20px; /* Adjust size of verified symbol */
  height: 20px;
  opacity: 0.8; /* Adjust opacity as needed */
}

.search-section-result-item-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 10%;
}

.search-section-result-item-image {
  height: 100% !important;
}

.search-section-result-item-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  color: aliceblue;
  font-weight: 800;
  font-size: calc(var(--base-font-size-folders-page) * (13 / 32));
  height: calc(var(--base-font-size-folders-page) * (43 / 32));
}

.search-close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  align-self: flex-end;
  margin-bottom: 5%;
}

/* scrollbar */

.search-results::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (6 / 32));
  background-color: transparent;
}

.search-results::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.search-results::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (5 / 32));
  background-color: transparent;
}

.search-results::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.search-results-mobile::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (6 / 32));
  background-color: transparent;
}

.search-results-mobile::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.search-results-mobile::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (5 / 32));
  background-color: transparent;
}

.search-results-mobile::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.token-search-input-mobile::placeholder {
  color: #c5c5c6;
  text-transform: uppercase;
}
.token-search-input::placeholder {
  color: #c5c5c6;
  font-size: calc(var(--base-font-size-collection-page) * (12 / 32));
  /* text-transform: uppercase; */
}

/* calc(var(--base-font-size-collection-page)*(1 / 32)) solid #ced2da */
