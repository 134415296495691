.header__logo {
  padding-right: px;
}

:root {
  --base-font-size-header: 2vw;
}

/* body {
  --item_page_left_comp_width: 630px;
  --item_page_right_comp_width: calc(4 / 5 * var(--item_page_left_comp_width));
} */

.nav-bar {
  width: 100%;
  height: 5vw;
  position: fixed;
  z-index: 10000;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2.5em);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-bar-pdf {
  width: 100%;
  height: 5vw;
  position: absolute;
  z-index: 10000;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2.5em);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-block {
  width: 95%;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1000;
  justify-content: space-evenly;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  /* padding: 0px; */
}

.backgroundBox {
  /* background-color: #ffffff; */
  position: static;
  height: 21vw;
  z-index: -1;
  width: 100vw;
}

.row-container {
  height: 30%;
}

.row-container * {
  height: 100%;
}

/* .header-align-section:hover {
  color: rgb(66, 56, 153);
} */

.header-editIcon-container {
  display: flex;
  width: 20%;
  height: 140%;
  /* padding: 1px 16px; */
  align-items: center;
  gap: 8px;
  border-radius: 25px;
  border: 2.5px solid #ecf0f9;
  margin-right: 10%;
  margin-left: 15%;
  padding: 1px 2%;
  line-height: 100%;
}

.highlighted {
  color: yellow;
  /* animation: highlight 1s linear infinite alternate; An example animation */
}

@keyframes highlight {
  0% {
    color: yellow;
  }
  100% {
    color: original-color; /* Replace 'original-color' with the default color of your EditIcon */
  }
}

#sign-up-btn {
  display: flex;
  width: 20%;
  height: 140%;
  background: white;
  color: #2fb484;
  border: 2px solid #2fb484;
  border-radius: 20px;
  font-weight: 700;
  font-size: var(--base-font-size-header);
  text-align: center;
  margin-left: -1%;
  margin-right: 5%;
  padding-left: 5%;
  padding-right: 5%;
  /* padding: 6px 10px; */
  text-wrap: nowrap;
  padding: 10px 30px;
  line-height: 90%;
}

#claim-wallet-btn {
  display: flex;
  width: 20%;
  height: 140%;
  /* text-align: center; */
  background: #2fb484;
  font-weight: 700;
  color: #fff;
  border: 0;
  border-radius: 30px;
  margin-left: -4%;
  margin-right: 6%;
  padding-left: -2%;
  padding-right: 5%;
  /* padding: 6px 10px; */
  font-size: var(--base-font-size-header);
  text-wrap: nowrap;
  padding: 10px 30px;
  line-height: 90%;
}

/*header-mobile-css*/
.nav-bar-mobile {
  width: 100%;
  height: 15vw;
  background-color: rgba(255, 255, 255, 0.75);
  position: fixed;
  z-index: 1000 !important;
  box-shadow: 0px 0px 4vw 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(calc(var(--base-font-size-header) * 100 / 32));
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--base-font-size-header);
}

.header-block-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw !important;
  margin: 0 2rem;
}

.hamburgerIcon-container-mobile {
  width: 20px;
  height: 14px;
  position: absolute;
  left: 24px;
  top: 21px;
}

.searchOutlineIcon-container-mobile {
  position: absolute;
  left: 213px;
  top: 12px;
  width: 32px;
  height: 32px;
  margin-left: 25px;
}

#claim-wallet-btn-mobile {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  right: 23px;
  top: 12px;
  border-radius: 4px;
  background: #2fb484;
  border-color: #ffffff;
}
.claim-wallet-btn-text {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* media queries */

@media screen and (min-width: 768px) and (max-width: 800px) {
  body {
    --item_page_left_comp_width: 300px;
  }

  .info-container {
    width: 320px;
  }
  #item-page-content-box {
    width: 290px;
  }
  .item-image-details-container {
    width: 320px;
  }
  .item-page-container-right-column {
    margin-left: 10px;
  }

  @media screen and (min-width: 800px) and (max-width: 900px) {
    body {
      --item_page_left_comp_width: 340px;
    }
    .info-container {
      width: 330px;
    }
    #item-page-content-box {
      width: 300px;
    }
    .item-image-details-container {
      width: 330px;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1000px) {
    body {
      --item_page_left_comp_width: 370px;
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1100px) {
    body {
      --item_page_left_comp_width: 420px;
    }
  }

  @media screen and (min-width: 1100px) and (max-width: 1200px) {
    body {
      --item_page_left_comp_width: 470px;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    body {
      --item_page_left_comp_width: 500px;
    }
  }

  @media screen and (min-width: 1300px) and (max-width: 1398px) {
    body {
      --item_page_left_comp_width: 550px;
    }
  }

  @media screen and (min-width: 1398px) and (max-width: 1500px) {
    body {
      --item_page_left_comp_width: 580px;
    }
  }

  @media screen and (min-width: 1900px) and (max-width: 2559px) {
    body {
      --item_page_left_comp_width: 790px;
    }
    .token-container {
      display: flex;
      transition: transform 0.3s ease;
      margin-left: 1550px;
      border: 2px solid red;
    }
  }

  @media screen and (min-width: 2560px) {
    body {
      --item_page_left_comp_width: 1050px;
    }
  }

  /* fonts */

  @media screen and (max-width: 768px) {
    /* Mobile font sizes */
    :root {
      --base-font-size-header: 0.825rem;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    /* Tablet font sizes */
    :root {
      --base-font-size: 0.875rem;
    }
  }
}

.search-result-hover:hover {
  color: white;
  background-color: #2fb484;
  transition: 0.5s;
}

.search-result-hover:hover img {
  opacity: 0.2;
  transition: 0.5s;
}

.profile-dropdown-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  top: calc(var(--base-font-size-header) * 3);
  right: 1.5vw;
  z-index: 0;
  animation: profile-dropdown-animation 0.5s ease-in-out;
  backdrop-filter: blur(3em) !important;
  background-color: white;
  /* background-color: rgba(255, 255, 255, 0.9); */
  border-radius: 0 0 calc(var(--base-font-size-header) * 12 / 32)
    calc(var(--base-font-size-header) * 12 / 32);
  width: 23vw;
  max-height: 100vh;
  position: fixed;
  padding-top: 1vw;
  padding-bottom: 1vw;
  box-shadow: 0 calc(var(--base-font-size-header) * 4 / 32)
    calc(var(--base-font-size-header) * 10 / 32) 0px rgba(0, 0, 0, 0.15);
}

@keyframes profile-dropdown-animation {
  0% {
    top: -20vw;
  }
  100% {
    top: 5.6vw;
  }
}

.profile-dropdown-button-animation {
  background-color: rgba(0, 0, 0, 0);
  color: #2fb484;
  transition: 0.3s;
}

.profile-dropdown-button-animation:hover {
  background-color: #2fb484;
  /* color: aliceblue !important; */
  transition: 0.3s;
}

.dropdown-redirect-row-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  color: #2fb484 !important;
  transition: 0.3s;
  padding-left: 3vw;
  gap: calc(var(--base-font-size-header) * 1);
  padding-top: 1vw;
  padding-bottom: 1vw;
  width: 100%;
  font-weight: 600;
  font-size: 1.2vw;
}

.dropdown-redirect-row-container:hover {
  background-color: #d7f4e4;
  transition: 0.3s;
  box-shadow: 0 calc(var(--base-font-size-header) * 0.1)
    calc(var(--base-font-size-header) * 0.6) 0px rgba(0, 0, 0, 0.1);
  border-left: calc(var(--base-font-size-header) * 0.2) solid #1b805e;
}

.profiledropdown-mobile-animation {
  /* ---Animation--- */
  animation: profile-dropdown-mobile-animation 0.5s ease-in-out;
  max-height: 80vh;
  font-size: calc(var(--base-font-size-header) * 1.4) !important;
  margin-top: 15vw;
  overflow-y: scroll;
  /* ---Styling--- */
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(calc(var(--base-font-size-header) * 100 / 32));
  box-shadow: 0px 0px 8vw 0px rgba(0, 0, 0, 0.35);
  position: fixed;
  z-index: 10;
  /* border-top: none; */
  padding: calc(var(--base-font-size-header) * 2);
  padding-left: calc(var(--base-font-size-header) * 3.5);
  padding-right: calc(var(--base-font-size-header) * 1.5);
  padding-bottom: calc(var(--base-font-size-header) * 2);
}

@keyframes profile-dropdown-mobile-animation {
  0% {
    top: -40vh;
  }
  100% {
    top: 0;
  }
}

.dropdown-link-text {
  color: #183b56;
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: calc(var(--base-font-size-header) * 1.5);
  font-weight: 550;
  font-size: calc(var(--base-font-size-header) * 0.55);
  /* padding-bottom: calc(var(--base-font-size-header) * 0.8); */
}
.dropdown-link-text:hover {
  color: #183b56;
  font-weight: 600;
}

.profiledropdown-mobile-container {
  display: flex;
  flex-direction: column;
  /* margin-top: calc(var(--base-font-size-header) * 1.5); */
  gap: calc(var(--base-font-size-header) * 1.5);
  align-items: center;
  justify-content: center;
}

.profiledropdown-mobile-link {
  border: none; /* Remove border */
  display: flex; /* Use flexbox layout */
  align-items: center; /* Align items vertically centered */
  color: #2fb484;
  font-weight: 600;
  text-decoration: none;
  font-size: calc(var(--base-font-size-header) * 1.7);
}

/* MJ logo and seprator */
.logo-button {
  height: 100%;
  display: flex;
  align-items: center;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  background-color: none;
}

.header-MJ-icon {
  height: calc(4 / 5 * var(--base-font-size-header) * (32 / 32));
  width: auto;
  margin-left: 0.5vw;
  margin-right: 0.7vw;
}

.header-MJ-title {
  height: calc(4 / 5 * var(--base-font-size-header) * (55 / 32));
  width: auto;
  margin-right: 0.5vw;
  margin-top: 0.4em;
}
.header-MJ-icon-mobile {
  height: calc(4 / 5 * var(--base-font-size-header) * 3);
  width: auto;
  margin-left: 2vw;
  margin-right: 2.3vw;
}

.header-MJ-title-mobile {
  height: calc(4 / 5 * var(--base-font-size-header) * 5);
  width: auto;
  margin-right: 0.5vw;
  margin-top: 0.4em;
}

.header-separator {
  font-size: calc(var(--base-font-size-header) * (40 / 32));
  font-weight: 250;
  color: rgba(224, 224, 224, 0.6);
  background-color: rgba(255, 255, 255, 0);
  margin-left: calc(var(--base-font-size-header) * (21 / 32));
  margin-right: calc(var(--base-font-size-header) * (21 / 32));
}

/* navigation links (Left of Search) */

.header-navigation-links {
  display: flex;
  flex-wrap: nowrap;
  background-color: rgba(255, 255, 255, 0);
  height: 100%;
  align-items: center;
  margin-right: 2vw;
}

.header-align-section {
  color: rgba(30, 30, 30, 0.6);
  font-size: calc(var(--base-font-size-header) * 0.5);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-left: 1.5vw;
  /* margin-right: calc(var(--base-font-size-header) * 0.8); */
  justify-content: center;
  transition: 0.4s;
  display: flex;
  flex-direction: row;
  height: 50%;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  align-items: center;
  margin-top: 0.2em;
  cursor: pointer;
  margin-right: 1.5vw;

  text-decoration: none;
  /* width: 6vw; */
}

.header-align-section:hover {
  color: #2fb484;
}

.header-align-section.active {
  color: #2fb483;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: calc(var(--base-font-size-header) * 0.1) solid #2fb483;
}

/* .header-align-section::before {
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.3s ease;
} */

/* user info section (right of Search) */

.header-user-info {
  position: relative;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0);
  /* margin-left: 8vw; */
}

.header-signin {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.header-login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-header) * (8 / 32));
  width: calc(var(--base-font-size-header) * 3);
  height: calc(var(--base-font-size-header) * 1.3);
  border: calc(var(--base-font-size-header) * 0.06) solid #2fb484;
  color: #2fb484;
  font-weight: 600;
  margin-left: 1vw;
  font-size: calc(var(--base-font-size-header) * 0.5);
  margin-right: 1vw;
}

.header-login-button-animation:hover {
  background-color: rgba(47, 180, 132, 1);
  color: aliceblue !important;
  transition: 0.3s;
}

.header-claimwallet-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: calc(var(--base-font-size-header) * (8 / 32));
  width: calc(var(--base-font-size-header) * 4);
  height: calc(var(--base-font-size-header) * 1.3);
  background-color: #2fb484;
  color: white;
  font-weight: 600;
  font-size: calc(var(--base-font-size-header) * 0.5);
  margin-left: 1vw;
}

.header-claimwallet-button-animation:hover {
  background-color: white !important;
  border: calc(var(--base-font-size-header) * 0.06) solid #2fb484;
  color: #2fb484 !important;
  transition: 0.3s;
}

.header-claimwallet-button-mobile {
  font-size: var(--base-font-size-header);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: calc(var(--base-font-size-header) * 1);
  width: 25vw;
  height: 8vw;
  background-color: #2fb484;
  color: white;
  font-weight: 600;
  margin-left: 10vw;
}

.header-claimwallet-button-animation-mobile:hover {
  background-color: white !important;
  border: calc(var(--base-font-size-header) * 0.06) solid #2fb484;
  color: #2fb484 !important;
  transition: 0.3s;
}

.header-loggedin {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 0em;
}

.header-user-profile-photo {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  margin-right: 0vw;
}

.header-user-profile-name {
  display: flex;
  flex-direction: column;
  margin-left: 0.8em;
  margin-top: 0.5em;
  margin-right: 4.5em;
  font-size: calc(var(--base-font-size-header) * 0.5);
}

.header-profile-dropdown {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10%;
  background-color: rgba(0, 0, 0, 0);
  width: calc(var(--base-font-size-header) * 1.5);
  height: calc(var(--base-font-size-header) * 1.5);
  z-index: 10;
}

.header-user {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.header-dropdown {
  display: flex;
  justify-items: flex-end;
  align-items: center;
}

.header-dropdown-icon {
  height: calc(var(--base-font-size-header) * 0.6);
  /* width: 2.15vw; */
}
.header-dropdown-icon-mobile {
  z-index: 1000000000;
  height: calc(var(--base-font-size-header) * 2);
  margin-left: calc(var(--base-font-size-header) * 3);
}

.header-space-mobile {
  width: calc(var(--base-font-size-header) * (400 / 32));
  margin-left: 10vw;
}

.header-profile-name {
  font-weight: 700;
  color: #2fb484;
  margin-top: calc(var(--base-font-size-header) * 0.08);
  white-space: nowrap;
}

/* header-profile-dropdown header-profile-dropdown-animation */
/* header-profile-dropdown header-profile-dropdown-animation */
