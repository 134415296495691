.loading-block-backgroundcolor-animation {
  background-color: #f3f3f3;
  background-image: linear-gradient(
    90deg,
    #f3f3f3 25%,
    #e0e0e0 37%,
    #f3f3f3 63%
  );
  background-size: 200% 100%;
  animation: loading-block-backgroundcolor-animation 1.8s infinite linear;
}

@keyframes loading-block-backgroundcolor-animation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
