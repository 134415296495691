:root {
  --base-font-size-collection-page: 2vw;
}

#CollectionPage {
  display: flex;
  height: 100vh;
  flex-direction: column;
  width: 100%;
}

/* banner profile section */
/* banner profile section */
.banner-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 27vw;
  overflow: hidden;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-icon-section {
  display: flex;
  margin-top: 1.5em;
  margin-left: 12.5em;
  margin-right: calc(var(--base-font-size-collection-page) * 1.2);
  display: flex;
  padding-left: 30px;
  height: 30px;
  justify-content: flex-end;
  align-items: center;
  /* border: 2px solid red; */
}

.profile-image-box {
  width: calc(var(--base-font-size-collection-page) * 4.5);
  height: calc(var(--base-font-size-collection-page) * 4.5);
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  z-index: 99;
  padding: 3px;
}

.profile-image-box-mobile {
  top: 50vw;
  height: 23vw;
  width: 23vw;
  position: absolute;
  left: 8vw;
  background-color: white;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5vw;
  overflow: hidden;
}

.profile-image-box-container {
  margin-top: -4%;
  display: flex;
  margin-left: 7%;
  justify-content: start;
  align-items: center;
  width: 90%;
  align-items: end;
  justify-content: space-between;
  padding-right: 7%;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

/* collection icons */
.collection-info-icons-row {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  gap: calc(var(--base-font-size-collection-page) * (10 / 32));
  margin-left: calc(var(--base-font-size-collection-page) * (10 / 32));
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
}

.collection-icon-division {
  margin-left: calc(var(--base-font-size-collection-page) * (5 / 32));
  margin-right: calc(var(--base-font-size-collection-page) * (5 / 32));
  width: calc(var(--base-font-size-collection-page) * (1 / 32));
  height: calc(var(--base-font-size-collection-page) * 0.5);
  border: calc(var(--base-font-size-collection-page) * (0.5 / 32)) solid #d9d9d9;
  background: #d9d9d9;
}

.marketplace-logo {
  height: calc(var(--base-font-size-collection-page) * (24 / 32));
  width: calc(var(--base-font-size-collection-page) * (24 / 32));
}

/* Collection Info box */

.collection-info-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 7%;
  margin-right: 10%;
  margin-top: calc(var(--base-font-size-collection-page) * (16 / 32));
  margin-bottom: calc(var(--base-font-size-collection-page) * (24 / 32));
}

.collection-info-collection-name {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #183b56;
  font-size: calc(var(--base-font-size-collection-page) * 1);
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  margin-bottom: calc(var(--base-font-size-collection-page) * (16 / 32));
}
.collection-info-collection-name-creator {
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
}

.collection-info-collection-address {
  /* color: #2fb484; */
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  /* font-weight: 600; */
  line-height: normal;
  margin-left: calc(var(--base-font-size-collection-page) * 0.2);
}
.collection-info-collection-address:hover {
  color: #2fb484;
  /* text-decoration-line: underline; */
}
.collection-info-collection-address-line {
  margin-top: 12px;
  margin-left: 5px;
  width: 219px;
  height: 2px;
  background: rgba(116, 116, 116, 0.75);
}
.collection-info-collection-address-dot {
  width: calc(var(--base-font-size-collection-page) * 0.1);
  border-radius: 100%;
  height: calc(var(--base-font-size-collection-page) * 0.1);
  font-weight: bolder;
  background-color: black;
}

.collection-info-collection-address-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-grow: 1;
  padding: 0;
  gap: 10px;
}

.collection-info-values {
  color: #2fb484;
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: calc(var(--base-font-size-collection-page) * 0.15);
}

.collection-info-headings {
  color: #183b56;
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.collection-info-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 2%;
  width: 60%;
  /* padding-right: 8vw; */
  /* margin-top: 2%; */
}
/* @media (min-width: 2050px) {
  .collection-info-row {
    gap: 1%;
  }
}
@media (min-width: 1700px) {
  .collection-info-row {
    gap: 2%;
  }
}
@media (min-width: 1100px) {
  .collection-info-row {
    gap: 3%;
  }
}
@media (min-width: 1350px) {
  .collection-info-row {
    gap: 4%;
  }
} */

/* info box mobile */
.collection-info-box-mobile-container-with-time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 80%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.info-box-mobile-container {
  display: flex;
  flex-direction: column;
  border-radius: 4vw;
  justify-content: center;
  align-items: center;
  gap: 6vw;

  padding-top: 4vw;
  padding-bottom: 4vw;

  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);

  backdrop-filter: blur(10.5px);
  width: 100%;
  flex-wrap: wrap;
}

.info-box-mobile-container-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  /* gap: calc(var(--base-font-size-collection-page) * 1); */
  flex-wrap: wrap;
  width: 75%;
}

.collection-info-box-headings-mobile {
  color: #747474;
  font-size: calc(var(--base-font-size-collection-page) * (14 / 32));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.collection-info-box-unique-owners-value-mobile {
  color: #2fb484;
  font-size: calc(var(--base-font-size-collection-page) * (14 / 32));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/* Collection Info - mobile */
.collection-info-section-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 70%; */
  flex-direction: column;
  gap: calc(var(--base-font-size-collection-page) * 1);
  flex-wrap: wrap;
}

.collection-info-mobile-value {
  color: #2fb484;
  margin-left: calc(var(--base-font-size-collection-page) * 8 / 32);
  font-weight: 700;
}

.collection-info-mobile-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: calc(var(--base-font-size-collection-page) * 0.6);
  flex-wrap: wrap;
}

.collection-info-address-mobile {
  color: #2fb484;
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: underline;
  margin-left: calc(var(--base-font-size-collection-page) * 0.1);
}

.collection-info-name-mobile {
  display: flex;
  width: 70%;
  margin-bottom: calc(var(--base-font-size-collection-page) * 0.8);
  margin-left: 7%;
  margin-top: calc(var(--base-font-size-collection-page) * 2);
  flex-wrap: wrap;
}

/* Description Section*/

.description-tab-container {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  width: auto;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 1rem;
  margin-left: 6%;
  margin-right: 6%;
}

.description-section-content {
  color: #183b56;
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: auto;
  margin-left: 6%;
  margin-right: 6%;
  line-height: 1.5;
}

.description-tab {
  padding-left: 0px;
  padding-right: 0px;
  cursor: pointer;
  color: rgb(104, 104, 104); /* color: #183b56; */
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 0.5rem;
}

.description-tab.active {
  border-bottom: #2fb484 4px solid;
  color: #2fb484;
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* Section tabs */
.section-tab-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: calc(var(--base-font-size-collection-page) * (27 / 32));
  width: auto;
  flex-wrap: wrap;
  border-bottom: calc(var(--base-font-size-collection-page) * (1 / 32)) solid
    #d9d9d9;
  margin-left: 6%;
  margin-right: 6%;
  margin-top: calc(var(--base-font-size-collection-page) * (32 / 32));
  /* padding-top: 2.5%; */
}

.section-tab {
  position: relative;
  display: flex;
  cursor: pointer;
  gap: calc(var(--base-font-size-collection-page) * (10 / 32));
  padding: 1% 2%;
  margin-right: 1%;
  color: rgb(104, 104, 104);
  font-size: calc(var(--base-font-size-collection-page) * 0.55);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 0.7em;
}

.section-tab::before {
  content: "";
  position: absolute;
  bottom: 0;

  width: 0;
  height: 3px;
  background-color: #2fb484;
}

.section-tab.active {
  color: #2fb484;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.section-tab.active::before {
  width: 100%;
  left: 50%;
  transform: translateX(-50%); /* Center the pseudo-element */
  transition: width 0.3s ease; /* Transition for width */
}

/* .freeze-section {
} */
.d-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.d-top-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-middle-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.items-container-with-filter-sections-container {
  display: grid;
  grid-template-columns: 100px 300px;
  align-items: start;
  justify-items: start;
}

.d-fourth-row-with-single-column {
  display: grid;
  /* grid-template-columns: 500px; */
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-items: center;
  margin-left: 20%;
  gap: 50px;
  grid-column-gap: 20%;
}
.items-tab-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-style: normal;
  line-height: normal;
  font-size: calc(var(--base-font-size-collection-page) * (14 / 32));
  font-weight: 700;
  margin-bottom: 2.6%;
  margin-top: calc(var(--base-font-size-collection-page) * (16 / 32));
  width: 100vw;
  flex-wrap: wrap;
  padding-left: 6%;
  padding-right: 6%;
}
.items-tab-section-header-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-style: normal;
  line-height: normal;
  font-size: calc(var(--base-font-size-collection-page) * (14 / 32));
  font-weight: 700;
  margin-bottom: 3%;
  margin-top: 2%;
  flex-wrap: wrap;
  width: 90%;
}
.no-tokens-message-container {
  text-align: center;
  margin-top: 2px;
  color: #555;
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  display: flex;
  justify-content: center;
}

.items-tab-section-items-container {
  display: flex;
  height: auto;
  flex-direction: row;
  gap: calc(var(--base-font-size-collection-page) * 1.2);
  justify-content: space-between;
  padding-left: 4%;
  padding-right: 4%;
  margin-bottom: calc(var(--base-font-size-collection-page) * (24 / 32));
}

.items-tab-items-container-without-filter-section {
  width: 100%;
}

.items-tab-items-container-with-filter-section {
  width: var(--items-tab-items-container-with-filter-section-width);
}

.items-tab-bigger-items-container-with-filter-section {
  animation: gridSlidIn 0.6s ease-in-out forwards;
}
@keyframes gridSlidIn {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(0%);
  }
}

@media (min-width: 1100px) {
  .items-tab-section-items-container {
    --columns-bigger-items-container-without-filter-section: 5;
    --columns-smaller-items-container-without-filter-section: 4;

    --columns-bigger-items-container-with-filter-section: 4;
    --columns-smaller-items-container-with-filter-section: 3;
    --items-tab-items-container-with-filter-section-width: 100%;
  }
}
@media (max-width: 1099px) {
  .items-tab-section-items-container {
    --columns-bigger-items-container-without-filter-section: 2;
    --columns-smaller-items-container-without-filter-section: 2;

    --columns-bigger-items-container-with-filter-section: 2;
    --columns-smaller-items-container-with-filter-section: 2;
    --items-tab-items-container-with-filter-section-width: 95%;
  }
}

/* Info box */
.collection-info-box-container-with-time {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  margin-left: 12%;
  margin-right: 12%;
  margin-bottom: calc(var(--base-font-size-collection-page) * (45 / 42));
}

.collection-info-box-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  height: 8vw;
  width: 100%;

  border-radius: calc(var(--base-font-size-collection-page) * (24 / 32));
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(
    calc(var(--base-font-size-collection-page) * (10.5 / 32))
  );
}

.collection-info-box {
  display: flex;
  justify-content: center;
  gap: calc(var(--base-font-size-collection-page) * 0.2);
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 33.3%;
}

.collection-info-box-floor-price {
  display: flex;
  gap: calc(var(--base-font-size-collection-page) * 0.2);
  flex-direction: column;
  background-color: #2fb48315;
  align-items: center;
  justify-content: center;
  justify-items: center;
  width: 33.3%;
  cursor: pointer;
  height: 100%;
}

.collection-info-box-leftmost {
  padding-left: 5%;
}

.collection-info-box-headings {
  color: #747474;
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.collection-info-box-values {
  color: #183b56;
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.collection-info-box-unique-owners-value {
  color: #2fb484;
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.collection-info-box-unique-owners-row {
  /* padding-top: 10px; */
  gap: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
}

.collection-info-box-division {
  width: 1.5px;
  height: 78px;
  background: #cdcdcd;
}

.collection-info-box-time {
  width: 100%;

  color: #747474;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: right;

  padding-right: calc(var(--base-font-size-collection-page) * (45 / 32));
  margin-top: calc(var(--base-font-size-collection-page) * (12 / 32));

  font-size: calc(var(--base-font-size-collection-page) * (14 / 32));
}

/*-------- filter section css ---------*/

.filter-section {
  position: sticky;
  top: calc(var(--base-font-size-collection-page) * (100 / 32));
  padding: calc(var(--base-font-size-collection-page) * (5 / 32))
    calc(var(--base-font-size-collection-page) * (10 / 32))
    calc(var(--base-font-size-collection-page) * (7 / 32))
    calc(var(--base-font-size-collection-page) * (10 / 32));

  max-height: 80vh;
  overflow-y: auto;
  height: auto;

  border-top: rgba(0, 0, 0, 0)
    calc(var(--base-font-size-collection-page) * (20 / 32)) solid;
  border-bottom: rgba(0, 0, 0, 0)
    calc(var(--base-font-size-collection-page) * (20 / 32)) solid;

  border-radius: calc(var(--base-font-size-collection-page) * (18 / 32));

  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(
    calc(var(--base-font-size-collection-page) * (10 / 32))
  );
  z-index: 1;
  animation: filterSlideIn 0.6s ease-in-out forwards;
}

@keyframes filterSlideIn {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(0%);
  }
}

.filter-section.visible {
  width: calc(var(--base-font-size-collection-page) * 8.5);
}

.filter-section.not_visible {
  width: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}

.filter-section-search-bar {
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 0.7px solid #cdcdcd;
  padding: 4% 0% 4% 4%;
  font-weight: 500;
  font-size: calc(var(--base-font-size-collection-page) * 0.4);
  cursor: pointer;
  border-radius: calc(var(--base-font-size-collection-page) * 0.2);
  background-color: rgb(236, 240, 249);
  margin-bottom: calc(var(--base-font-size-collection-page) * (24 / 32));

  width: 80%;
  margin-left: 10%;
}

.filter-section-search-bar:focus {
  border: 1px solid #2fb484;
  cursor: pointer;
}

.filter-section-mobile {
  position: fixed;
  bottom: -2050px;
  padding: 10px 25px 7px 15px;
  height: auto;
  max-height: 70vh;
  border-radius: 18px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: white;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  overflow-y: auto;
  width: 82%;
  z-index: 99999;

  transition: bottom 0.5s ease-in-out;
}

.filter-section-mobile.visible {
  bottom: 0;
}

.filter-section-headings-row {
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;

  margin-bottom: calc(var(--base-font-size-collection-page) * (12 / 32));
  /* margin-top: calc(var(--base-font-size-collection-page) * (20 / 32)); */
}

.filter-section-headings {
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-collection-page) * (24 / 32));
  color: #183b56;
  font-size: calc(var(--base-font-size-collection-page) * 0.55);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-block-end: 0px;
}
.filter-section-traits-sub-headings {
  color: #183b56;
  font-size: calc(var(--base-font-size-collection-page) * 0.45);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.price-filter-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: calc(var(--base-font-size-collection-page) * (32 / 32));
  font-size: calc(var(--base-font-size-collection-page) * (8 / 32));
  width: 100%;
  padding-left: 12%;
  margin-bottom: calc(var(--base-font-size-collection-page) * (15 / 32));
}
.price-filter-block-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: calc(var(--base-font-size-collection-page) * (32 / 32));
  font-size: calc(var(--base-font-size-collection-page) * (8 / 32));
  width: 100%;
  padding-left: 12%;
  margin-bottom: calc(var(--base-font-size-collection-page) * (15 / 32));
}
.price-filter-price-range {
  display: flex;
  align-items: center;
  gap: 3%;

  width: calc(var(--base-font-size-collection-page) * (140 / 32));
}

.price-filter-price-input {
  padding-left: calc(var(--base-font-size-collection-page) * (8 / 32));
  width: calc(var(--base-font-size-collection-page) * (64 / 32));
  height: calc(var(--base-font-size-collection-page) * 1);
  flex-shrink: 0;
  background: #ecf0f9;
  border: 0.7px solid #cdcdcd;
  color: #747474;
  font-size: calc(var(--base-font-size-collection-page) * (10 / 32));
  font-style: normal;
  font-weight: 550;
  line-height: normal;
  border-radius: calc(var(--base-font-size-collection-page) * (10 / 32));
}

.price-filter-price-input:focus {
  border: 1px solid #2fb484;
}

.price-filter-min-max-to {
  color: #747474;
  font-size: calc(var(--base-font-size-collection-page) * (12 / 32));
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.price-filter-apply-button {
  width: 100%;
  height: calc(var(--base-font-size-collection-page));
  cursor: pointer;
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-collection-page) * (10 / 32));
  background: #ecf0f9;
  border: 0.5px solid #cdcdcd;
  color: #183b56;
  font-size: calc(var(--base-font-size-collection-page) * (12 / 32));
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.price-filter-apply-button:hover {
  border: 2px solid #2fb484;
}
.filter-section-traits-filter {
  margin-top: 10%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filter-section-traits-filter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.filter-section-traits-filter-trait-type {
  width: 100%;

  display: flex;
  justify-content: space-between;
  color: #183b56;
  padding-bottom: calc(var(--base-font-size-collection-page) * (20 / 32));
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: 750;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
}
.filter-section-sub-headings {
  color: #183b56;
  font-size: calc(var(--base-font-size-collection-page) * 0.4);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filter-section-traits-filter-heading-count-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 7px;
  color: #183b56;
  font-size: calc(var(--base-font-size-collection-page) * (10 / 32));
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}

.filter-section-traits-filter-trait-values {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: calc(var(--base-font-size-collection-page) * 0.2);
  color: #183b56;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: calc(var(--base-font-size-collection-page) * 0.5);
}
.filter-section-traits-filter-trait-values-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-section-traits-filter-trait-value-checkbox {
  width: calc(var(--base-font-size-collection-page) * (12 / 32));
  height: calc(var(--base-font-size-collection-page) * (12 / 32));
  cursor: pointer;
}
.filter-section-traits-filter-trait-value-checkbox {
  width: calc(var(--base-font-size-collection-page) * (12 / 32));
  height: calc(var(--base-font-size-collection-page) * (12 / 32));
  cursor: pointer;
}

.filter-section-traits-filter-trait-value-count {
  color: #2fb484;
  padding-left: 3px;
  font-size: calc(var(--base-font-size-collection-page) * (10 / 32));
  font-weight: bolder;
}

/*-------- Filter Button css ---------*/
.filter-button {
  display: flex;
  color: #183b56;
  align-items: center;
  padding: calc(var(--base-font-size-collection-page) * (12 / 32))
    calc(var(--base-font-size-collection-page) * 1);
  gap: calc(var(--base-font-size-collection-page) * 0.5);
  font-size: calc(var(--base-font-size-collection-page) * (18 / 32));
}

/*--------------Live Button CSS-------------*/
.live-button {
  padding-top: calc(var(--base-font-size-collection-page) * (12 / 32));
  padding-bottom: calc(var(--base-font-size-collection-page) * (12 / 32));
  padding-right: calc(var(--base-font-size-collection-page) * 2.5);
  padding-left: calc(var(--base-font-size-collection-page) * 2.2);
  display: flex;
  align-items: center;
  gap: calc(var(--base-font-size-collection-page) * 0.5);
  color: #183b56;
}

.items-tab-section-header-second-row-mobile {
  display: flex;
  margin-top: 2%;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  gap: 2%;
}

/**Token Grid - CSS**/

.items_tab_header_buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--base-font-size-collection-page) * (42 / 32));
  border: calc(var(--base-font-size-collection-page) * (1 / 32)) solid #ced2da;
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);
  background: white;
  width: 8vw;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
}

.items_tab_header_buttons-home-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(var(--base-font-size-collection-page) * (42 / 32));
  border: calc(var(--base-font-size-collection-page) * (1 / 32)) solid #ced2da;
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);
  background: white;
  width: 8vw;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
  font-size: calc(var(--base-font-size-collection-page) * (18 / 32));
}
.items_tab_header_buttons-home-page:hover {
  border: calc(var(--base-font-size-collection-page) * (1 / 32)) solid #cdcdcd;
}

.items_tab_header_buttons:active {
  background-color: #c6eade;
}

.items_tab_header_buttons:hover {
  cursor: pointer;
  border: 1px solid #b4b8bf;
}

.ToggleGridButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(var(--base-font-size-collection-page) * (42 / 32));
  border: calc(var(--base-font-size-collection-page) * (1 / 32)) solid #ced2da;
  padding: calc(var(--base-font-size-collection-page) * 0.2);
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);
  background: #ecf0f9;
  width: 7vw;
  cursor: pointer;
  overflow: hidden;
}

.grid-button {
  border: 0.5px solid transparent;
  border-radius: calc(var(--base-font-size-collection-page) * 0.2);
  padding: calc(var(--base-font-size-collection-page) * 0.15);
  background: #ecf0f9;
  transition: all 0.3s ease;
}

.grid-button-active {
  border-color: #2fb484;
  background: white;
}

/*-------- Activity Tab Section css ---------*/

.transfer-history-table-container {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  border-radius: 1.125em;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding: 1vw;
  align-self: center;
}
.transfer-history-table-container-with-scroll {
  display: flex;
  flex-direction: column;
  height: auto;
  max-height: calc(var(--base-font-size-collection-page) * (500 / 32));
  overflow-y: auto;
  width: 100%;
  border-radius: 1.125em;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding: 1vw;
  align-self: center;
}
.creator-owner-activity-with-scroll {
  height: auto;
  max-height: calc(var(--base-font-size-collection-page) * (500 / 32));
  overflow-y: auto;
}
.transfer-history-table-container-with-filter {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 72vw;
  /* overflow-x: auto; */
  border-radius: 1.125rem;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px calc(var(--base-font-size-collection-page) * (2 / 32))
    calc(var(--base-font-size-collection-page) * (15 / 32)) 0px
    rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding: 0px calc(var(--base-font-size-collection-page) * (10 / 32))
    calc(var(--base-font-size-collection-page) * (10 / 32))
    calc(var(--base-font-size-collection-page) * (7 / 32));
  padding-top: calc(var(--base-font-size-collection-page) * (10 / 32));
  align-self: center;
}

.transfer-history-table {
  --base-font-size-transfer-history-table: 2vw;
  margin-bottom: 0%;
  width: 100%;
}

.transfer-history-table-header-table {
  position: sticky;
  top: calc(var(--base-font-size-collection-page) * (100 / 32));
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: calc(var(--base-font-size-collection-page) * (0.5 / 32)) solid
    #cdcdcda4;
  z-index: 1;
  border-radius: calc(var(--base-font-size-collection-page) * (18 / 32));
  backdrop-filter: blur(10px);
}

.non-sticky-border-bottom {
  border-bottom: calc(var(--base-font-size-collection-page) * (2 / 32)) solid
    #cdcdcda4;
}

.scroll-on-table-header {
  background-color: white;
  position: sticky;
  top: 0;
}

@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  .transfer-history-table {
    --base-font-size-transfer-history-table: 4vw;
  }
}

.transfer-history-table tbody tr {
  border-bottom: calc(var(--base-font-size-transfer-history-table) * (0.5 / 32))
    solid #d9d9d9;
  border-left: calc(var(--base-font-size-transfer-history-table) * (4 / 32))
    solid transparent;
}

.transfer-history-table tbody tr:hover {
  background-color: #d7f4e4;
  border-left: calc(var(--base-font-size-transfer-history-table) * (4 / 32))
    solid #1b805e;
}

.transfer-history-table
  tr:nth-child(odd).transfer-history-table-loading-gradient
  td {
  background: linear-gradient(
    90deg,
    rgba(218, 214, 214, 0) 0%,
    rgba(66, 64, 64, 0.8) 50%,
    rgba(19, 7, 7, 0) 100%
  );
  background-size: 200% 100%;
  animation: moveGradient 2s linear infinite;
}

.transfer-history-table .transfer-history-table-loading-gradient td {
  width: 100%; /* Adjust the width as needed */
  height: 100px !important;
}

@keyframes moveGradient {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.transfer-history-table > thead {
  top: 0;
}

.transfer-history-table > th > .tableHeader {
  display: flex;
  flex-direction: column;
}

.transfer-history-table > th > .tableHeader > .subheading {
  color: grey;
}

.transfer-history-table td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: inherit;
}

.transfer-history-table-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--base-font-size-transfer-history-table) * 0.4);
}

.transfer-history-table-item-info {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.transfer-history-table-item-id {
  color: #183b56;
  font-size: calc(var(--base-font-size-transfer-history-table) * 0.45);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.transfer-history-table-item-name {
  font-size: calc(var(--base-font-size-transfer-history-table) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.transfer-history-table-token-info-token-ranking {
  padding: calc(var(--base-font-size-transfer-history-table) * 3 / 64)
    calc(var(--base-font-size-transfer-history-table) * 7 / 64)
    calc(var(--base-font-size-transfer-history-table) * 3 / 64)
    calc(var(--base-font-size-transfer-history-table) * 7 / 64);
  width: calc(var(--base-font-size-transfer-history-table) * 45 / 64);
  height: calc(var(--base-font-size-transfer-history-table) * 22 / 64);
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-transfer-history-table) * 5 / 64);
  background: #2fb484;
  color: #fff;
  font-size: calc(var(--base-font-size-transfer-history-table) * 0.8 / 2);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-left: calc(var(--base-font-size-transfer-history-table) * 5 / 64);
}

.transfer-history-table-token-info-token-opensea {
  margin-left: calc(var(--base-font-size-transfer-history-table) * 5 / 64);
}

.transfer-history-table-item-rank-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.transfer-history-table-item-rank {
  color: #2fb484;
  font-size: calc(var(--base-font-size-transfer-history-table) * 0.4);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.transfer-history-table-token-image {
  width: calc(var(--base-font-size-transfer-history-table) * 80 / 32);
  height: calc(var(--base-font-size-transfer-history-table) * 80 / 32);
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-transfer-history-table) * 10 / 32);
  object-fit: cover;
}

.transfer-history-table-cell {
  color: #183b56;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.image-hover {
  /* border: 2px solid red; */
  margin-top: calc(var(--base-font-size-transfer-history-table) * 1.2);
}

.transfer-history-table-price-usd-cell {
  color: #747474;
  font-size: calc(var(--base-font-size-transfer-history-table) * 0.4);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.transfer-history-table-user-details-cell {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-transfer-history-table) * 0.4);
}

.transfer-history-table-user-details-icons {
  display: flex;
  width: calc(var(--base-font-size-transfer-history-table) * 3);
  align-items: center;
  align-self: center;
  gap: calc(var(--base-font-size-transfer-history-table) * 0.35);
  line-height: normal;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.transfer-history-table-user-details-icons-item-page {
  display: flex;
  gap: calc(var(--base-font-size-collection-page) * 0.35);
  line-height: normal;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/*-------- Selected filters css ---------*/

.selected-filter:hover {
  background-color: #1b805e;
}
.selected-filter {
  width: auto;
  /* max-width: 30%; */
  align-items: center;
  justify-content: center;
  padding: calc(var(--base-font-size-collection-page) * (7 / 32))
    calc(var(--base-font-size-collection-page) * (10 / 32));
  background-color: rgba(47, 180, 131, 1);
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.selected-filter-remove-button:hover {
  /* background-color: #16664d; */
}

.selected-filter-remove-button {
  /* font-size: calc(var(--base-font-size-collection-page) * 9); */
  /* border-radius: 100%; */
  background-color: rgba(0, 0, 0, 0);
  margin-left: calc(var(--base-font-size-collection-page) * 0.2);
  color: white;
  border: none;
  /* padding: none; */
  /* cursor: pointer; */
  height: calc(var(--base-font-size-collection-page) * (2 / 32));
  width: auto;
  /* width: calc(var(--base-font-size-collection-page) * 1.2); */
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

/* .selected-filter-remove-button:hover{
  background-color: white;
  color: black;
}
.selected-filter:hover {
  background-color: white;
  color: black;
  cursor: pointer;
  border: 0.7px solid red;
} */

/* community AD */
.communityad-box {
  width: 437px;
  border: 1px solid #2fb484;
  border-radius: 20px;
  fill: rgba(255, 255, 255, 0.35);
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.15));
  backdrop-filter: blur(10.5px);
}

.communityad-button {
  border-radius: 50px;
  background: #2fb484;
  display: flex;
  width: 95px;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  color: #fff;
  font-size: calc(var(--base-font-size-collection-page) * 0.5);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  border: none;
}

/* load more button for grid */

.load-more-button {
  width: "30%";
  font-size: calc(var(--base-font-size-collection-page) * 0.45);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  text-align: center;
  min-width: 200px;
  margin-top: 2.5%;
  margin-bottom: 4%;
  transition: all 0.3s ease-in-out;

  /* padding: 0.5em !important; */

  background-color: "#2fb484";
  color: "white";
  border-radius: 0.5vw;
}

.load-more-button:hover {
  background-color: #ffffff !important;
  color: #2fb484 !important;
}

/* scroll to top button*/

.scroll-to-top-button {
  position: relative;
  padding: calc(var(--base-font-size-collection-page) * (10 / 32));
  background-color: #2fb484; /* You can change the background color */
  color: #fff; /* You can change the text color */
  border: none;
  cursor: pointer;
  display: none;
  border-radius: calc(var(--base-font-size-collection-page) * (100 / 32));
  height: calc(var(--base-font-size-collection-page) * (40 / 32));
  width: calc(var(--base-font-size-collection-page) * (40 / 32));

  margin-bottom: calc(var(--base-font-size-collection-page) * (20 / 32));
  margin-right: calc(var(--base-font-size-collection-page) * (20 / 32));

  font-size: calc(var(--base-font-size-collection-page) * (16 / 32));
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

.scroll-to-top-button:hover {
  background-color: #16714f; /* You can change the hover background color */
}
/* Additional style to show the button when visible */
.scroll-to-top-button-visible {
  display: block;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
}
/* Track styles (the area where the scrollbar is located) */
::-webkit-scrollbar-track {
  display: none;
}

/* Handle styles (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background-color: #2fb484; /* You can set the color of the scrollbar thumb */
  border-radius: 6px; /* You can adjust the border-radius as needed */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #16714f; /* You can set the color when the scrollbar is hovered */
}

.filter-section::-webkit-scrollbar {
  width: 3px; /* You can adjust the width as needed */
}

/* Track styles for the specific component */
.filter-section::-webkit-scrollbar-track {
  background-color: transparent; /* You can set the background color as needed */
}

/* Handle styles for the specific component */
.filter-section::-webkit-scrollbar-thumb {
  background-color: #2fb484; /* You can set the color of the scrollbar thumb */
  border-radius: 6px; /* You can adjust the border-radius as needed */
}

/* Handle on hover for the specific component */
.filter-section::-webkit-scrollbar-thumb:hover {
  background-color: #16714f; /* You can set the color when the scrollbar is hovered */
}
.filter-section-mobile::-webkit-scrollbar {
  width: 3px; /* You can adjust the width as needed */
}

/* Track styles for the specific component */
.filter-section-mobile::-webkit-scrollbar-track {
  background-color: transparent; /* You can set the background color as needed */
}

/* Handle styles for the specific component */
.filter-section-mobile::-webkit-scrollbar-thumb {
  background-color: #2fb484; /* You can set the color of the scrollbar thumb */
  border-radius: 6px; /* You can adjust the border-radius as needed */
}

/* Handle on hover for the specific component */
.filter-section-mobile::-webkit-scrollbar-thumb:hover {
  background-color: #16714f; /* You can set the color when the scrollbar is hovered */
}

.toggle-switch-container {
  width: calc(var(--base-font-size-collection-page) * (20 / 32));
  height: calc(var(--base-font-size-collection-page) * (16 / 32));
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: calc(var(--base-font-size-collection-page) * (20 / 32));
  font-size: calc(var(--base-font-size-collection-page) * 0.4);
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2fb484;
  transition: 0.4s;
  border-radius: 1vw;
}

.slider:before {
  position: absolute;
  content: "";
  height: 70%;
  width: 25%;
  left: calc(var(--base-font-size-collection-page) * (3 / 32));
  bottom: calc(var(--base-font-size-collection-page) * (2 / 32));
  background-color: white;
  transition: 0.4s;
  border-radius: 2vw;
  padding-bottom: 0.9vw !important;
}

input:checked + .slider:before {
  transform: translateX(100%);
  left: calc(var(--base-font-size-collection-page) * (20 / 32));
}

.toggle-label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(var(--base-font-size-collection-page) * (6 / 32));
  color: white;
  font-size: calc(var(--base-font-size-collection-page) * 0.35);
}

.and {
  left: calc(var(--base-font-size-collection-page) * (21 / 32));
  padding-bottom: 1.5% !important;
}

.or {
  right: calc(var(--base-font-size-collection-page) * (3 / 32));
  padding-bottom: 1.5% !important;
}

/* Whitelist/Blacklist Button */

.priority-button {
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  border: 1.5px solid #2fb484;
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);
  background-color: transparent;
  cursor: pointer;
  gap: calc(var(--base-font-size-collection-page) * 0.3);
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

.priority-button.active {
  background-color: #000;
  border: 1.5px solid #000;
  color: #fff;
}

/* media queries */

/* fonts */
@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  :root {
    --base-font-size-collection-page: 7vw !important;
  }
  th.hiddenOnMobile,
  td.hiddenOnMobile {
    display: none;
  }
}

.whitelist-blacklist-section {
  display: flex;
  width: 13em;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: calc(var(--base-font-size-collection-page) * 20 / 32);
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);
  border: 0.5px solid #b4b8bf;
  justify-content: space-between;
  align-items: center;
}

.whitelist-blacklist-section-mobile {
  display: flex;
  /* width: 13em; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: calc(var(--base-font-size-collection-page) * 20 / 32);
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);
  border: 0.5px solid #b4b8bf;
  justify-content: space-between;
  align-items: center;
}

.whitelist-blacklist-button {
  cursor: pointer;
  font-weight: 600;
  font-size: calc(var(--base-font-size-collection-page) * 0.55);
  padding: calc(var(--base-font-size-collection-page) * 0.15)
    calc(var(--base-font-size-collection-page) * 0.3);
  color: #183b56;
  border-radius: calc(var(--base-font-size-collection-page) * 0.4);
}

.whitelist-blacklist-button.active {
  background-color: #b4b8bf96;
  color: #183b56;
}

.transfer-history-table-container-with-scroll::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.transfer-history-table-container-with-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.transfer-history-table-container-with-scroll::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: calc(var(--base-font-size-item-page) * 6 / 32);
}

.transfer-history-table-container-with-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.transfer-history-table-container-with-scroll::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.creator-owner-activity-with-scroll::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}

.creator-owner-activity-with-scroll::-webkit-scrollbar-track {
  background-color: transparent;
}

.creator-owner-activity-with-scroll::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: calc(var(--base-font-size-item-page) * 6 / 32);
}

.creator-owner-activity-with-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.creator-owner-activity-with-scroll::-webkit-scrollbar {
  width: calc(var(--base-font-size-item-page) * 5 / 32);
  height: calc(var(--base-font-size-item-page) * 5 / 32);
}
