body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  -o-font-smoothing: antialiased !important;
  -ms-font-smoothing: antialiased !important;
  font-smoothing: antialiased !important;
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  font-weight: 900 !;
  -webkit-transition: 0.3s ease-in-out !important;
  transition: 0.3s ease-in-out !important;
  background-color: #fdfdfd;
  overflow-x: hidden;
}

body.modal-open {
  padding-right: 0 !important;
  overflow: auto;
}

.container-setting {
  margin-top: 5%;
}

.container {
  max-width: 100vw;
  display: flex;
}
