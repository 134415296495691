.background {
  background-image: url("../assets/images/BG.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  position: absolute;
  background-position: 0.2vw 20vw;
}

.background-mobile {
  background-color: #effaf5;
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: absolute;
  background-position: center 40%;
}
