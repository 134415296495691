:root {
  --base-font-size-folders-page: 2.4vw;
}

/* folders page - creator-info section*/

.folders-page-main-container-with-nft-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  padding-top: calc(4 / 5 * var(--base-font-size-header) * (109 / 32));
}

.folders-page-main-container-with-nft-section-mobile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  height: 100vh;
  padding-top: calc(4 / 5 * var(--base-font-size-header) * (109 / 32));
}

.folders-page-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}

.folders-page-main-container-user-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-left: 10%;
  align-items: center;
  /* margin-top: 3%; */
}

.folders-page-main-container-user-info-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  margin-left: 15%;
  align-items: flex-start;
  margin-top: calc(var(--base-font-size-folders-page) * (80 / 32));
  gap: calc(var(--base-font-size-folders-page) * (30 / 32));
}

.folder-creator-info-main-section {
  display: flex;
  flex-direction: column;
}

.wallet-addresses-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.checkbox-dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.dropdown-section {
  cursor: pointer;
  border-radius: calc(var(--base-font-size-folders-page) * (7 / 32));
  width: auto;
  height: auto;
  cursor: pointer;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: calc(var(--base-font-size-folders-page) * (7 / 32));
  max-height: calc(var(--base-font-size-folders-page) * (200 / 32));
  max-width: calc(var(--base-font-size-folders-page) * (350 / 32));
  overflow-y: auto;
  display: none;
  width: auto;
  margin-top: 5%;
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-weight: 500;
  cursor: pointer;
  z-index: 3;
  color: black;
}

.checkbox-dropdown.open .dropdown-content {
  display: block;
  cursor: pointer;
}

.checkbox-option {
  padding: calc(var(--base-font-size-folders-page) * (8 / 32));
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox-option:hover {
  background: #2fb4833f;
}

.checkbox-option label {
  margin-left: calc(var(--base-font-size-folders-page) * (8 / 32));
  cursor: pointer;
}

.folders-addresses-search-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  width: 90%;
  margin-top: calc(var(--base-font-size-folders-page) * (20 / 32));
}

.wallet-user-selected-addresses {
  display: flex;
  flex-direction: row;
  align-items: top;
  /* gap: calc(var(--base-font-size-folders-page) * (10 / 32)); */
  margin-left: 11%;
  width: 50%;
  text-wrap: wrap;
  word-wrap: break-word;
  height: auto;
  line-height: calc(var(--base-font-size-folders-page) * (30 / 32));
  font-weight: 500;
  flex-wrap: wrap;
  cursor: pointer;
}

.wallet-user-selected-address {
  margin-top: calc(var(--base-font-size-folders-page) * (0 / 32));
  margin-right: calc(var(--base-font-size-folders-page) * (10 / 32));
  font-weight: bold;
  /* color: #2fb484; */
}
.wallet-user-selected-address:hover {
  color: #2fb484;
  text-decoration: underline;
}

/* folders page - my folders section*/

.folder-section-main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: calc(var(--base-font-size-folders-page) * (60 / 32)); */

  justify-content: center;
  align-items: center;
}

.folders-section {
  display: flex;
  margin-top: 3%;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.folders-section-header {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.folders-section-header-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-folders-page) * 0.6);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  width: 50%;
  padding-right: calc(var(--base-font-size-folders-page) * (2 / 32));
}
.folders-section-header-heading-current {
  color: #2fb484;
  font-size: calc(var(--base-font-size-folders-page) * 0.6);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
  width: 50%;
  padding-right: calc(var(--base-font-size-folders-page) * (2 / 32));
}
.folders-section-header-go-back {
  display: flex;
  cursor: pointer;
}
.folders-section-header-heading:hover {
  color: #0d9a66;
}

.folders-section-header-heading-bar {
  color: #183b56;
  font-size: calc(var(--base-font-size-folders-page) * 0.6);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  width: 50%;
  padding-right: calc(var(--base-font-size-folders-page) * (2 / 32));
}

.folders-section-all-folders-container {
  width: 82%;
  /* margin-top: 1%; */
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--base-font-size-folders-page) * (40 / 32));
  align-items: center;
  margin-bottom: 4%;
  height: auto;
  /* max-height: 80vh; */
  /* overflow-y: auto; */
  overflow-x: hidden;
  padding-top: 2%;
  padding-bottom: 1%;
  cursor: pointer;
  padding-left: 1.2%;
}

.folders-section-all-folders-container-main {
  width: 85%;
  margin-top: calc(var(--base-font-size-folders-page) * (40 / 32));
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 3%;
  margin-bottom: calc(var(--base-font-size-folders-page) * (40 / 32));
}

.folders-section-all-folders-container-main-mobile {
  width: 85%;
  margin-top: calc(var(--base-font-size-folders-page) * (40 / 32));
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: calc(var(--base-font-size-folders-page) * (40 / 32));
}

.folders-section-all-folders-container > * {
  width: calc(20% - 10px);
  margin-bottom: calc(var(--base-font-size-folders-page) * (10 / 32));
}

.add-folder-button {
  display: flex;
  box-shadow: inset 0 calc(var(--base-font-size-folders-page) * (2 / 32)) 0 0
      hsla(0, 0%, 100%, 0.2),
    inset 0 calc(var(--base-font-size-folders-page) * (-1 / 32)) 0 0
      rgba(0, 0, 0, 0.25),
    0 calc(var(--base-font-size-folders-page) * (2 / 32))
      calc(var(--base-font-size-folders-page) * (6 / 32)) 0 rgba(0, 0, 0, 0.1);
  padding: calc(var(--base-font-size-folders-page) * (10 / 32));
  gap: calc(var(--base-font-size-folders-page) * 0.4);
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  background: #2fb484;
  color: #fff;
  font-size: calc(var(--base-font-size-folders-page) * 0.4);
  max-width: calc(var(--base-font-size-folders-page) * (140 / 32));
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  border: none;
  height: calc(var(--base-font-size-folders-page) * (30 / 32));
  white-space: nowrap;
}
.add-folder-button-mobile {
  display: flex;
  box-shadow: inset 0 calc(var(--base-font-size-folders-page) * (2 / 32)) 0 0
      hsla(0, 0%, 100%, 0.2),
    inset 0 calc(var(--base-font-size-folders-page) * (-1 / 32)) 0 0
      rgba(0, 0, 0, 0.25),
    0 calc(var(--base-font-size-folders-page) * (2 / 32))
      calc(var(--base-font-size-folders-page) * (6 / 32)) 0 rgba(0, 0, 0, 0.1);
  padding: calc(var(--base-font-size-folders-page) * (10 / 32));
  gap: calc(var(--base-font-size-folders-page) * 0.4);
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  background: red;
  color: #fff;
  font-size: calc(var(--base-font-size-folders-page) * 0.5);
  max-width: calc(var(--base-font-size-folders-page) * (140 / 32));
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  border: none;
  height: calc(var(--base-font-size-folders-page) * (40 / 32));
  white-space: nowrap;
}

.add-folder-button:hover {
  background: #1b805e;
}

.load-more-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}
.load-more-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

/* each folder*/

.each-folder-box {
  position: relative;
  width: 100%;
  backdrop-filter: blur(0.8em);
  height: calc(var(--base-font-size-folders-page) * (95 / 22));
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-folders-page) * (16 / 32));
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0px calc(var(--base-font-size-folders-page) * (4 / 32))
    calc(var(--base-font-size-folders-page) * (15 / 32)) 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--base-font-size-folders-page) * (12 / 32));
  padding-top: 4%;
  padding-bottom: 8%;
  cursor: pointer;
}

.folder-name {
  font-size: calc(var(--base-font-size-folders-page) * 0.4);
  font-weight: 500;
}

.each-folder-box:hover {
  background: #2fb4833f;
}

.each-folder-name {
  color: #183b56;
  font-size: calc(var(--base-font-size-folders-page) * 0.45);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.sub-folders-tokens-section-headings {
  font-size: calc(var(--base-font-size-folders-page) * 0.45);
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: normal;
}

.sub-folders-tokens-section-count {
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  margin-left: 1%;
  font-weight: 900;
  color: white;
  width: auto;
  background-color: #2fb484;
  display: flex;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  padding: calc(var(--base-font-size-folders-page) * (2 / 32))
    calc(var(--base-font-size-folders-page) * (5 / 32));
}

.sub-folders-tokens-count-on-folders {
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-weight: 600;
  color: #183b56;
  width: auto;
  border: 1px solid #2fb484;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  padding: calc(var(--base-font-size-folders-page) * (3 / 32))
    calc(var(--base-font-size-folders-page) * (4 / 32));
}

.folder-tokens-count {
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-weight: 700;
  color: #183b56;
  margin-left: calc(var(--base-font-size-folders-page) * (10 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  border: 0.5px solid #2fb484;
  padding: calc(var(--base-font-size-folders-page) * (1 / 32))
    calc(var(--base-font-size-folders-page) * (4 / 32));
}

/* create folder modal */

.folder-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.folder-modal {
  background: #fff;
  padding: calc(var(--base-font-size-folders-page) * (20 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (16 / 32));
  width: calc(var(--base-font-size-folders-page) * (300 / 32));
  height: calc(var(--base-font-size-folders-page) * (170 / 32));
  box-shadow: 0px calc(var(--base-font-size-folders-page) * (4 / 32))
    calc(var(--base-font-size-folders-page) * (15 / 32)) 0px rgba(0, 0, 0, 0.15);
}

.folder-modal input {
  width: 100%;

  border: none;
}

.folder-modal-create-folder-button {
  display: inline-flex;
  box-shadow: inset 0 2px 0 0 hsla(0, 0%, 100%, 0.2),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: calc(var(--base-font-size-folders-page) * (10 / 32));
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  background: #2fb484;
  color: #fff;
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: 100%;
}

.folder-modal-create-folder-button:hover {
  background: #1b805e;
}

.folder-modal-delete-button {
  display: inline-flex;
  box-shadow: inset 0 2px 0 0 hsla(0, 0%, 100%, 0.2),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  padding: calc(var(--base-font-size-folders-page) * (10 / 32));
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  background: #c7202b;
  color: #fff;
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: end;
  justify-self: right;
  align-self: flex-end;
  width: 40%;
}

.folder-modal-create-folder-cancel-button {
  display: inline-flex;
  /* box-shadow: inset 0 2px 0 0 hsla(0, 0%, 100%, 0.2),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.1); */
  padding: calc(var(--base-font-size-folders-page) * (10 / 32));
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  /* background: rgba(216, 216, 216, 0.6); */
  background: white;
  color: #747474;
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  border: 1px solid #b7b8b9;
  display: flex;
  align-items: center;
  justify-self: center;
  align-self: center;
  width: 30%;
}

/* folder NFTs section */

.folders-page-nft-section-main-container {
  width: calc(var(--base-font-size-folders-page) * (500 / 32));
  overflow: auto;
  flex-shrink: 0;
  background: #d7f4e4;
  animation: slideFromRight 0.5s ease;
  border-radius: calc(var(--base-font-size-folders-page) * (16 / 32));
}

@keyframes slideFromRight {
  from {
    transform: translateX(20vw);
  }
  to {
    transform: translateX(0);
  }
}

.sub-folders-tokens-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-left: 1%; */
  gap: calc(var(--base-font-size-folders-page) * (20 / 32));
}

.sub-folders-tokens-section-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: calc(var(--base-font-size-folders-page) * (15 / 32));

  padding: calc(var(--base-font-size-folders-page) * (7 / 32));
  margin-bottom: calc(var(--base-font-size-folders-page) * (30 / 32));
  gap: calc(var(--base-font-size-folders-page) * (40 / 50));
}

.sub-folders-tokens-section-row-without-nft-section {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: calc(var(--base-font-size-folders-page) * (15 / 32));

  margin-bottom: calc(var(--base-font-size-folders-page) * (30 / 32));
  gap: calc(var(--base-font-size-folders-page) * (40 / 50));
}

.sub-folders-tokens-section-row-mobile {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: calc(var(--base-font-size-folders-page) * (15 / 32));

  margin-bottom: calc(var(--base-font-size-folders-page) * (30 / 32));
  gap: calc(var(--base-font-size-folders-page) * (40 / 50));
}

.folders-page-nft-section-main-container-mobile {
  position: fixed;
  padding: calc(var(--base-font-size-folders-page) * (10 / 32)) 0px
    calc(var(--base-font-size-folders-page) * (7 / 32))
    calc(var(--base-font-size-folders-page) * (15 / 32));
  height: 70vw;
  min-height: 50vh;
  max-height: calc(100vh - 18vw);
  border-radius: calc(var(--base-font-size-folders-page) * (18 / 32));
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: white;
  box-shadow: 0px calc(var(--base-font-size-folders-page) * (2 / 32))
    calc(var(--base-font-size-folders-page) * (15 / 32)) 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(calc(var(--base-font-size-folders-page) * (10 / 32)));
  overflow-y: auto;
  width: 90%;
  margin-left: 5%;
  z-index: 2;
  transition: bottom 5s ease-in-out;
  overflow-x: hidden;
}

.folders-page-nft-section-main-container-mobile.visible {
  /* transition: bottom 0.5s ease-in-out; */
  bottom: 0;
}

.folders-page-nft-section-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: normal;
  margin-top: calc(var(--base-font-size-folders-page) * (30 / 32));
  margin-left: 5%;
  margin-right: 7%;
  margin-bottom: calc(var(--base-font-size-folders-page) * (20 / 32));
}

/* all folder tokens*/
.folder-token-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
  width: 90%;
  margin-left: 5%;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 2.5%;
  padding-bottom: 5%;
  margin-bottom: calc(var(--base-font-size-folders-page) * (20 / 82));
}
.folder-token-container-mobile {
  display: flex;
  flex-wrap: wrap;
  gap: calc(var(--base-font-size-folders-page) * (24 / 32));
  width: 90%;
  margin-left: 5%;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 2.5%;
  padding-bottom: 5%;
  margin-bottom: calc(var(--base-font-size-folders-page) * (20 / 82));
}

.nfts-loading-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-folders-page) * 0.4);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 5%;
  width: 310%;
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-folders-page) * (10 / 32));
  justify-content: center;
  align-items: center;
  padding-left: 15%;
}

/* each token in token section for folders*/

.each-token-card-main-container {
  width: 100%;
  min-height: calc(var(--base-font-size-folders-page) * (300 / 32));
  height: auto;
  border-radius: calc(var(--base-font-size-folders-page) * (18 / 32));
  background: #fff;
  box-shadow: 0px calc(var(--base-font-size-folders-page) * (4 / 32))
    calc(var(--base-font-size-folders-page) * (15 / 32)) 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
}

.each-token-card-main-container-compact {
  width: calc(var(--base-font-size-folders-page) * (140 / 32));
  margin-bottom: 5%;
  flex-wrap: nowrap;
  word-wrap: break-word;
  min-height: calc(var(--base-font-size-folders-page) * (300 / 32));
  height: auto;
  border-radius: calc(var(--base-font-size-folders-page) * (18 / 32));
  background: #fff;
  box-shadow: 0px calc(var(--base-font-size-folders-page) * (4 / 32))
    calc(var(--base-font-size-folders-page) * (15 / 32)) 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
}

.each-token-card-image-container {
  width: 100% !important;
  height: 55% !important;
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-folders-page) * (18 / 32))
    calc(var(--base-font-size-folders-page) * (18 / 32)) 0px 0px;
  padding: none;
  margin: none;
  overflow: hidden;
}

.each-token-card-image {
  width: 100% !important;
  height: 100% !important;
  border-radius: calc(var(--base-font-size-folders-page) * (18 / 32))
    calc(var(--base-font-size-folders-page) * (18 / 32)) 0px 0px;
  object-fit: cover;
}

.each-token-card-info-container {
  width: 90%;
  flex-wrap: nowrap;
  height: 40%;
  word-wrap: break-word;
  margin-top: calc(var(--base-font-size-folders-page) * (6 / 32));
  display: flex;
  flex-direction: column;
  margin-left: calc(var(--base-font-size-folders-page) * (7 / 32));
  justify-content: space-around;
}

.each-token-card-info-name {
  color: #183b56;
  font-size: calc(var(--base-font-size-folders-page) * 0.45);
  font-style: normal;
  font-weight: 700;
  flex-wrap: nowrap;
  word-wrap: break-word;
  line-height: normal;
  cursor: pointer;
}

.each-token-card-info-name:hover {
  color: #0d9a66;
}

.each-token-card-collection-name {
  color: #183b56;
  flex-wrap: nowrap;
  color: #747474;
  word-wrap: break-word;
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: calc(var(--base-font-size-folders-page) * (5 / 32));
  margin-bottom: calc(var(--base-font-size-folders-page) * (5 / 32));
}
.each-token-card-collection-name:hover {
  color: #0d9a66;
}

.each-token-card-info-purchase-box {
  border-top: 1px solid #d9d9d9;
  width: 85%;
  padding-top: calc(var(--base-font-size-folders-page) * (3 / 32));
}

.each-token-card-info-purchase {
  color: #747474;
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.each-token-card-info-purchase-value {
  color: #183b56;
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.token-checkbox {
  display: none;
  position: absolute;
  top: 0.7em;
  left: 0.8em;
  cursor: pointer;
  height: 1em;
  width: 1em;
}

.each-token-card-main-container:hover .token-checkbox,
.token-checkbox.selected {
  display: block;
}

.select-all-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 2px 0 0 hsla(0, 0%, 100%, 0.2),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  width: calc(var(--base-font-size-folders-page) * (110 / 32));
  height: calc(var(--base-font-size-folders-page) * (32 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  background-color: #2fb484;
  color: white;
  font-weight: 600;
  line-height: normal;
  padding: calc(var(--base-font-size-folders-page) * (8 / 32))
    calc(var(--base-font-size-folders-page) * (7 / 32));
  text-align: center;
  font-size: calc(var(--base-font-size-folders-page) * 0.45);
  cursor: pointer;
  margin-left: 62%;
  transition: all 0.3s ease-in-out;
}

.reset-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 2px 0 0 hsla(0, 0%, 100%, 0.2),
    inset 0 -1px 0 0 rgba(0, 0, 0, 0.25), 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  width: calc(var(--base-font-size-folders-page) * (130 / 32));
  height: calc(var(--base-font-size-folders-page) * (32 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  background-color: #2fb484;
  gap: calc(var(--base-font-size-folders-page) * (8 / 32));
  color: white;
  line-height: normal;
  border: none;
  font-weight: 600;
  /* padding: calc(var(--base-font-size-folders-page) * (8 / 32))
    calc(var(--base-font-size-folders-page) * (7 / 32)); */
  text-align: center;
  font-size: calc(var(--base-font-size-folders-page) * 0.45);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.reset-button:hover {
  background-color: #16664d;
}

.nft-section-reset-select-all-row {
  display: flex;
  flex-direction: row;
  width: 88%;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
}

.select-all-button-container {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  margin-left: 5%;
}

.select-all-button:hover {
  background-color: #1b805e;
  color: #fff;
  cursor: pointer;
}
.select-all-checkbox {
  width: 1.2em;
  height: 1.2em;
  cursor: pointer;
}

/* search section nfts*/

.search-section-nfts {
  height: calc(var(--base-font-size-collection-page) * (40 / 32));
  border: none;
  border-radius: calc(var(--base-font-size-collection-page) * (10 / 32));
  background: #f0f0f0;
  border: 0.7px solid #4cbb8f;
  color: #183b56;
  padding: calc(var(--base-font-size-collection-page) * (13 / 32))
    calc(var(--base-font-size-collection-page) * (16 / 32));
  gap: calc(var(--base-font-size-collection-page) * (10 / 32));
  width: calc(var(--base-font-size-collection-page) * (260 / 32));
  cursor: pointer;
  font-size: calc(var(--base-font-size-collection-page) * 0.4);
}

.search-section-nfts:hover {
  border: 0.7px solid #4cbb8f;
}
.search-section-nfts:focus {
  border: 1px solid #4cbb8f;
}

.nft-section-search-mobile {
  margin-left: 5%;
  margin-right: 7%;
}

/* modal for deleting folder and tokens */
.red-prompt-heading {
  color: #c7202b;
  font-size: calc(var(--base-font-size-folders-page) * 0.45);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.red-prompt-sub-heading {
  color: #747474;
  font-size: calc(var(--base-font-size-folders-page) * 0.4);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.folder-path-icon-box {
  padding: calc(var(--base-font-size-folders-page) * (3 / 32))
    calc(var(--base-font-size-folders-page) * (6 / 32))
    calc(var(--base-font-size-folders-page) * (3 / 32))
    calc(var(--base-font-size-folders-page) * (6 / 32));
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  background: rgba(0, 0, 0, 0.25);
  border: 0.5px solid transparent;
  backdrop-filter: blur(10.5px);
}
.folder-path-icon-box:hover {
  border: 0.5px solid #2fb484;
}

/* dropdown */
.folders-page-filter-section-dropdown {
  color: #747474;
  font-size: calc(var(--base-font-size-folders-page) * 0.4);
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  padding: calc(var(--base-font-size-folders-page) * (8 / 32));
  width: 100%;
  height: calc(var(--base-font-size-folders-page) * (25 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  background-color: #ecf0f9;
}

/* custom drop down */
.custom-dropdown {
  width: 100%;
  height: auto;
  max-height: 40vh;
  z-index: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: auto;
  background-color: #ecf0f9;
  border: calc(var(--base-font-size-folders-page) * (0.5 / 32)) solid #ced2da;
  cursor: pointer;

  padding-bottom: calc(var(--base-font-size-folders-page) * (10 / 32));
  padding-left: calc(var(--base-font-size-folders-page) * (10 / 32));
  padding-top: calc(var(--base-font-size-folders-page) * (10 / 32));

  border-radius: calc(var(--base-font-size-folders-page) * (12 / 32));
  /* margin-right: calc(var(--base-font-size-folders-page) * (10 / 32)); */
}

.custom-dropdown-smaller {
  width: 20%;
  height: auto;
  z-index: 100;
  position: absolute;
  max-height: 30vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow-y: auto;
  background-color: white;
  border: calc(var(--base-font-size-folders-page) * (0.5 / 32)) solid #ced2da;
  cursor: pointer;
  padding: calc(var(--base-font-size-folders-page) * (10 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (12 / 32));
}

.custom-dropdown:hover {
  border: 0.5px solid #747474;
}

.custom-dropdown-options {
  padding: calc(var(--base-font-size-folders-page) * (8 / 32));
  font-size: calc(var(--base-font-size-folders-page) * 0.35);
  font-weight: 500;
  cursor: pointer;
  width: 100%;
}
.custom-dropdown-option {
  background-color: #fff;
  margin-top: calc(var(--base-font-size-folders-page) * (8 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  padding: calc(var(--base-font-size-folders-page) * (8 / 32));
  text-wrap: wrap;
  word-wrap: break-word;
}
.custom-dropdown-option:hover {
  background-color: #2fb4833f;
}
.custom-dropdown-search {
  border: none;
  width: 100%;
  /* font-size: calc(var(--base-font-size-folders-page) * 0.35); */

  background-color: transparent;
  color: black;
}

/* fonts */
@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  :root {
    --base-font-size-folders-page: 8vw;
  }
}

/* Scrollbar CSS */

.folder-token-container::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.folder-token-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.folder-token-container:-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.folder-token-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

/* */

.folders-section-all-folders-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (6 / 32));
  background-color: transparent;
}

.folders-section-all-folders-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.folders-section-all-folders-container:-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (5 / 32));
  background-color: transparent;
}

.folders-section-all-folders-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

/* */

.folders-page-nft-section-main-container-mobile::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (6 / 32));
  background-color: transparent;
}

.folders-page-nft-section-main-container-mobile::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.folders-page-nft-section-main-container-mobile::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (5 / 32));
  background-color: transparent;
}

.folders-page-nft-section-main-container-mobile::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.folders-page-nft-section-main-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (6 / 32));
  background-color: transparent;
}

.folders-page-nft-section-main-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.folders-page-nft-section-main-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (5 / 32));
  background-color: transparent;
}

.folders-page-nft-section-main-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.folders-page-main-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: transparent;
}

.folders-page-main-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.folders-page-main-container::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: transparent;
}

.folders-page-main-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.dropdown-content::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (4 / 32));
  height: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: transparent;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.dropdown-content::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: transparent;
}

.dropdown-content::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.custom-dropdown::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (4 / 32));
  height: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: transparent;
}

.custom-dropdown::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.custom-dropdown::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: transparent;
}

.custom-dropdown::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.custom-dropdown-smaller::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (4 / 32));
  height: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: transparent;
}

.custom-dropdown-smaller::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.custom-dropdown-smaller::-webkit-scrollbar {
  width: calc(var(--base-font-size-folders-page) * (4 / 32));
  background-color: transparent;
}

.custom-dropdown-smaller::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.folder-guide-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  padding: calc(var(--base-font-size-folders-page) * (20 / 32));
  border-radius: calc(var(--base-font-size-folders-page) * (12 / 32));
  width: calc(var(--base-font-size-folders-page) * (600 / 32));
  height: auto;
  /* height: calc(var(--base-font-size-folders-page) * (650 / 32)); */
  max-height: 90%;
  box-shadow: 0px calc(var(--base-font-size-folders-page) * (4 / 32))
    calc(var(--base-font-size-folders-page) * (15 / 32)) 0px rgba(0, 0, 0, 0.15);

  font-size: calc(var(--base-font-size-folders-page) * (16 / 32));
}

.folder-guide-modal-content-area-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 39%;
  margin-bottom: calc(var(--base-font-size-folders-page) * 1);
  /* line-height: calc(var(--base-font-size-folders-page) * (30 / 32)); */
}

.folder-guide-modal-content-area-text-box-row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  /* gap: 1%; */

  height: 80%;
  margin-bottom: 3%;

  line-height: calc(var(--base-font-size-folders-page) * (30 / 32));

  transition: left 0.3s ease-in-out;
}

.folder-guide-modal-content-area-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  line-height: calc(var(--base-font-size-folders-page) * (30 / 32));
}

.folder-guide-modal-content-area-heading {
  width: 100%;
  height: 20%;
  align-self: flex-start;
  color: #183b56;
  font-size: calc(var(--base-font-size-folders-page) * 0.55);
  font-weight: 700;

  margin-bottom: 2%;
}

.folder-guide-modal-content-area-points {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 77%;
  font-size: calc(var(--base-font-size-folders-page) * 0.4);
  font-weight: 400;
  margin-bottom: 0;
}

.folder-guide-modal-content-area-carousal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3%;
  width: 100%;
}

.folder-guide-carousal-circle {
  width: calc(
    var(--base-font-size-folders-page) * (12 / 32)
  ); /* Set the width of the circle */
  height: calc(
    var(--base-font-size-folders-page) * (12 / 32)
  ); /* Set the height of the circle */
  background-color: #d7f4e4; /* Set the inside color */
  border: calc(var(--base-font-size-folders-page) * (3 / 32)) solid #2fb484;
  /* Set the border width and color */
  border-radius: 50%; /* This makes the div circular */
  display: flex; /* Optional: To center content inside the circle */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  cursor: pointer;
}

.folder-guide-carousal-circle-selected {
  width: calc(
    var(--base-font-size-folders-page) * (24 / 32)
  ); /* Set the width of the circle */
  height: calc(
    var(--base-font-size-folders-page) * (24 / 32)
  ); /* Set the height of the circle */
  background-color: #2fb484; /* Set the inside color */
  border: calc(var(--base-font-size-folders-page) * (5 / 32)) solid #d7f4e4; /* Set the border width and color */
  border-radius: 50%; /* This makes the div circular */
  display: flex; /* Optional: To center content inside the circle */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  cursor: pointer;
}

.folder-guide-modal-footer {
  /* position: absolute;
  bottom: 0;
  left:calc(var(--base-font-size-folders-page) * (20 / 32));
  width: calc(var(--base-font-size-folders-page) * (650 / 32)); */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
  width: 100%;

  padding-left: 10%;
  padding-right: 10%;
  flex-grow: 1;
  /* margin-bottom: 3%; */
}

.folder-guide-modal-footer-button {
  display: flex;
  box-shadow: inset 0 calc(var(--base-font-size-folders-page) * (2 / 32)) 0 0
      hsla(0, 0%, 100%, 0.2),
    inset 0 calc(var(--base-font-size-folders-page) * (-1 / 32)) 0 0
      rgba(0, 0, 0, 0.25),
    0 calc(var(--base-font-size-folders-page) * (2 / 32))
      calc(var(--base-font-size-folders-page) * (6 / 32)) 0 rgba(0, 0, 0, 0.1);
  padding: calc(var(--base-font-size-folders-page) * (12 / 32));
  padding-top: calc(var(--base-font-size-folders-page) * (6 / 32));
  padding-bottom: calc(var(--base-font-size-folders-page) * (6 / 32));
  gap: calc(var(--base-font-size-folders-page) * 0.4);
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-folders-page) * (8 / 32));
  background: #2fb484;
  color: #fff;
  max-width: calc(var(--base-font-size-folders-page) * (140 / 32));
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  border: none;
  white-space: nowrap;
}

.folder-guide-modal-footer-button:hover {
  background: #1b805e;
}
