:root {
  --base-font-size-user-provenance-page: 2vw;
}

/* user provenance main page */

.user-provenance-main-container {
  margin-top: 9%;
  width: 88%;
  margin-left: 6%;
}
.user-provenance-main-container-mobile {
  margin-top: calc(15vw + 7%);
  width: 88%;
  margin-left: 6%;
}

.my-provenance-main-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.8);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
.user-provenance-main-details-container {
  margin-top: calc(var(--base-font-size-user-provenance-page) * (24/32));
  padding: calc(var(--base-font-size-user-provenance-page) * (32 / 32))
    calc(var(--base-font-size-user-provenance-page) * (43 / 32))
    calc(var(--base-font-size-user-provenance-page) * (26 / 32))
    calc(var(--base-font-size-user-provenance-page) * (44 / 32));
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-user-provenance-page) * (16 / 32));
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10.5px);
}

.provenance-tokens-main-section-mobile {
  margin-top: 3%;
  width: 85vw;
  padding: calc(var(--base-font-size-user-provenance-page) * (32 / 32))
    calc(var(--base-font-size-user-provenance-page) * (15 / 32))
    calc(var(--base-font-size-user-provenance-page) * (20 / 32))
    calc(var(--base-font-size-user-provenance-page) * (15 / 32));
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-user-provenance-page) * (16 / 32));
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1),
    2px -4px 15px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(10.5px);
}

.user-provenance-main-details-container-mobile {
  margin-top: 3%;
  padding-top: calc(var(--base-font-size-user-provenance-page) * (32 / 32));
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.user-provenance-sort-search-container {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 1vw;
  padding-left: 25%;
}

.user-provenance-sort-search-container-mobile {
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 1vw;
}

.user-provenance-tokens-checked-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  width: auto;
  margin-bottom: 2%;
  gap: "1em";
  margin-left: 25%;
}
.user-provenance-tokens-checked-row-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  width: 100%;
  margin-bottom: 2%;
  gap: "1em";
  margin-left: auto;
}

.user-provenance-filters-tokens-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  margin-bottom: 2%;
}

.provenance-select-all-button {
  width: calc(var(--base-font-size-folders-page) * (150 / 32));
  border-radius: 0.5vw;
  background-color: #2fb484;
  color: white;
  line-height: normal;
  padding: calc(var(--base-font-size-folders-page) * (5 / 32))
    calc(var(--base-font-size-folders-page) * (7 / 32));
  text-align: center;
  font-size: calc(var(--base-font-size-folders-page) * 0.4);
  cursor: pointer;
  /* margin-left: 75%; */
}

/* ProvenanceCollectionsFilterSection */

.provenance-filter-section-container {
  position: sticky;
  top: calc(var(--base-font-size-collection-page) * (100 / 32));
  align-self: start;
  margin-top: 6%;
  padding-top: 5%;
  width: calc(var(--base-font-size-user-provenance-page) * (300 / 32));
  height: auto;
  overflow-y: auto;
  max-height: calc(var(--base-font-size-user-provenance-page) * (650 / 32));
  border-radius: calc(var(--base-font-size-user-provenance-page) * (18 / 32));
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding-bottom: 2%;
  overflow-x: hidden;
}

.provenance-filter-section-mobile {
  position: fixed;
  overflow-x: hidden;
  bottom: -2050px;
  padding: 20px 25px 7px 15px;
  height: auto;
  max-height: 70vh;
  border-radius: 18px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: white;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  overflow-y: auto;
  width: 82%;
  z-index: 99999;
  transition: bottom 0.5s ease-in-out;
}

.provenance-filter-section-mobile.visible {
  bottom: 0;
}

.provenance-filter-section-heading {
  width: 80%;
  padding-bottom: 1.5%;
  display: flex;
  border-bottom: 1px solid #d9d9d9;
  color: #183b56;
  margin-left: 6%;
  margin-bottom: 5%;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.55);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.provenance-filter-section-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow-x: hidden;
  margin-left: 6%;
  margin-bottom: 6%;
  width: 90%;
  height: calc(var(--base-font-size-user-provenance-page) * 50);
  overflow-y: scroll;
  padding-right: 10%;
  cursor: pointer;
  overflow-y: scroll;
  max-height: calc(var(--base-font-size-user-provenance-page) * (250 / 32));
}

/* scrollbar */
.provenance-filter-section-data::-webkit-scrollbar {
  width: 3px;
}

.provenance-filter-section-data::-webkit-scrollbar-track {
  background-color: transparent;
}

.provenance-filter-section-data::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.provenance-filter-section-data::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.provenance-filter-section-data::-webkit-scrollbar {
  width: 3px;
}

.provenance-filter-section-collections {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  justify-content: start;
  line-height: normal;
  margin-bottom: 2.5%;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.46);
  gap: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  padding-left: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
  padding-top: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
  padding-bottom: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
  cursor: pointer;
}

.provenance-filter-section-collections:hover {
  background-color: #2fb48459;
  border-radius: calc(var(--base-font-size-user-provenance-page) * 0.2);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding-left: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
  padding-top: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
  padding-bottom: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
  cursor: pointer;
}

/* provenance tokens table section */

.provenance-tokens-table-container {
  width: 100%;
  height: 1000px;
  overflow-y: auto;
  overflow-x: hidden;
}

/*   Table container   */
.provenance-tokens-data-table {
  width: 100%;
  border-collapse: collapse;
}
/*   Table body   */
.provenance-tokens-data-table td {
  padding: calc(var(--base-font-size-user-provenance-page) * (16 / 32));
  cursor: pointer;
}

/*   Table header   */
.provenance-tokens-sorting-th-heading {
  color: #747474;
  cursor: pointer;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.5);
  font-weight: 600;
  line-height: normal;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: calc(var(--base-font-size-user-provenance-page) * (15 / 32));
}

/*   Table header w/ sorting   */

.provenance-tokens-sorting-th {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  line-height: normal;
}
.provenance-tokens-data-table td {
  border-bottom: 0.5px solid #d9d9d9;
  text-align: center;
}

.provenance-tokens-data-table tbody tr:hover {
  background-color: rgb(47, 180, 132, 0.15);
}

.provenance-tokens-item-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}

.provenance-tokens-images {
  width: calc(var(--base-font-size-user-provenance-page) * (80 / 32));
  height: calc(var(--base-font-size-user-provenance-page) * (80 / 32));
  flex-shrink: 0;
  border-radius: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
}

.provenance-tokens-name-section {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  margin-left: calc(var(--base-font-size-user-provenance-page) * (15 / 32));
}

.provenance-tokens-name {
  /* color: #183b56; */
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
}

.provenance-tokens-collection-name {
  color: #183b56;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: start;
  justify-content: start;
}

.provenance-tokens-dates {
  color: #183b56;
  text-align: center;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.provenance-tokens-buttons-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--base-font-size-user-provenance-page) * (20 / 32));
}

.provenance-tokens-buttons {
  text-align: center;
  padding: calc(var(--base-font-size-user-provenance-page) * (6 / 32))
    calc(var(--base-font-size-user-provenance-page) * (12 / 32));
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-user-provenance-page) * (8 / 32));
  border: calc(var(--base-font-size-user-provenance-page) * (1 / 32)) solid
    #2fb484;
  color: #183b56;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.provenance-tokens-buttons:hover {
  background-color: #2fb484;
  color: #ffffff;
  cursor: pointer;
}

.add-provenance-name-heading {
  display: flex;
  flex-direction: column;
  margin-top: 3%;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-bottom: 3%;
  max-height: 200px;
  overflow: scroll;
}

/* scrollbar */
.add-provenance-name-heading::-webkit-scrollbar {
  width: 3px;
}

.add-provenance-name-heading::-webkit-scrollbar-track {
  background-color: transparent;
}

.add-provenance-name-heading::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.add-provenance-name-heading::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.add-provenance-name-heading::-webkit-scrollbar {
  width: 3px;
}

/* Add proveance section */
.add-provenance-main-section {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: calc(var(--base-font-size-user-provenance-page) * (70 / 32));

  padding: calc(var(--base-font-size-user-provenance-page) * (32 / 32))
    calc(var(--base-font-size-user-provenance-page) * (43 / 32))
    calc(var(--base-font-size-user-provenance-page) * (26 / 32))
    calc(var(--base-font-size-user-provenance-page) * (44 / 32));
  border-radius: calc(var(--base-font-size-user-provenance-page) * (16 / 32));
  background: white;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1),
    2px -4px 15px 0px rgba(255, 255, 255, 0.25) inset;
  backdrop-filter: blur(10.5px);
  width: 100%;

  height: auto;
}

.add-provenance-category {
  width: 95%;
  align-self: self-start;
  height: calc(var(--base-font-size-user-provenance-page) * (35 / 32));
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  padding-left: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
}
.add-provenance-headings {
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  padding-right: calc(var(--base-font-size-user-provenance-page) * (30 / 32));
  border-right: 1px solid #d9d9d9;
}
.add-provenance-heading {
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  font-weight: bold;
  border-radius:  calc(var(--base-font-size-user-provenance-page) * (8/ 32));
  padding-left: calc(var(--base-font-size-user-provenance-page) * (7 / 32));
  padding-top: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  padding-bottom: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  cursor: pointer;
  color: #183b56;
}
/* .add-provenance-heading {
  color: rgba(24, 59, 86, 0.6);
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  padding-left: calc(var(--base-font-size-user-provenance-page) * (7 / 32));
  padding-top: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  padding-bottom: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
} */
.add-provenance-heading:hover {
  /* width: 110%; */
  background-color: #2fb48459;
  border-radius:  calc(var(--base-font-size-user-provenance-page) * (8/ 32));
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding-left: calc(var(--base-font-size-user-provenance-page) * (7 / 32));
  padding-right: calc(var(--base-font-size-user-provenance-page) * (7 / 32));
  padding-top: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  padding-bottom: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  cursor: pointer;
  color: #183b56;
}

.add-provenance-heading-active {
  /* width: 110%; */
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  font-weight: bold;
  background-color: #2fb48459;
  border-radius:  calc(var(--base-font-size-user-provenance-page) * (8/ 32));
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  padding-left: calc(var(--base-font-size-user-provenance-page) * (7 / 32));
  padding-top: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  padding-bottom: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  cursor: pointer;
  color: #183b56;
}

.selected-provenance-token-name {
  color: #183b56;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.5);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-bottom: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
  padding: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
  border-radius: calc(var(--base-font-size-user-provenance-page) * (8 / 32));
  background-color: #2fb48459;
  border-radius: 12px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10.5px);
  width: 70%;
}

.remove-form-button {
  display: flex;
  flex-direction: row;
  color: #b02a2a;
  gap: calc(var(--base-font-size-user-provenance-page) * (7 / 32));
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  justify-content: end;
  align-items: center;
  padding-top: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
}

/* add provenance details section */

.add-provenance-details-main-section {
  width: 40vw;
}

.add-provenance-details-heading {
  color: #183b56;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.5);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5%;
}
.add-provenance-details-form {
  display: flex;
  flex-direction: column;
  gap: calc(var(--base-font-size-user-provenance-page) * (20 / 32));
  border-radius: 16px;
  background: #f9fafb;
  padding: calc(var(--base-font-size-user-provenance-page) * (16 / 32));
  margin-bottom: 2%;
}
.add-provenance-details-form-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  color: #747474;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 2%;
  margin-left: 2%;
}
.add-provenance-details-form-input {
  width: 95%;
  align-self: self-start;
  height: calc(var(--base-font-size-user-provenance-page) * (35 / 32));
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.4);
  font-style: italic;
  font-weight: 550;
  color: #747474;
  padding: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  margin-top: calc(var(--base-font-size-user-provenance-page) * (2 / 32));
}

.add-provenance-details-form-input:hover {
  border: 1px solid #2fb484;
}

/*  provenance search */

.provenance-favorites-search-input {
  width: calc(var(--base-font-size-user-provenance-page) * (300 / 32));
  height: calc(var(--base-font-size-user-provenance-page) * (35 / 32));
  border-radius: calc(var(--base-font-size-user-provenance-page) * (8 / 32));
  background: #ecf0f9;
  border: none;
  color: black;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  padding-left: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  margin-left: 2.5%;
  cursor: pointer;
}

.provenance-favorites-search-input:hover {
  border: 1px solid #2fb484;
}

.provenance-favorites-search-input:focus {
  border: 2px solid #2fb484;
}
/* fonts */

@media screen and (max-width: 1100px) {
  /* Mobile font sizes */
  :root {
    --base-font-size-user-provenance-page: 5vw;
  }
}
.add-provenance-details-submit-button {
  width: calc(var(--base-font-size-user-provenance-page) * (101 / 32));
  height: calc(var(--base-font-size-user-provenance-page) * (43 / 32));
  color: #2fb484;
  border: 0;
  border-radius: calc(var(--base-font-size-user-provenance-page) * 1);
  background-color: #c6eade;
  font-weight: bold;
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.5);
  margin-left: calc(var(--base-font-size-user-provenance-page) * 0.3);
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.5);
  transition: all 0.4s;
}

.add-provenance-details-submit-button:hover {
  width: calc(var(--base-font-size-user-provenance-page) * (101 / 32));
  height: calc(var(--base-font-size-user-provenance-page) * (43 / 32));
  color: white;
  border: 0;
  border-radius: calc(var(--base-font-size-user-provenance-page) * 1);
  background-color: #2fb484;
  font-weight: bold;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.5);
  margin-left: calc(var(--base-font-size-user-provenance-page) * 0.3);
  transition: all 0.4s;
}

.add-provenance-details-cancel-button {
  width: calc(var(--base-font-size-user-provenance-page) * (101 / 32));
  height: calc(var(--base-font-size-user-provenance-page) * (43 / 32));
  color: #747474;
  border: 0;
  border-radius: calc(var(--base-font-size-user-provenance-page) * 1);
  background-color: #ecf0f9;
  font-weight: bold;
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.5);
  margin-left: calc(var(--base-font-size-user-provenance-page) * 0.3);
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.5);
  transition: all 0.4s;
}

.add-provenance-details-cancel-button:hover {
  width: calc(var(--base-font-size-user-provenance-page) * (101 / 32));
  height: calc(var(--base-font-size-user-provenance-page) * (43 / 32));
  color: white;
  border: 0;
  border-radius: calc(var(--base-font-size-user-provenance-page) * 1);
  background-color: #747474;
  font-weight: bold;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.5);
  margin-left: calc(var(--base-font-size-user-provenance-page) * 0.3);
  transition: all 0.4s;
}

.add-provenance-details-form-file-input-test {
  display: none;
}

.upload-button {
  border: 1px solid #2fb484;
  display: flex;
  color: #747474;
  font-weight: bold;
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.5);
  height: calc(var(--base-font-size-user-provenance-page) * (31 / 32));
  width: calc(var(--base-font-size-user-provenance-page) * (118 / 32));
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-user-provenance-page) * 0.3);
  cursor: pointer;
  transition: all 0.4s;
}

.upload-button:hover {
  border: 0;
  background-color: #2fb484;
  box-shadow: 0px 2px 8px rgba(116, 116, 116, 0.5);
  display: flex;
  color: white;
  font-weight: bold;
  height: calc(var(--base-font-size-user-provenance-page) * (31 / 32));
  width: calc(var(--base-font-size-user-provenance-page) * (118 / 32));
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: calc(var(--base-font-size-user-provenance-page) * 0.3);
  cursor: pointer;
}

.image-upload-text {
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.5);
  margin-bottom: calc(var(--base-font-size-user-provenance-page) * 0.25);
  font-weight: 50;
  color: #747474;
  font-weight: bold;
}

.add-provenance-details-form-file-input {
  width: 95%;
  display: flex;
  align-items: center;
  background-color: white;
  height: calc(var(--base-font-size-user-provenance-page) * (200 / 32));
  border-radius: 8px;
  justify-content: center;
  border: 1px solid #d9d9d9;
  margin-left: 2%;
  flex-direction: column;
}

.add-provenance-details-form-uploaded-images {
  width: 95%;
  display: flex;
  align-items: start;
  background-color: white;
  max-height: calc(var(--base-font-size-user-provenance-page) * (150 / 32));
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  margin-left: 2%;
  flex-direction: column;
  overflow-y: auto;
  gap: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  height: auto;
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.5);
  padding-top: calc(var(--base-font-size-user-provenance-page) * (20 / 32));
  padding-bottom: calc(var(--base-font-size-user-provenance-page) * (20 / 32));
  cursor: pointer;
  text-wrap: wrap;
}

.image-upload-section {
  display: flex;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.5);
  cursor: pointer;
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.5);
}
.hidden {
  display: none;
}

.custom-file-upload {
  border: 1px solid #2fb484;
  background-color: white;
  color: #747474;
  border-radius: calc(var(--base-font-size-user-provenance-page) * 5 / 32);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  padding: calc(var(--base-font-size-user-provenance-page) * 5 / 32)
    calc(var(--base-font-size-user-provenance-page) * 7 / 32);
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.5);
  border-radius: calc(var(--base-font-size-user-provenance-page) * 0.3);
  transition: all 0.3s;
}
.custom-file-upload:hover {
  cursor: pointer;

  background-color: #2fb484;
  color: white;
}

.add-provenance-details-add-button {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 0.375em;
  box-shadow: none;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.7);
  font-weight: bold;
  height: calc(var(--base-font-size-user-provenance-page) * 1.2);
  justify-content: center;
  line-height: 1.5;
  padding: calc(var(--base-font-size-user-provenance-page) * 0.5);
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.add-provenance-details-add-button-mobile {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 0.375em;
  box-shadow: none;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.7);
  font-weight: bold;
  height: calc(var(--base-font-size-user-provenance-page) * 1.2);
  justify-content: center;
  line-height: 1.5;
  padding: calc(var(--base-font-size-user-provenance-page) * 0.5);
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  margin-bottom: 4%;
  margin-top: 7%;
}

.add-provenance-details-add-button:hover {
  border-color: #b5b5b5;
  background-color: #f9f9f9;
}

.modal-overlay-add-provenance {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black color with 50% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-x: hidden;
  cursor: pointer;
}

.add-provenance-modal-container {
  background: white;
  font-size: calc(var(--base-font-size-creator-page) * 0.5);
  height: calc(var(--base-font-size-user-provenance-page) * (600 / 32));
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 90vh;
  width: 60%;
  justify-content: left;
  align-self: center;
  border-radius: calc(var(--base-font-size-user-provenance-page) * (16 / 32));
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: default;
  text-align: center;
}

.provenance-radio-buttons {
  display: flex;
  flex-direction: row;
  gap: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.5);
  margin-bottom: calc(var(--base-font-size-user-provenance-page) * 0.5);
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  margin-left: 2%;
  cursor: pointer;
  color: #183b56;
}

/* scrollbar */
.provenance-tokens-table-container::-webkit-scrollbar {
  width: 3px;
}

.provenance-tokens-table-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.provenance-tokens-table-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.provenance-tokens-table-container::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.provenance-tokens-table-container::-webkit-scrollbar {
  width: 3px;
}

/* scrollbar */
.provenance-filter-section-container::-webkit-scrollbar {
  width: 3px;
}

.provenance-filter-section-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.provenance-filter-section-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.provenance-filter-section-container::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.provenance-filter-section-container::-webkit-scrollbar {
  width: 3px;
}

/* scrollbar */
.add-provenance-modal-container::-webkit-scrollbar {
  width: 3px;
}

.add-provenance-modal-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.add-provenance-modal-container::-webkit-scrollbar-thumb {
  background-color: #2fb484;
  border-radius: 6px;
}

.add-provenance-modal-container::-webkit-scrollbar-thumb:hover {
  background-color: #16714f;
}
.add-provenance-modal-container::-webkit-scrollbar {
  width: 3px;
}

.add-category-submit-button {
  padding: calc(var(--base-font-size-user-provenance-page) * (6 / 32))
    calc(var(--base-font-size-user-provenance-page) * (12 / 32));
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: calc(var(--base-font-size-user-provenance-page) * (8 / 32));
  border: 1px solid #2fb484;
  color: #183b56;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.4);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.3);
  cursor: pointer;
}
.add-category-submit-button:hover {
  background-color: #2fb484;
  color: #ffffff;
  cursor: pointer;
}

.add-category-input {
  width: 95%;
  align-self: self-start;
  height: calc(var(--base-font-size-user-provenance-page) * (35 / 32));
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.4);
  font-style: italic;
  font-weight: 550;
  color: #747474;
  padding: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
}

.add-category-input::placeholder {
  color: #b5b5b5;
}

.add-provenance-details-form--date-input {
  width: 36vw;
  align-self: self-start;
  height: calc(var(--base-font-size-user-provenance-page) * (35 / 32));
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.4);
  font-style: italic;
  font-weight: 550;
  color: #747474;
  padding: calc(var(--base-font-size-user-provenance-page) * (10 / 32));
  margin-top: calc(var(--base-font-size-user-provenance-page) * 0.1);
}
.add-provenance-details-form--date-input:hover {
  border: 1px solid #2fb484;
}

.add-category-heading {
  color: rgba(24, 59, 86, 0.6);
  font-size: calc(var(--base-font-size-user-provenance-page) * 0.45);
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  padding-left: calc(var(--base-font-size-user-provenance-page) * (7 / 32));
  padding-top: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
  padding-bottom: calc(var(--base-font-size-user-provenance-page) * (5 / 32));
}
.add-category-heading:hover {
  color: #183b56;
}
